import React, { useState, useEffect } from 'react';
import  { Link } from 'react-router-dom';
import axios from 'axios';
import Sidebar from './Sidebar';
import Footer from './footer';
import search from './assets/search (1).png';
import auth6 from './assets/theme_asset/authentication/6.svg';
import './About.css';
import SkeletonLoader from './SkeletonLoader';

function Terms() {
    const [setting, setSetting] = useState(null); // Declare setting variable here
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const fetchData = async () => {
        try {
            const id = localStorage.getItem('id');
        const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
            setSetting(response.data.setting);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return  <SkeletonLoader showHeader={false} showFooter={false} />  ;
      }
    
      if (error) {
        return <div>Error: {error.message}</div>;
      }

    return (

        
        <div>

<div className="auth-header">
    <img className="Himg-fluid img" src={auth6} alt="v1" />
    <form className="theme-form p-2" target="_blank">
            <div className="form-group mt-2" >
                <div className="form-input" >
                <div>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <button style={{ 
                padding: '8px 16px',
                backgroundColor: '#fff',
                color: 'black',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                boxShadow: '2px 4px 6px 2px rgba(0, 0, 0, 0.2)',
                transition: 'background-color 0.3s',
              }}>X</button>
            </Link>
          </div>
                </div>
            </div>
        </form>
    <div className="auth-content">
        
        <br />
        <div>
            <h1 className='text-white'>Welcome to Finvalley </h1>
           
        </div>
    </div>
</div>
          

        <div className="about-section">
  <div className="about-item">
    <div className="about-title-head">Terms and Conditions</div>
    <div className="about-description">Welcome to Stock Uk. These terms and conditions outline the rules and regulations for the use of the Stock Uk mobile application.
By accessing this app, we assume you accept these terms and conditions. Do not continue to use Stock Uk if you do not agree to take all of the terms and conditions stated on 
this page.</div>
  </div>
  
  <div className="about-item">
    <div className="about-title">License</div>
    <div className="about-description">Unless otherwise stated, Stock Uk and/or its licensors own the intellectual 
    property rights for all material on Stock Uk. All intellectual property rights are reserved. You may access this from Stock Uk for your own personal use 
    subjected to restrictions set in these terms and conditions.</div>
  </div>
  
  <div className="about-item">
    <div className="about-title">Restrictions</div>
    <div className="about-description">1. publishing any Stock Uk material in any other media.</div>
    <div className="about-description">2. selling, sublicensing, and/or otherwise commercializing any Stock Uk material.</div>
    <div className="about-description">3. publicly performing and/or showing any Stock Uk material.</div>
    <div className="about-description">4. using this app in any way that is or may be damaging to this app.</div>
    <div className="about-description">5. using this app in any way that impacts user access to this app.</div>
    <div className="about-description">6. using this app contrary to applicable laws and regulations, or in any way may cause harm to the 
    app, or to any person or business entity.</div>
    <div className="about-description">7. engaging in any data mining, data harvesting, data extracting, or any other similar activity in 
    relation to this app.</div>
  </div>
  
  <div className="about-item">
    <div className="about-title">Your Content</div>
    <div className="about-description">In these terms and conditions, "Your Content" shall mean any audio, video text, images, or other 
    material you choose to display on Stock Uk. By displaying Your Content, you grant Stock Uk a non-exclusive, worldwide irrevocable, sub-licensable license to use, 
    reproduce, adapt, publish, translate, and distribute it in any and all media.</div>
  </div>

  <div className="about-item">
    <div className="about-title">No warranties</div>
    <div className="about-description">Stock Uk is provided "as is," with all faults, and Stock Uk makes no express or implied representations or 
    warranties of any kind related to this app or the materials contained on this app. Additionally, nothing contained 
    on Stock Uk shall be construed as providing consult or advice to you.</div>
  </div>
</div>



            
        </div>
    );
}

export default Terms;
