import React, { useState, useEffect } from 'react';
import  { Link } from 'react-router-dom';
import axios from 'axios';
import Sidebar from './Sidebar';
import Footer from './footer';
import search from './assets/search (1).png';
import auth6 from './assets/theme_asset/authentication/6.svg';
import './About.css';
import SkeletonLoader from './SkeletonLoader';

function Return() {
    const [setting, setSetting] = useState(null); // Declare setting variable here
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const fetchData = async () => {
        try {
            const id = localStorage.getItem('id');
        const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
            setSetting(response.data.setting);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return  <SkeletonLoader showHeader={false} showFooter={false} />  ;
      }
    
      if (error) {
        return <div>Error: {error.message}</div>;
      }

    return (

        
        <div>

<div className="auth-header">
    <img className="Himg-fluid img" src={auth6} alt="v1" />
    <form className="theme-form p-2" target="_blank">
            <div className="form-group mt-2" >
                <div className="form-input" >
                <div>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <button style={{ 
                padding: '8px 16px',
                backgroundColor: '#fff',
                color: 'black',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                boxShadow: '2px 4px 6px 2px rgba(0, 0, 0, 0.2)',
                transition: 'background-color 0.3s',
              }}>X</button>
            </Link>
          </div>
                </div>
            </div>
        </form>
    <div className="auth-content">
        
        <br />
        <div>
            <h1 className='text-white'>Welcome to Finvalley </h1>
           
        </div>
    </div>
</div>
          

        <div className="about-section">
  <div className="about-item">
    <div className="about-title-head">Refund Policy</div>
    <div className="about-description">Welcome to Stock Uk. This Refund Policy outlines the terms and conditions 
    under which refunds will be provided for purchases made on the Stock Uk mobile application.</div>
  </div>
  
  <div className="about-item">
    <div className="about-title">Refund Eligibility</div>
    <div className="about-description">We offer refunds for purchases made on Stock Uk under the following circumstances:</div>
    <div className="about-description">1.<b>Technical Issues:</b> If you experience technical issues or errors that prevent you from 
    accessing or using the purchased features or services.</div>
    <div className="about-description">2.<b>Unauthorized Transactions:</b> If a purchase was made on your account without your authorization.</div>
  </div>
  
  <div className="about-item">
    <div className="about-title">Refund Procedure</div>
    <div className="about-description">To request a refund, please contact our customer support team within 5 days of the purchase date. We may require 
    additional information or documentation to process your refund request.</div>
   
  </div>
  
  <div className="about-item">
    <div className="about-title">Refund Processing Time</div>
    <div className="about-description">Refunds will be processed within 5 days of receiving your refund request. Please note that it may take additional time for the refunded amount to 
    reflect in your account, depending on your payment method and financial institution.</div>
  </div>

  <div className="about-item">
    <div className="about-title">Non-Refundable Items</div>
    <div className="about-description">Certain items or services may not be eligible for refunds. These include:</div>
    <div className="about-description">1. Digital products or services that have been accessed or used.</div>
    <div className="about-description">2. Subscription fees for services that have been partially or fully utilized.</div>
    <div className="about-description">3. Products or services purchased during promotional or discounted periods.</div>
  </div>
  <div className="about-item">
    <div className="about-title">Contact Us</div>
    <div className="about-description">If you have any questions or concerns about our Refund Policy, please contact us at support.stockuk@gmail.com .</div>
    </div>
</div>



            
        </div>
    );
}

export default Return;
