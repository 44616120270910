import React, { useState, useEffect } from "react";
import axios from "axios";
import Stories from "react-insta-stories";
import "./CarouselComponent.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CarouselComponent = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showStories, setShowStories] = useState(false);
  const [storiesData, setStoriesData] = useState([]);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = 10000000;
        const response = await axios.get(`${apiBaseUrl}/contentdetailapi/${id}`);
        setData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [apiBaseUrl]);

  const handleButtonClick = (storyPath) => {
    setStoriesData([
      {
        url: storyPath,
        duration: 5000,
        type: 'image'
      }
    ]);
    
    setShowStories(true);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="carousel-wrapper">
      <div className="carousel-track">
        {data && data.stories.map((story) => (
          <div
            key={story.id}
            className="story-item"
            onClick={() => handleButtonClick(`${apiImageUrl}/story_image/${story.image}`)}
          >
            <div className="story-avatar">
              <img src={`${apiImageUrl}/story_image/${story.image}`} alt={story.name} />
            </div>
          </div>
        ))}
      </div>

      {showStories && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'black',
            zIndex: 9999,
          }}
        >
          <Stories
            stories={storiesData}
            width="100%"
            height="100%"
            loop
            defaultInterval={5000}
            progressClassName="custom-progress-bar"
            onAllStoriesEnd={() => setShowStories(false)}
          />
        </div>
      )}
    </div>
  );
};

export default CarouselComponent;
