import logo from "./logo.svg";

import feather from "feather-icons";
import Footer from "./footer";
import Sidebar from "./Sidebar";

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";

// Assuming you're using React Router and have a route set up for blog details

import favicon from "./assets/theme_asset/logo/favicon.png";

import logo1 from "./assets/theme_asset/logo/logo.png";

import logo2 from "./assets/theme_asset/svg/logo1.svg";

import auth_bg from "./assets/theme_asset/background/auth-bg.jpg";

import arrow from "./assets/theme_asset/svg/arrow.svg";

import arrow_down_right from "./assets/theme_asset/svg/arrow-down-right.svg";
import arrow_up_right from "./assets/theme_asset/svg/arrow-up-right.svg";

import p1 from "./assets/theme_asset/person/p1.png";
import p2 from "./assets/theme_asset/person/p2.png";
import p3 from "./assets/theme_asset/person/p3.png";
import p4 from "./assets/theme_asset/person/p4.png";
import p5 from "./assets/theme_asset/person/p5.png";
import ellipse from "./assets/theme_asset/svg/ellipse.svg";

import a1 from "./assets/theme_asset/svg/1.svg";
import a2 from "./assets/theme_asset/svg/2.svg";
import a3 from "./assets/theme_asset/svg/3.svg";
import a4 from "./assets/theme_asset/svg/4.svg";
import a5 from "./assets/theme_asset/svg/5.svg";

import a6 from "./assets/theme_asset/svg/6.svg";
import a7 from "./assets/theme_asset/svg/7.svg";
import a8 from "./assets/theme_asset/svg/8.svg";
import a9 from "./assets/theme_asset/svg/9.svg";
import a10 from "./assets/theme_asset/svg/10.svg";
import a11 from "./assets/theme_asset/svg/11.svg";

import card_chip from "./assets/theme_asset/svg/card-chip.svg";

import img1 from "./assets/theme_asset/1.jpg";

import contact from "./assets/theme_asset/svg/contact.svg";

import auth6 from "./assets/theme_asset/authentication/6.svg";

import img2 from "./assets/theme_asset/2.jpg";
import img3 from "./assets/theme_asset/3.jpg";
import img4 from "./assets/theme_asset/4.jpg";
import l49 from "./assets/theme_asset/logo/48.png";

import search from "./assets/search (1).png";

import "./App.css";

import scan from "./assets/theme_asset/svg/scan.svg";
import { useEffect } from "react";
import { Bar } from "react-chartjs-2";

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import Modal from "./modal";

const Register = () => {
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission status

  const [formData, setFormData] = useState({
    
    name: "",
    DOB: "",
    email: "",
    mobile: "",
    password: "",
    image: "",
    referal: "",
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    // If the input is a file input, set the file to the image field
    const image = name === "image" ? files[0] : null;
    setFormData({ ...formData, [name]: value, image });

    // form ka data idhar aenga
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Set isSubmitting to true when form is submitted

    try {
      localStorage.setItem(
        "modalMessage4",
        JSON.stringify({
          title: "User regsiter successfully",
          content: "You register and login successfully",
        })
      );

      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }

      const response = await axios.post(
        `${apiBaseUrl}/register`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set content type to multipart/form-data for file upload
          },
        }
      );

      const token = response.data.token;
      const id = response.data.user_id;
      const id1 = encodeURI(id);

      console.log(id1);
      // Store the token in localStorage
      localStorage.setItem("token", token);
      localStorage.setItem("id", id);

      navigate("/"); // Redirect the user to the homepage after successful registration
      // You can add any further actions here, like redirecting the user or showing a success message
    } catch (error) {
      console.error("Error registering user:", error);
      setModalMessage({
        title: "Error",
        content:
          "Failed to Register. Please try again later or check the information you filled.",
      });
      setShowModal(true);
    }

    // Handle error, show error message, etc.
  };

  
  const closeModal = () => {
    setShowModal(false);
    setIsSubmitting(false);

  };

  return (
    <div>
      {/*  if showmodel is true ,,,,model is getting set here from modal folder */}

      {showModal && <Modal message={modalMessage} closeModal={closeModal} />}

      <div className="auth-header">
        <Link to="/login">
          {" "}
          <i className="back-btn" data-feather="arrow-left" />{" "}
        </Link>
        <img className="img-fluid img" src={auth6} alt="v1" />
        <div className="auth-content">
          <div>
            <h2>Welcome</h2>
            <h4 className="p-0">Fill up the form</h4>
          </div>
        </div>
      </div>
      {/* header end */}
      {/* login section start */}

      {/* handleSubmit sending data to requestserver  */}
      <form className="auth-form" onSubmit={handleSubmit}>
        <div className="custom-container">
          <div className="form-group">
            <label htmlFor="name" className="form-label">
              Full name
            </label>
            <div className="form-input">
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="Enter your name"
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="DOB" className="form-label">
              Date of Birth
            </label>
            <div className="form-input">
              <input
                type="date"
                className="form-control"
                id="DOB"
                name="DOB"
                placeholder="Enter your DOB"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="Mobile" className="form-label">
              Mobile
            </label>
            <div className="form-input">
              <input
                type="number"
                className="form-control"
                id="mobile"
                name="mobile"
                placeholder="Enter your Mobile"
                onChange={handleChange}
              />
            </div>
          </div>
          {/* <div className="form-group">
        <label htmlFor="image" className="form-label">
          Profile Image
        </label>
        <div className="form-input">
          <input
            type="file"
            className="form-control"
            id="image"
            name ="image"
            onChange={handleChange}
          />
        </div>
      </div> */}
      <div className="form-group">
            <label htmlFor="email" className="form-label">
             Referal Id (optional)
            </label>
            <div className="form-input">
              <input
                type="text"
                className="form-control"
                id="referal"
                name="referal"
                placeholder="Enter Referal Id"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="email" className="form-label">
              Email id
            </label>
            <div className="form-input">
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder="Enter Your Email"
                onChange={handleChange}
              />
            </div>
          </div>
          
          <div className="form-group">
            <label htmlFor="newpin" className="form-label">
              Enter new Password
            </label>
            <div className="form-input">
              <input
                type="text"
                className="form-control"
                id="password"
                name="password"
                placeholder="Enter Password"
                onChange={handleChange}
              />
            </div>
          </div>
          referal
          {/* <div className="form-group">
        <label htmlFor="confirmpassword" className="form-label">
          Re-enter new Password
        </label>
        <div className="form-input">
          <input
            type="number"
            className="form-control"
            id="confirmpin"
            placeholder="Re-enter Password"
          />
        </div>
      </div> */}
          <div className="remember-option mt-3">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue=""
                id="flexCheckDefault"
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                I agree to all terms &amp; condition
              </label>
            </div>
          </div>
          <button type="submit" className="btn theme-btn w-100" style={{color:'white'}} disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Sign up"}
          </button>
          <h4 className="signup">
            Already have an account ?<Link to="/login"> Sign in</Link>
          </h4>
        </div>
      </form>
    </div>
  );
};

export default Register;
