import React from "react";
import logo from "./logo.svg";

import feather from "feather-icons";
import Footer from "./footer";

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";

// Assuming you're using React Router and have a route set up for blog details

import favicon from "./assets/theme_asset/logo/favicon.png";

import logo1 from "./assets/theme_asset/logo/logo.png";

import logo2 from "./assets/theme_asset/svg/logo1.svg";
import facebook from "./assets/theme_asset/svg/facebook.svg";
import google from "./assets/theme_asset/svg/google.svg";

import auth_bg from "./assets/theme_asset/background/auth-bg.jpg";

import arrow from "./assets/theme_asset/svg/arrow.svg";

import arrow_down_right from "./assets/theme_asset/svg/arrow-down-right.svg";
import arrow_up_right from "./assets/theme_asset/svg/arrow-up-right.svg";

import p1 from "./assets/theme_asset/person/p1.png";
import p2 from "./assets/theme_asset/person/p2.png";
import p3 from "./assets/theme_asset/person/p3.png";
import p4 from "./assets/theme_asset/person/p4.png";
import p5 from "./assets/theme_asset/person/p5.png";
import ellipse from "./assets/theme_asset/svg/ellipse.svg";

import a1 from "./assets/theme_asset/svg/1.svg";
import a2 from "./assets/theme_asset/svg/2.svg";
import a3 from "./assets/theme_asset/svg/3.svg";
import a4 from "./assets/theme_asset/svg/4.svg";
import a5 from "./assets/theme_asset/svg/5.svg";

import a6 from "./assets/theme_asset/svg/6.svg";
import a7 from "./assets/theme_asset/svg/7.svg";
import a8 from "./assets/theme_asset/svg/8.svg";
import a9 from "./assets/theme_asset/svg/9.svg";
import a10 from "./assets/theme_asset/svg/10.svg";
import a11 from "./assets/theme_asset/svg/11.svg";

import card_chip from "./assets/theme_asset/svg/card-chip.svg";

import img1 from "./assets/theme_asset/1.jpg";

import contact from "./assets/theme_asset/svg/contact.svg";

import auth6 from "./assets/theme_asset/authentication/6.svg";
import auth1 from "./assets/theme_asset/authentication/1.svg";

import img2 from "./assets/theme_asset/2.jpg";
import img3 from "./assets/theme_asset/3.jpg";
import img4 from "./assets/theme_asset/4.jpg";
import l49 from "./assets/theme_asset/logo/48.png";

import search from "./assets/search (1).png";

import "./App.css";

import scan from "./assets/theme_asset/svg/scan.svg";
import { useEffect } from "react";
import { Bar } from "react-chartjs-2";

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import Modal from "./modal";

const Subcription = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="auth-header">
        <div>
          <Link to="/" style={{ textDecoration: "none" }}>
            <button
              style={{
                padding: "8px 16px",
                color: "white",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </Link>
        </div>

        <div className="auth-content" style={{ justifyContent: "center" }}>
          <div>
            <center>
              <h1 className="text-white">Became a Waya Believer</h1>
              <br />
              <h3 className="text-white">Check out our Subcriptions Plans</h3>
            </center>
          </div>
        </div>
      </div>

      <form className="auth-form">
        <div className="custom-container">
          <section>
            <div className="custom-container">
              <div className="m_wrap">
                <div className="g-3 mb-3">
                  <div className="bill-box mb-3 pb-3">
                    {" "}
                    {/* Added pb-3 for bottom padding */}
                    <div className="d-flex gap-3">
                      <div className="bill-icon">
                        <img
                          className="img-fluid balance-bg"
                          src={auth_bg}
                          alt="auth-bg"
                        />
                      </div>
                      <div className="bill-details">
                        <h1 className="dark-text"> Yearly plan</h1>
                        <h6 className="light-text mt-2">12 months</h6>
                      </div>

                      <div
                        style={{ justifyContent: "flex-end", padding: "10px" }}
                      >
                        <h1>₹4500.0</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
          <div>
            <table class="table">
              <thead>
                <tr>
                  <th>Features</th>
                  <th>Free</th>
                  <th>Believer</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Stock Analysis</td>
                  <td>+</td>
                  <td>+</td>
                </tr>
                <tr>
                  <td>Sectrol Trend</td>
                  <td>+</td>
                  <td>+</td>
                </tr>
                <tr>
                  <td>FIll till Date</td>
                  <td>+</td>
                  <td>+</td>
                </tr>
                <tr>
                  <td>Educational Content</td>
                  <td>+</td>
                  <td>+</td>
                </tr>
                <tr>
                  <td>Recommandations</td>
                  <td>-</td>
                  <td>+</td>
                </tr>
                <tr>
                  <td>Multibagger Portfolia</td>
                  <td>-</td>
                  <td>+</td>
                </tr>
                <tr>
                  <td>Short Tades</td>
                  <td>-</td>
                  <td>+</td>
                </tr>
                <tr>
                  <td>Stock Research Report</td>
                  <td>-</td>
                  <td>+</td>
                </tr>
                <tr>
                  <td>Free Waya6 </td>
                  <td>-</td>
                  <td>+</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            {/* <button type="submit">

            </button> */}
            <Link
              to="/subcriptions_plans"
              style={{ color: "white" }}
              className="btn theme-btn w-100"
            >
              Upgrade to yearly plan
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Subcription;
