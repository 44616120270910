import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './SkeletonLoader.css'; // Import your CSS file for styling
import 'react-loading-skeleton/dist/skeleton.css';
import Footer from './footer';
import Sidebar from './Sidebar';

const SkeletonLoader = ({ showHeader, showFooter }) => {
  return (
    <div className="fixed-header-footer-container">
      {/* Conditional rendering of header */}
      {showHeader && (
        <div className="fixed-header">
         <Sidebar />
        </div>
      )}

      {/* Main content area */}
      <div className="main-content">
        {/* Skeleton loaders for body content */}
        <Skeleton className='p3 mb-2' /> 
        <Skeleton count={4} />
        <div className='row mb-4 mt-4'>
          <div className='col-6'><Skeleton className='p4 mb-2' /> <Skeleton count={6} /></div>
          <div className='col-6'><Skeleton className='p4 mb-2' /> <Skeleton count={6} /></div>
        </div>
        <Skeleton className='p7 mb-4 mt-4' />
        <Skeleton count={6} />
        <div className='row mb-4 mt-4'>
          <div className='col-6'><Skeleton className='p4 mb-2' /> <Skeleton count={6} /></div>
          <div className='col-6'><Skeleton className='p4 mb-2' /> <Skeleton count={6} /></div>
        </div>
      </div>

      {/* Conditional rendering of footer */}
      {showFooter && (
        <div className="fixed-footer">
          <Footer />
        </div>
      )}
    </div>
  );
}

export default SkeletonLoader;
