import logo from './assets/theme_asset/favicon.png';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Link } from 'react-router-dom';

// Assuming you're using React Router and have a route set up for blog details






import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';
import { useParams } from 'react-router-dom';
import Stories from 'react-insta-stories';


import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import SkeletonLoader from './SkeletonLoader';

import Pagination from './react-pegination';

import './paginate.css';
import CarouselComponent from './CarouselComponent';


function Blog() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showStories, setShowStories] = useState(false);
  const [SingleStoryData, setSingleStoryData] = useState(null);

 

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  

  const reports = []; // Your report data
  const perPage = 10; // Number of reports per page

  // Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const pageCount = Math.ceil(reports.length / perPage);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };


  const handleButtonClick = () => {
    setShowStories(true);
  };
  
  // Pagination logic to slice the reports array
  const offset = pageNumber * perPage;
  const currentPageReports = reports.slice(offset, offset + perPage);


  useEffect(() => {
      fetchData();
  }, [pageNumber]);




  

      


     const [selectedTab, setSelectedTab] = useState('content');

     useEffect(() => {
      // Fetch data based on the new 'id' parameter
      fetchData(id);
  }, [id]);
  const fetchData = async () => {
    try {

      
      const response = await axios.get(`${apiBaseUrl}/contentdetailapi/${id}?page=${pageNumber + 1}`);
      setData(response.data);
      
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const storiesData = data && data.stories.map((story) => ({
    url: `${apiImageUrl}/story_image/${story.image}`, 
    duration: 5000,
    type: story.type
  }));
  
  
  
  const handleGoBack = () => {
    window.history.back();
  };

  useEffect(() => {
    // Replace icons after component is mounted
    
    
    
    feather.replace();

    fetchData();
  }, []);

  if (loading) {
    return  <SkeletonLoader /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if(data == null)
    {
      return (
          <SkeletonLoader showHeader={false} showFooter={true} /> 
       )
    }

   
  return (

    
    <div style={{overflowX:'hidden'}}>
     

<div className='row pt-2'>
        <div className='col' style={{paddingRight:'0px',marginRight:'-25px'}}>
       <Link   to="#" onClick={handleGoBack} style={{padding:'5px 10px'}}>
       <svg xmlns="http://www.w3.org/2000/svg" width="35" height="25" viewBox="0 0 24 24">
	<path fill="black" d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1" />
</svg>
          </Link>
          </div>
          <div className='col' style={{paddingLeft:'0px'}}>
            <img src={logo} alt="logo" style={{width:'30px',height:'30px'}}/>
          </div>
          </div>
         {data && data.stories && ( 
          <section style={{ position: 'relative' }}>
            <h2 style={{paddingLeft:'20px'}}>Stories</h2>
          <div className="App">
      <CarouselComponent />
    </div> 
  </section>
  )}
          <section className="categories section-b-space">
        <div className="custom-container">
        <ul className="categories-list1 row" style={{marginLeft:'0px',marginRight:'0px'}}>
        {data.getCategory.categoriesWithParentId.map((category, index) => (
            <><div className='col-3' style={{marginBottom:'-10px'}}><Link to={`/blog/${category.id}`} style={{textDecoration:'none'}}>
             <li>
        <div style={{padding:'0 30px 0 0px'}} >
    
          <div className="categories-box1">
          <img className="svg" style={{width:'60px'}} src={`${apiImageUrl}/blog_image/${category.icon}`} alt="img1" />
          </div>
          
          <h6 className="" style={{width:"60px", textAlign:'center',color:'black', fontSize:'10px !important'}}>{category.name}</h6>
        </div>
      </li>
              </Link></div></>
          ))}
           
      

    </ul>
        </div>
      </section>
        {/* monthly statistics section end */}
        {/* news-update section starts */}
        <section  style={{borderRadius:'15px',padding:'10px 0 0 0'}}>
          <div className="custom-container">
       
       

      {data.getCategory.blogs.data && data.getCategory.blogs.data.length > 0 ? (
  <section style={{padding:'0px'}}>
    <div className="">
      {data.getCategory.blogs.data.map((content_byte, index) => (
       <><div style={{padding:'20px',backgroundColor:'white', borderRadius:'15px',marginBottom:'10px'}}><Link to={`/blog_details/${content_byte.id}/${content_byte.blog_title.replace(/\s+/g, '-')}`} style={{textDecoration:'none'}}>
       <img className="img-fluid" style={{width:'100%'}} src={`${apiImageUrl}/blog_image/${content_byte.blog_image}`} alt="img1" />
       <div>
       <div style={{display:'flex', justifyContent:'space-between',color: 'black'}}>
         <p style={{margin:'5px 0 0 0'}}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 20 20">
<path fill="#000" d="M5.673 0a.7.7 0 0 1 .7.7v1.309h7.517v-1.3a.7.7 0 0 1 1.4 0v1.3H18a2 2 0 0 1 2 1.999v13.993A2 2 0 0 1 18 20H2a2 2 0 0 1-2-1.999V4.008a2 2 0 0 1 2-1.999h2.973V.699a.7.7 0 0 1 .7-.699M1.4 7.742v10.259a.6.6 0 0 0 .6.6h16a.6.6 0 0 0 .6-.6V7.756zm5.267 6.877v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zm-8.333-3.977v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zM4.973 3.408H2a.6.6 0 0 0-.6.6v2.335l17.2.014V4.008a.6.6 0 0 0-.6-.6h-2.71v.929a.7.7 0 0 1-1.4 0v-.929H6.373v.92a.7.7 0 0 1-1.4 0z" />
</svg> {content_byte.date}</p>
         <p style={{margin:'5px 0 0 0'}}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 36 36">
<path fill="#000" d="M33.87 8.32L28 2.42a2.07 2.07 0 0 0-2.92 0L4.27 23.2l-1.9 8.2a2.06 2.06 0 0 0 2 2.5a2.14 2.14 0 0 0 .43 0l8.29-1.9l20.78-20.76a2.07 2.07 0 0 0 0-2.92M12.09 30.2l-7.77 1.63l1.77-7.62L21.66 8.7l6 6ZM29 13.25l-6-6l3.48-3.46l5.9 6Z" class="clr-i-outline clr-i-outline-path-1" />
<path fill="none" d="M0 0h36v36H0z" />
</svg> {content_byte.author}</p>
       </div>
         <h2 style={{paddingTop:'5px',color: 'black'}}>{content_byte.blog_title}</h2>
       </div>
       </Link></div></>
      ))}
      {data.getCategory.blogs.links && (
        <Pagination
          pageCount={data.getCategory.blogs.last_page}
          onPageChange={handlePageClick}
        />
      )}
    </div>
  </section>
) : (
  <div style={{textAlign:'center'}}>No Blogs available</div>
)}
     
   

     
     

          </div>
        </section>
        {/* news-update section end */}
        {/* panel-space start */}
        <section className="panel-space" />
        {/* panel-space end */}
        {/* bottom navbar start */}
       
        {/* bottom navbar end */}
        {/* add money modal start */}
        <Footer/>
     
        
      </div>
  );
}

export default Blog;
