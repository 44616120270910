import logo from './assets/theme_asset/favicon.png';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { Link } from 'react-router-dom'; 

import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import SkeletonLoader from './SkeletonLoader';







function Stock() {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0])
  







      




  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  function getStatusClass(status) {
    switch (status) {
      case 'active':
        return 'bg-green-status';
      case 'inactive':
        return 'bg-red-status';
      default:
        return ''; // You can set a default class or leave it empty if none matches
    }
  }

  

  useEffect(() => {
    // Replace icons after component is mounted

    

    feather.replace();

    fetchData();
  }, []);

  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> 
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  if (data === null) {
    return null;
  }

  if(data == null)
    {
      return (
       <>  </>
       )
    }

  
  return (

    
    <div>
       
       <div className="auth-header" style={{height:'150px'}}>
      <div>
              <Link to="/" style={{ textDecoration: 'none' }}>
                <button style={{
                  padding: '8px 16px',
                  color: 'white',
                  backgroundColor: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                }}>X</button>
              </Link>
            </div>
        
        <div className="auth-content" style={{justifyContent:'center'}}>
          <div>
            <center>
            <h2>Stock's</h2>
            <h4 className="p-0"></h4>
            </center></div>
        </div>
      </div>
         
         <section>
  <div className="custom-container" >
  
    <div className="m_wrap">
      <div className="g-3 mb-3" >
      {data.stock_under_radar.map((under_radar, index) => (
          <div key={index} className="bill-box mb-3 pb-3"> {/* Added pb-3 for bottom padding */}
            <div className="d-flex justify-content-between">
              <div className="bill-icon">
                <img className="img-fluid person-img" src={"https://images.5paisa.com/MarketIcons/" + under_radar.symbol+".png"} alt="p1" />
              </div>
              <div className="bill-price">
              <h6 className="dark-text">
                            {under_radar.stock_name} |
                            <span className="text-muted">
                              {" "}
                              {under_radar.symbol}
                            </span>
                            </h6>
              </div>
              <div className="bill-price">
                <Link to="#" data-bs-toggle="modal" className={`btn bill-pay bill-paid ${getStatusClass(under_radar.status)}`}>{under_radar.status}</Link>
            </div>
            </div>
            </div>
            
        ))}
      </div>
    </div>
  </div>
</section>




        {/* bill details section starts */}
        


        {/* quick send section starts */}

       
       
        
      </div>
  );
}

export default Stock;
