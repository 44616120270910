import React, { useState, useEffect } from 'react';
import  { Link } from 'react-router-dom';
import axios from 'axios';
import Sidebar from './Sidebar';
import Footer from './footer';
import search from './assets/search (1).png';
import auth6 from './assets/theme_asset/authentication/6.svg';
import './About.css';
import SkeletonLoader from './SkeletonLoader';

function Privacy() {
    const [setting, setSetting] = useState(null); // Declare setting variable here
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const fetchData = async () => {
        try {
            const id = localStorage.getItem('id');
        const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
            setSetting(response.data.setting);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return  <SkeletonLoader showHeader={false} showFooter={false} />  ;
      }
    
      if (error) {
        return <div>Error: {error.message}</div>;
      }

    return (

        
        <div>

<div className="auth-header">
    <img className="Himg-fluid img" src={auth6} alt="v1" />
    <form className="theme-form p-2" target="_blank">
            <div className="form-group mt-2" >
                <div className="form-input" >
                <div>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <button style={{ 
                padding: '8px 16px',
                backgroundColor: '#fff',
                color: 'black',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                boxShadow: '2px 4px 6px 2px rgba(0, 0, 0, 0.2)',
                transition: 'background-color 0.3s',
              }}>X</button>
            </Link>
          </div>
                </div>
            </div>
        </form>
    <div className="auth-content">
        
        <br />
        <div>
            <h1 className='text-white'>Welcome to Finvalley</h1>
           
        </div>
    </div>
</div>
          

        <div className="about-section">
  <div className="about-item">
    <div className="about-title-head">Privacy and Policy</div>
    <div className="about-description">This Privacy Policy governs the manner in which Stock Uk collects,
     uses, maintains, and discloses information collected from users (each, a "User") of the 
    Stock Uk mobile application ("App").</div>
  </div>
  
  <div className="about-item">
    <div className="about-title">Information Collection and Use</div>
    <div className="about-description">We may collect personal identification information from Users in 
    various ways, including, but not limited to, when Users visit our app, register on the app, place an order, 
    subscribe to the newsletter, respond to a survey, fill out a form, and in connection with other activities, 
    services, features, or resources we make available on our App.</div>
  </div>
  
  <div className="about-item">
    <div className="about-title">How We Use Collected Information</div>
    <div className="about-description">Stock Uk may collect and use Users personal information for the following 
    purposes:</div>
    <div className="about-description"><b>To personalize user experience:</b> We may use information in the aggregate 
    to understand how our Users as a group use the services and resources provided on our App.</div>
    <div className="about-description"><b>To improve our App:</b>We continually strive to improve 
    our offerings based on the information and feedback we receive from you.</div>
    <div className="about-description"><b>To send periodic emails: </b> We may use the email address to send User 
    information and updates pertaining to their order. It may also be used to respond to 
    their inquiries, questions, and/or other requests.</div>
  </div>
  
  <div className="about-item">
    <div className="about-title">How We Protect Your Information</div>
    <div className="about-description">We adopt appropriate data collection, storage, and processing practices 
    and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your 
    personal information, username, password, transaction information, and data stored on our App.</div>
  </div>

  <div className="about-item">
    <div className="about-title">Sharing Your Personal Information</div>
    <div className="about-description">We do not sell, trade, or rent Users' personal information to others. 
    We may share generic aggregated demographic information not linked to any personal identification information 
    regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes 
    outlined above.</div>
  </div>
</div>



            
        </div>
    );
}

export default Privacy;
