import logo from './assets/theme_asset/favicon.png';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { Link } from 'react-router-dom';

import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';

import Modal from "./modal";

import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import SkeletonLoader from './SkeletonLoader';

import { useNavigate } from "react-router-dom";






function Portfolio() {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0])
  
  const [Portfolio, setPortfolio] = useState(null);

  const [edit_portfolioform, setedit_portfolioform] = useState(false);
  const [exit_portfolioform, setexit_portfolioform] = useState(false);
  
  const [selectedStatus, setSelectedStatus] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({});
    // Handle change event for the select element
    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    const closeModal = () => {
      setShowModal(false);
    };
      




  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/portfoliopageapi/${id}`);
      setData(response.data);
      console.log(response.data);
      var tArr = [response.data.purchased_stock.buy_calls,response.data.purchased_stock.hold_calls,response.data.purchased_stock.exit_calls]; 
      setRecMetrics(tArr)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  
  const handleeditformSubmit = async (e) => {
    e.preventDefault();

  try {
    const form = e.target;
        
    // Get the form elements by name
    const trans_id = form.elements.trans_id?.value;
    const id = form.elements.id?.value;
    const quantity = form.elements.quantity?.value;
    const status = form.elements.status?.value;
    const p_price = form.elements.p_price?.value;
    const selling_price = form.elements.selling_price?.value;
    const package_id = form.elements.package_id?.value;
    
    console.log({ trans_id, id, quantity, status, p_price, selling_price,package_id });

    
    const response = await axios.post(`${apiBaseUrl}/update_portfolio`, {
      trans_id,
      id,
      quantity,
      status,
      p_price,
      selling_price,
      package_id
    }, {
      headers: {
        'Content-Type': 'application/json', // Set content type to application/json for JSON data
      },
    });
    navigate('/portfolio');
    fetchData();
    setedit_portfolioform(false)

  } catch (error) {
    setedit_portfolioform(false)
    console.log("Error while sending the Stock Management Data:", error);
    setModalMessage({
      title: "Error",
      content:
        "Failed to Update Please try again later.",
    });
    setShowModal(true);
  }
  }

  const handleexitformSubmit = async (e) => {
    e.preventDefault();

  try {
    const form = e.target;
        
    // Get the form elements by name
    const trans_id = form.elements.trans_id?.value;
    const id = form.elements.id?.value;
    const status = form.elements.status?.value;
    const selling_price = form.elements.selling_price?.value;
    const package_id = form.elements.package_id?.value;
    
    console.log({ trans_id, id, status, selling_price,package_id });

    
    const response = await axios.post(`${apiBaseUrl}/exit_stock_portfolio`, {
      trans_id,
      id,
      status,
      selling_price,
      package_id
    }, {
      headers: {
        'Content-Type': 'application/json', // Set content type to application/json for JSON data
      },
    });
    navigate('/portfolio');
    fetchData();
    setexit_portfolioform(false)

  } catch (error) {
    setexit_portfolioform(false)
    console.log("Error while sending the Stock Management Data:", error);
    setModalMessage({
      title: "Error",
      content:
        "Failed to Update Please try again later.",
    });
    setShowModal(true);
  }
  }

  const handlebuttonclick = async (id) => {
    
      const recomm_id = id;

      const id1 = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/portfolioeditapi/${recomm_id}`, {
        params: {
            user_id: id1,
        },
    });
    setPortfolio(response.data);
    console.log(response.data)
    setedit_portfolioform(true);
  }

  const handleexitclick = async (id) => {
    
    const recomm_id = id;

    const id1 = localStorage.getItem('id');
    const response = await axios.get(`${apiBaseUrl}/portfolioeditapi/${recomm_id}`, {
      params: {
          user_id: id1,
      },
  });
  setPortfolio(response.data);
  setexit_portfolioform(true);
}

  const handleupdatestoploss = async () => {
    try {
    
      const id1 = localStorage.getItem('id');
      console.log(id1);
      
      const confirmSubmit = window.confirm("Are you sure you want to Update all stock Stoploss?");

      if(confirmSubmit){
      const response = await axios.post(`${apiBaseUrl}/up_update_stoploss`, {
       
        id1,
      
      }, {
        headers: {
          'Content-Type': 'application/json', // Set content type to application/json for JSON data
        },
      });
      navigate('/portfolio');
      fetchData();
    }
    } catch (error) {
      console.log("Error while sending the Stock Management Data:", error);
      // setModalMessage({
      //   title: "Error",
      //   content:
      //     "Failed to send Stock Management Data request. Please try again later or check the data you entered.",
      // });
      // setShowModal(true);
    }
  }

  const handleRemove = async (id) => {
    try {
      const recomm_id = id;
      const id1 = localStorage.getItem('id');
      console.log(recomm_id,id1);
      
      const confirmSubmit = window.confirm("Are you sure you want to Remove stock from Portfolio?");

      if(confirmSubmit){
      const response = await axios.post(`${apiBaseUrl}/remove_recomm`, {
        recomm_id,
        id1,
      
      }, {
        headers: {
          'Content-Type': 'application/json', // Set content type to application/json for JSON data
        },
      });
      navigate('/portfolio');
      fetchData();
    }
    } catch (error) {
      console.log("Error while sending the Stock Management Data:", error);
      // setModalMessage({
      //   title: "Error",
      //   content:
      //     "Failed to send Stock Management Data request. Please try again later or check the data you entered.",
      // });
      // setShowModal(true);
    }
  };

  function getColorFromString(str) {
    // Convert the string to a hash code
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    // Convert the hash code to an RGB color
    const c = (hash & 0x00FFFFFF)
      .toString(16)
      .toUpperCase()
      .padStart(6, '0');
    // Return the color in hexadecimal format
    return `#${c}`;
  }

  const labels = data?.getPieChartData_mine?.map(item => item.sector) || [];
  const data1 = data && data.getPieChartData_mine.map(item => item.count);

  
  const backgroundColor = labels?.map(label => getColorFromString(label));


  const dataChartRec1 = {
    labels: labels,
    datasets: [{
      label: 'My Sector Portfolio',
      data: data1,
      backgroundColor: backgroundColor,
      hoverOffset: 4
    }]
  };  


const dataChartRec = {
  labels: [
    'Active',
    'Hold',
    'Exit'
  ],
  datasets: [{
    label: 'Recommandation Insights',
    data: recMetrics,
    backgroundColor: [
      '#2b8b64',
      '#d18925',
      'black'
    ],
    hoverOffset: 4
  }]
};


  function getStatusClass(status) {
    switch (status) {
      case 'buy':
        return 'bg-green-status';
      case 'short':
        return 'bg-red-status';
      case 'hold':
        return 'bg-yellow-status';
      case 'exit':
        return 'bg-black-status';
      case 'cancel':
        return 'bg-gray-status';
      default:
        return ''; // You can set a default class or leave it empty if none matches
    }
  }

  

  useEffect(() => {
    // Replace icons after component is mounted

    

    feather.replace();

    fetchData();
  }, []);

  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  if(data == null)
    {
      return (
       <>  </>
       )
    }
    let success_ratio;
    if(data.purchased_stock.exit_calls_success === 0 || data.purchased_stock.exit_calls === 0)
      {
         success_ratio = 0;
      }
      else
      {
         success_ratio = data.purchased_stock.exit_calls_success / data.purchased_stock.exit_calls * 100;
      }
    

  
  return (

    <div style={{overflowX:'hidden'}}>
       

  
       <div className='row pt-2'>
        <div className='col' style={{paddingRight:'0px',marginRight:'-25px'}}>
       <Link to="/" style={{padding:'5px 10px'}}>
       <svg xmlns="http://www.w3.org/2000/svg" width="35" height="25" viewBox="0 0 24 24">
	<path fill="black" d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1" />
</svg>
          </Link>
          </div>
          <div className='col' style={{paddingLeft:'0px'}}>
            <img src={logo} alt="logo" style={{width:'30px',height:'30px'}}/>
          </div>
          </div>



        
                                    <section style={{padding:'0px'}}>
    <div className="custom-container">
    
    <div>
    {data && data.purchased_stock && data.purchased_stock.data && (
    <div className='p6 col-md-6'>   
      <Doughnut data={dataChartRec} />
    </div>
    )}
    <div className='p3 col-md-6'>
    <h2>My Performance Metrix</h2>
    <div className="bill-box mb-3 bg-light mt-3">
                  <div className="">
                    <div className='d-flex gap-3'>
                    <div className="bill-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="none" stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 16v2a1 1 0 0 1-1 1H6l6-7l-6-7h11a1 1 0 0 1 1 1v2" />
</svg>
                    </div>
                    <div className="bill-details mt-2">
                      <h2>Total Calls</h2>
                    </div>

                    <div className="float-right mt-2">
                      <h2>{data && data.purchased_stock && data.purchased_stock.total_calls ? data.purchased_stock.total_calls : 0}</h2>
                    </div>
                    </div>

                    <hr></hr>

                    <div className='d-flex gap-3'>
                    <div className="bill-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
	<path fill="black" d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M1.5 8a6.5 6.5 0 1 0 13 0a6.5 6.5 0 0 0-13 0m4.879-2.773l4.264 2.559a.25.25 0 0 1 0 .428l-4.264 2.559A.25.25 0 0 1 6 10.559V5.442a.25.25 0 0 1 .379-.215" />
</svg>
                    </div>
                    <div className="bill-details mt-2">
                      <h2>Active Calls</h2>
                    </div>

                    <div className="float-right mt-2">
                      <h2>{data && data.purchased_stock && data.purchased_stock.buy_calls ? data.purchased_stock.buy_calls : 0}</h2>
                    </div>
                    </div>


                    <hr></hr>

                    <div className='d-flex gap-3'>
                    <div className="bill-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 20 20">
	<path fill="black" d="M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07m12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32M7 6h2v8H7zm4 0h2v8h-2z" />
</svg>
                    </div>
                    <div className="bill-details mt-2">
                      <h2>Open Calls</h2>
                    </div>

                    <div className="float-right mt-2">
                      <h2>{ data && data.purchased_stock && data.purchased_stock.hold_calls ? data.purchased_stock.hold_calls : 0}</h2>
                    </div>
                    </div>


                    <hr></hr>

                    <div className='d-flex gap-3'>
                    <div className="bill-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 20 20">
	<path fill="black" d="M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07m1.41-1.41A8 8 0 1 0 15.66 4.34A8 8 0 0 0 4.34 15.66m9.9-8.49L11.41 10l2.83 2.83l-1.41 1.41L10 11.41l-2.83 2.83l-1.41-1.41L8.59 10L5.76 7.17l1.41-1.41L10 8.59l2.83-2.83z" />
</svg>
                    </div>
                    <div className="bill-details mt-2">
                      <h2>Exit Calls</h2>
                    </div>

                    <div className="float-right mt-2">
                      <h2>{data && data.purchased_stock && data.purchased_stock.exit_calls ? data.purchased_stock.exit_calls : 0}</h2>
                    </div>
                    </div>


                    <hr></hr>

                    <div className='d-flex gap-3'>
                    <div className="bill-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<g fill="none" stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
		<path d="M5 19L19 5" />
		<circle cx="7" cy="7" r="3" />
		<circle cx="17" cy="17" r="3" />
	</g>
</svg>
                    </div>

                    <div className="bill-details mt-2">
                      <h2>Success Ratio</h2>
                    </div>

                    <div className="float-right mt-2">
                      <h2>{success_ratio > 0 ? success_ratio.toFixed(2) : 0}%</h2>
                    </div>
                   
                    </div>

                  </div>
                  
                </div>
      
    </div>
    </div>
    </div>
  </section>


  <section style={{padding:'0px'}}>
    <div className="custom-container">
  <div className='p3 col-md-6'>
    <h2>My Portfolio</h2>
    <div className="bill-box mb-3 bg-light mt-3">
                  <div className="">
                    <div className='d-flex gap-3'>
                    <div className="bill-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="none" stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 16v2a1 1 0 0 1-1 1H6l6-7l-6-7h11a1 1 0 0 1 1 1v2" />
</svg>
                    </div>
                    <div className="bill-details mt-2">
                      <h2>Total Buying Value</h2>
                    </div>

                    <div className="float-right mt-2">
                      <h2>₹{data && data.purchased_stock && data.purchased_stock.total_buy_value ? data.purchased_stock.total_buy_value : 0}</h2>
                    </div>
                    </div>

                    <hr></hr>

                    <div className='d-flex gap-3'>
                    <div className="bill-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
	<path fill="black" d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M1.5 8a6.5 6.5 0 1 0 13 0a6.5 6.5 0 0 0-13 0m4.879-2.773l4.264 2.559a.25.25 0 0 1 0 .428l-4.264 2.559A.25.25 0 0 1 6 10.559V5.442a.25.25 0 0 1 .379-.215" />
</svg>
                    </div>
                    <div className="bill-details mt-2">
                      <h2>Total Actual Value</h2>
                    </div>

                    <div className="float-right mt-2">
                      <h2>₹{data && data.purchased_stock && data.purchased_stock.total_actual_value ? data.purchased_stock.total_actual_value : 0}</h2>
                    </div>
                    </div>


                    <hr></hr>

                    <div className='d-flex gap-3'>
                    <div className="bill-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 20 20">
	<path fill="black" d="M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07m12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32M7 6h2v8H7zm4 0h2v8h-2z" />
</svg>
                    </div>
                    <div className="bill-details mt-2">
                      <h2>Total Profit/Loss</h2>
                    </div>

                    <div className="float-right mt-2">
                      <h2 style={{ color: data && data.purchased_stock && data.purchased_stock.total_profit < 0 ? 'red' : 'green' }}>₹{ data && data.purchased_stock && data.purchased_stock.total_profit ? data.purchased_stock.total_profit : 0} </h2>
                    </div>
                    </div>

                    <hr></hr>

                    <div className='d-flex gap-3'>
                    <div className="bill-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<g fill="none" stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
		<path d="M5 19L19 5" />
		<circle cx="7" cy="7" r="3" />
		<circle cx="17" cy="17" r="3" />
	</g>
</svg>
                    </div>
                    <div className="bill-details mt-2">
                      <h2>Profit/Loss Percentage</h2>
                    </div>

                    <div className="float-right mt-2">
                      <h2 style={{ color: data && data.purchased_stock && data.purchased_stock.percentage < 0 ? 'red' : 'green' }}>{ data && data.purchased_stock && data.purchased_stock.percentage ? data.purchased_stock.percentage.toFixed(2) : 0}%</h2>
                    </div>
                    </div>


                   
                  </div>
                  
                </div>
      
    </div>
    </div>
  </section>
<section>
    <h2>Sector Portfolio</h2>
    {data && data.getPieChartData_mine && (
    <div className='p6 col-md-6'>   
      <Doughnut data={dataChartRec1} />
    </div>
    )}
</section>
<section >
<div className="custom-container">
<div  onClick={handleupdatestoploss}
                        style={{
                            backgroundColor: '#5e17eb',
                            color: 'white',
                            padding: '3px 3px',
                            margin: '4px',
                            borderRadius: '10px',
                            textAlign: "center"
                        }}>
                          Set Stoploss to all in Upstox 
                          </div>
</div>
</section>
      
        <section style={{marginBottom:'20px'}}>
        <div className="custom-container">
            <div className="">
           {data && data.purchased_stock && data.purchased_stock.data && (
                <div className="g-3 mb-2">
                    {data.purchased_stock.data.map((item, index) => {
                        // Add null or undefined check for item and item.recommendation
                        if (item && item) {
                            return (
                              <div className="portfolio-card">
                              <div className="portfolio-card-header">
                                  <img
                                      className="portfolio-logo"
                                      src={`https://images.5paisa.com/MarketIcons/${item.recommendation.symbol}.png`}
                                      alt={item.recommendation.script_name}
                                  />
                                  <div className="portfolio-details">
                                    <div className='d-flex justify-content-between'>
                                      <div>
                                        <h4 className="portfolio-name">{item.recommendation.script_name}</h4>
                                      </div>
                                      <div>
                                        <p className="portfolio-quantity" >QTY: {item.amount}</p>
                                      </div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                      <div>
                                        <p className="portfolio-quantity" style={{padding:'0px'}}>Buying Zone: ₹{item.recommendation.price_band_from} - ₹{item.recommendation.price_band_to}</p>
                                      </div>
                                      <div>
                                        <p className="portfolio-quantity" style={{padding:'0px'}}>Duration: {item.recommendation.duration} {item.recommendation.duration_type}</p>
                                      </div>
                                    </div>
                                      
                                  </div>
                              </div>
                              <div className="portfolio-card-body">
                                  
                                      <p style={{padding:'0px'}}><strong>T:</strong> ₹{item.recommendation.target}</p>
                                      <p style={{padding:'0px'}}><strong>SL:</strong> ₹{item.recommendation.stoploss}</p>
                                      {item.transaction.order_id && (<p style={{padding:'0px'}}><strong>BV:</strong> ₹{item.transaction.order_id}</p>)}
                                     {item.transaction.payment_id &&( <p style={{padding:'0px'}}><strong>SV:</strong> ₹{item.transaction.payment_id}</p>)}
                                  </div>
                                  <div className="portfolio-card-body">
                                  
                                      <p style={{padding:'5px',border:'1px solid',borderRadius:"10px"}}><div><strong style={{fontSize:"12px"}}>Purchased At:</strong></div> <div>₹{item.buying_value}</div></p>
                                      <p style={{padding:'5px',border:'1px solid',borderRadius:"10px"}}><div><strong style={{fontSize:"12px"}}>Actual value:</strong></div> <div>₹{item.actual_value}</div></p>
                                     <p style={{ color: item.profit > 0 ? 'green' : 'red' ,padding:'5px',border:'1px solid',borderRadius:"10px"}}><div><strong style={{fontSize:"12px"}}>Profit/Loss:</strong></div><div> ₹{item.profit}</div></p>
                                     {item.transaction.payment_id &&( <p style={{ color: item.transaction.payment_status > item.buying_value ? 'green' : 'red' ,padding:'5px',border:'1px solid',borderRadius:"10px"}}><div><strong style={{fontSize:"12px"}}>Total SV:</strong></div> <div>₹{item.transaction.payment_status}</div></p>)}
                                  </div>
                                  {/* <div className="portfolio-card-body">
                                      <p  style={{padding:'5px',border:'1px solid',borderRadius:"10px"}}><strong>Purchased At:</strong> ₹{item.buying_value}</p>
                                      <p style={{padding:'5px',border:'1px solid',borderRadius:"10px"}}><strong>Actual Value:</strong> ₹{item.actual_value.toFixed(2)}</p>
                                      <p className="portfolio-profit-loss" style={{ color: item.profit > 0 ? 'green' : 'red' ,padding:'5px',border:'1px solid',borderRadius:"10px"}}>
                                          <strong>P/L:</strong> ₹{item.profit}
                                      </p>
                                     {item.transaction.payment_status && ( <p style={{ color: item.transaction.payment_status > item.buying_value ? 'green' : 'red' ,padding:'5px',border:'1px solid',borderRadius:"10px"}}><strong>Total SV:</strong> ₹{item.transaction.payment_status}</p>)}
                                  </div> */}
                              <div className="portfolio-card-footer">
                                  <button  className="btn1 btn1-edit" onClick={() => handlebuttonclick(item.recommendation.id)}><div><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path fill="white" d="M21 12a1 1 0 0 0-1 1v6a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h6a1 1 0 0 0 0-2H5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-6a1 1 0 0 0-1-1m-15 .76V17a1 1 0 0 0 1 1h4.24a1 1 0 0 0 .71-.29l6.92-6.93L21.71 8a1 1 0 0 0 0-1.42l-4.24-4.29a1 1 0 0 0-1.42 0l-2.82 2.83l-6.94 6.93a1 1 0 0 0-.29.71m10.76-8.35l2.83 2.83l-1.42 1.42l-2.83-2.83ZM8 13.17l5.93-5.93l2.83 2.83L10.83 16H8Z"/></svg></div><div><span style={{fontSize:'10px'}}>Edit</span></div></button>
                                  <button  className="btn1 btn1-stoploss" onClick={() => handleexitclick(item.recommendation.id)}><div><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16"><path fill="white" d="M12 10V8H7V6h5V4l3 3zm-1-1v4H6v3l-6-3V0h11v5h-1V1H2l4 2v9h4V9z"/></svg></div><div><span style={{fontSize:'10px'}}>Exit</span></div></button>
                                  <button  className="btn1 btn1-remove" onClick={() => handleRemove(item.recommendation.id)}><div><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path fill="white" fill-rule="evenodd" d="M12 21a9 9 0 1 0 0-18a9 9 0 0 0 0 18m-5-8h10v-2H7z" clip-rule="evenodd"/></svg></div><div><span style={{fontSize:'10px'}}>Remove</span></div></button>
                                  <Link to={`/recomm_details/${item.recommendation.id}`} className="btn1 btn1-view"><div><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 2048 2048"><path fill="white" d="M777 1920q15 35 36 67t48 61H0V0h1536v911q-32-9-64-17t-64-13V128H128v1792zm503-1408H256V384h1024zM256 768h1024v128H256zm960 640q66 0 124 25t101 69t69 102t26 124t-25 124t-69 102t-102 69t-124 25t-124-25t-102-68t-69-102t-25-125t25-124t68-101t102-69t125-26m0 512q40 0 75-15t61-41t41-61t15-75t-15-75t-41-61t-61-41t-75-15t-75 15t-61 41t-41 61t-15 75t15 75t41 61t61 41t75 15m0-896q100 0 200 21t193 64t173 103t139 139t93 173t34 204h-128q0-91-29-169t-81-142t-119-113t-147-83t-162-51t-166-18q-82 0-166 17t-162 51t-146 83t-120 114t-80 142t-30 169H384q0-109 34-204t93-173t139-139t172-103t193-63t201-22"/></svg></div><div><span style={{fontSize:'10px'}}>View</span></div></Link>
                              </div>
                          </div>
                            );
                        }
                        // Return null if item or item.recommendation is null or undefined
                        return null;
                    })}
                </div>
              )}
            </div>
            <div className='mt-3'></div>
        </div>
    </section>



        {/* bill details section starts */}
        
<section >
{showModal && <Modal message={modalMessage} closeModal={closeModal} />}    
{edit_portfolioform && (
   <div className="edit-portfolio-overlay">
                    <div className="edit-portfolio-modal">
                  

    <form className="auth-form" onSubmit={handleeditformSubmit}>
    <div className="custom-container">
    <div className='d-flex justify-content-between'>
    <h2>Edit form</h2>
    <button className=" btn theme-btn " onClick={() => setedit_portfolioform(false)} style={{padding:'5px 12px',margin:'-8px 0 0 0',color:'white'}}>X</button>
  </div>
    <div className="form-group">
  <label htmlFor="name" className="form-label">
    Name of Stock
  </label>
  <div className="form-input">
    <input
      type="text"
      className="form-control"
      id="name"
      name="name"
      defaultValue={Portfolio.recommandation.script_name}
      placeholder="Enter your name"
      readOnly
    />
     <input
      type="hidden"
      className="form-control"
      id="trans_id"
      name="trans_id"
      defaultValue={Portfolio.purchased_stock_one.id}
      placeholder="Enter your name"
      readOnly
    />
    <input
      type="hidden"
      className="form-control"
      id="id"
      name="id"
      defaultValue={Portfolio.purchased_stock_one.user_id}
      placeholder="Enter your ID"
    />
    <input
      type="hidden"
      className="form-control"
      id="package_id"
      name="package_id"
      defaultValue={Portfolio.purchased_stock_one.package_id}
      placeholder="Enter your ID"
    />
  </div>
</div>

<div className="form-group">
  <label htmlFor="mobile" className="form-label">
    Quantity
  </label>
  <div className="form-input">
    <input
      type="number"
      className="form-control"
      id="quantity"
      name="quantity"
      placeholder="Enter your Mobile"
      defaultValue={Portfolio.purchased_stock_one.amount}
    />
  </div>
</div>

<div className="form-group">
  <label htmlFor="email" className="form-label">
    Purchase Rate
  </label>
  <div className="form-input">
    <input
      type="text"
      className="form-control"
      id="p_price"
      name="p_price"
      placeholder="Enter Your Email ID"
      defaultValue={Portfolio.purchased_stock_one.order_id}
    />
  </div>
</div>


      <button type='submit' className="btn theme-btn w-100" style={{color:'white'}}>
        Update
      </button>
      
    </div>
  </form>
  </div>
  </div>
)


}

  {/* Exit Form  */}

{exit_portfolioform && (
   <div className="edit-portfolio-overlay">
                    <div className="edit-portfolio-modal">
                  

    <form className="auth-form" onSubmit={handleexitformSubmit}>
    <div className="custom-container">
    <div className='d-flex justify-content-between'>
    <h2>Edit form</h2>
    <button className=" btn theme-btn " onClick={() => setexit_portfolioform(false)} style={{padding:'5px 12px',margin:'-8px 0 0 0',color:'white'}}>X</button>
  </div>
    <div className="form-group">
  <label htmlFor="name" className="form-label">
    Name of Stock
  </label>
  <div className="form-input">
    <input
      type="text"
      className="form-control"
      id="name"
      name="name"
      defaultValue={Portfolio.recommandation.script_name}
      placeholder="Enter your name"
      readOnly
    />
     <input
      type="hidden"
      className="form-control"
      id="trans_id"
      name="trans_id"
      defaultValue={Portfolio.purchased_stock_one.id}
      placeholder="Enter your name"
      readOnly
    />
    <input
      type="hidden"
      className="form-control"
      id="id"
      name="id"
      defaultValue={Portfolio.purchased_stock_one.user_id}
      placeholder="Enter your ID"
    />
    <input
      type="hidden"
      className="form-control"
      id="package_id"
      name="package_id"
      defaultValue={Portfolio.purchased_stock_one.package_id}
      placeholder="Enter your ID"
    />
  </div>
</div>

<div className="form-group">
  <label htmlFor="upiid" className="form-label">
    Status
  </label>
  <div className="form-input">
  <select className="form-control" id="status" name="status" onChange={handleStatusChange} value={selectedStatus}>
        <option value="">Select Status</option>
        <option value="earlyexit">Early Exit</option>
  </select>
  </div>
</div>
    
  <div className="form-group mt-3">
    <label htmlFor="selling_price">Selling Price:</label>
      <input
        type="text"
        className="form-control"
        id="selling_price"
        name="selling_price"
        placeholder="Enter selling price"
      />
  </div>
       
     
      <button type='submit' className="btn theme-btn w-100" style={{color:'white'}}>
        Update
      </button>
      
    </div>
  </form>
  </div>
  </div>
)


}
</section>

        {/* quick send section starts */}

       
       
        
      </div>
  );
}

export default Portfolio;
