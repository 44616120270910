import logo from "./logo.svg";

import feather from "feather-icons";
import Footer from "./footer";
import Sidebar from "./Sidebar";

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Login from "./login";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import { Pagination, Navigation } from 'swiper/modules';


import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { Link } from "react-router-dom";

import favicon from "./assets/theme_asset/logo/favicon.png";

import logo1 from "./assets/theme_asset/logo/logo.png";

import logo2 from "./assets/theme_asset/svg/logo1.svg";

import auth_bg from "./assets/theme_asset/background/auth-bg.jpg";

import arrow from "./assets/theme_asset/svg/arrow.svg";

import arrow_down_right from "./assets/theme_asset/svg/arrow-down-right.svg";
import arrow_up_right from "./assets/theme_asset/svg/arrow-up-right.svg";

import p1 from "./assets/theme_asset/person/p1.png";
import p2 from "./assets/theme_asset/person/p2.png";
import p3 from "./assets/theme_asset/person/p3.png";
import p4 from "./assets/theme_asset/person/p4.png";
import p5 from "./assets/theme_asset/person/p5.png";
import ellipse from "./assets/theme_asset/svg/ellipse.svg";

import a1 from "./assets/theme_asset/svg/1.svg";
import a2 from "./assets/theme_asset/svg/2.svg";
import a3 from "./assets/theme_asset/svg/3.svg";
import a4 from "./assets/theme_asset/svg/4.svg";
import a5 from "./assets/theme_asset/svg/5.svg";

import a6 from "./assets/theme_asset/svg/6.svg";
import a7 from "./assets/theme_asset/svg/7.svg";
import a8 from "./assets/theme_asset/svg/8.svg";
import a9 from "./assets/theme_asset/svg/9.svg";
import a10 from "./assets/theme_asset/svg/10.svg";
import a11 from "./assets/theme_asset/svg/11.svg";

import card_chip from "./assets/theme_asset/svg/card-chip.svg";

import img1 from "./assets/theme_asset/1.jpg";

import contact from "./assets/theme_asset/svg/contact.svg";

import auth6 from "./assets/theme_asset/authentication/6.svg";

import img2 from "./assets/theme_asset/2.jpg";
import img3 from "./assets/theme_asset/3.jpg";
import img4 from "./assets/theme_asset/4.jpg";
import l49 from "./assets/theme_asset/logo/48.png";

import bull from "./assets/theme_asset/bull.png"
import bull_bear from "./assets/theme_asset/bull_bear.png"
import bear from "./assets/theme_asset/bear.png"

import search from "./assets/search (1).png";

import "./App.css";


import scan from "./assets/theme_asset/svg/scan.svg";
import { useEffect } from "react";
import { Bar } from "react-chartjs-2";

import { requestPermission, messaging } from "./firebase";
import { onMessage } from "firebase/messaging";

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import Modal from "./modal";
import SkeletonLoader from "./SkeletonLoader";
import { Helmet } from 'react-helmet';


function App() {
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState('buy');

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics, setRecMetrics] = useState([0, 0, 0]);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

   // Initialize state based on the value from local storage
   const [isPopupOpen, setIsPopupOpen] = useState(() => {
    const acknowledged = sessionStorage.getItem('disclaimerAcknowledged');
    return acknowledged !== 'true';
});


const paginationStyle = {
  color: 'white', // Change bullet color to white
};
// Function to handle closing the popup
const handleClosePopup = () => {
    // Close the popup and save the acknowledgment flag in local storage
    setIsPopupOpen(false);
    sessionStorage.setItem('disclaimerAcknowledged', 'true');
};

// Effect to check local storage value on component mount
useEffect(() => {
  requestPermission();
  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // console.log(payload.notification.data.click_action);
    console.log(payload.data.click_action);
  })

    const acknowledged = sessionStorage.getItem('disclaimerAcknowledged');
    // Only open the popup if the user has not acknowledged the disclaimer
    if (acknowledged !== 'true') {
        setIsPopupOpen(true);
    }
}, []);


  const handleModalToggle = () => {
    setShowModal1(true);
  };



  const dataChartRec = {
    labels: ["Active", "Hold", "Exit"],
    datasets: [
      {
        label: "Recommandation Insights",
        data: recMetrics,
        backgroundColor: ["#2b8b64", "#d18925", "black"],
        hoverOffset: 4,
      },
    ],
  };


  const fetchData = async () => {
    try {
      const id = localStorage.getItem("id");
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
      setData(response.data);
      var tArr = [
        response.data.recommandation_count.active,
        response.data.recommandation_count.hold,
        response.data.recommandation_count.exit,
      ];

      setRecMetrics(tArr);

      if(!response.data.user){
        navigate('/login')
      }

    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (e, data) => {
    e.preventDefault(); // Prevent form submission
    try {
      const response = data && data.Recommandations_get;
      if (!response) return; // Check if response is available
  
      // Filter search results based on symbol or name
      const filteredResults = response.filter(stock => (
        stock.symbol.toLowerCase().includes(searchQuery.toLowerCase()) ||
        stock.script_name.toLowerCase().includes(searchQuery.toLowerCase())
      ));


      setSearchResults(filteredResults);
    } catch (error) {
      console.error('Error searching stocks:', error);
    }
  };
  

  const handleSuggestions = () => {
    const input = searchQuery.toLowerCase();
    const filteredSuggestions = searchResults.filter(stock => (
      stock.symbol.toLowerCase().includes(input) ||
      stock.script_name.toLowerCase().includes(input)
    ));
    setSuggestions(filteredSuggestions);
  };

  useEffect(() => {
    handleSuggestions();
  }, [searchQuery, searchResults]);



  const fetchData1 = async () => {
    try {
      const id = localStorage.getItem("id");
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
      // setData(response.data);
      var tArr = [
        response.data.recommandation_count.active,
        response.data.recommandation_count.hold,
        response.data.recommandation_count.exit,
      ];

      setRecMetrics(tArr);

      

    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  function getStatusClass(status) {
    switch (status) {
      case "buy":
        return "bg-green-status";
      case "short":
        return "bg-red-status";
      case "hold":
        return "bg-yellow-status";
      case "exit":
        return "bg-black-status";
      case 'cancel':
        return 'bg-gray-status';
      default:
        return ""; // You can set a default class or leave it empty if none matches
    }
  }

  function getBackgroundClass(status) {
    switch (status) {
      case "buy":
        return "bg-green";
      case "short":
        return "bg-red";
      case "hold":
        return "bg-yellow";
      case "exit":
        return "bg-black";
        case 'cancel':
          return 'bg-gray';
      default:
        return ""; // You can set a default class or leave it empty if none matches
    }
  }

  function getTrendBackground(status) {
    switch (status) {
      case "up":
        return "bg-green-status";
      case "down":
        return "bg-red-status";
      case "side":
        return "bg-yellow-status";
      default:
        return "bg-red-status"; // You can set a default class or leave it empty if none matches
    }
  }

  function getTrendIcon(status) {
    switch (status) {
      case "up":
        return "arrow-up-circle";
      case "down":
        return "arrow-down-circle";
      case "side":
        return "arrow-right-circle";
      default:
        return ""; // You can set a default class or leave it empty if none matches
    }
  }

  function getTrendImage(status) {
    switch (status) {
        case "up":
            return <img src={bull} alt="Bull trend" style={{ width: "50px" }} />;
        case "down":
            return <img src={bear} alt="Bull trend" style={{ width: "50px" }} />;
        case "side":
            return <img src={bull_bear} alt="Bull trend" style={{ width: "50px" }} />;
        default:
            return <img src={bear} alt="Bull trend" style={{ width: "50px" }} />; // Return an empty string for an unexpected or unknown status
    }
}


const stock_risk_amount = data && data.Stock_rsk * (data && data.user && data.user.percentage/100);
const future_risk_amount = data && data.future_rsk * (data && data.user && data.user.percentage_future/100);
const option_risk_amount = data && data.option_rsk * (data && data.user && data.user.percentage_option/100);

  const [modalMessage, setModalMessage] = useState(null);

  useEffect(() => {
    // Replace icons after component is mounted
    const storedMessage = localStorage.getItem("modalMesage3");

    if (storedMessage) {
      setModalMessage(JSON.parse(storedMessage));
      // Remove modal message from local storage after retrieving
      localStorage.removeItem("modalMessage3");
    }

    const token = localStorage.getItem("token");
    if (token == null) {
      navigate("/login");
    } else {
      navigate("/");
    }
    feather.replace();

    fetchData();
  }, []);

  if (loading) {
    <SkeletonLoader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (data == null) {
    return <SkeletonLoader showHeader={true} showFooter={true} />;
  }

  
  
 
  const closeModal = () => {
    setShowModal(false);
  };

  const handleCheck = (type) => {
    if (data && data.package_active && data.package_active.status === 'success') {
      navigate(`/recommandation?type=${type}`);
    } else {
      alert("To access this module, please subscribe to a plan.");
      navigate('/pricing');
    }
  };


  const RecommendationCard = ({ recommendation }) => {
    const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

    const getBackgroundClass = (status) => {
      switch (status) {
        case 'buy':
          return 'bg-green';
        case 'short':
          return 'bg-red';
        case 'hold':
          return 'bg-yellow';
        case 'exit':
          return 'bg-black';
        case 'cancel':
          return 'bg-gray';
        default:
          return '';
      }
    };

    const getStatusClass = (status) => {
      switch (status) {
        case 'buy':
          return 'bg-green-status';
        case 'short':
          return 'bg-red-status';
        case 'hold':
          return 'bg-yellow-status';
        case 'exit':
          return 'bg-black-status';
        case 'cancel':
          return 'bg-gray-status';
        default:
          return '';
      }
    };

    // Function to compute the default quantity
const computeDefaultQuantity = (recommendation, data) => {
  const percentage = (data.Stock_rsk) * (data.user.percentage / 100);
  const percentage1 = (data.future_rsk) * (data.user.percentage_future / 100);
  const percentage2 = (data.option_rsk) * (data.user.percentage_option / 100);

  // Compute quantity based on recommendation stock type
  return recommendation.stock_type === 'options'
      ? Math.max(0, Math.floor(percentage2 / (recommendation.price_band_to - recommendation.stoploss)))
      : recommendation.stock_type === 'futures'
      ? Math.max(0, Math.floor(percentage1 / (recommendation.price_band_to - recommendation.stoploss)))
      : Math.max(0, Math.floor(percentage / (recommendation.price_band_to - recommendation.stoploss)));
};



// Function to find matching amount from stockPurchases

const recommendationId = recommendation.id;
const stockPurchases = data.stock_purchase;


const findMatchingAmount = (recommendationId, stockPurchases) => {
  // Iterate through stockPurchases array
  for (const purchase of stockPurchases) {
      // Check if the purchase is not null or undefined
      if (purchase && purchase.package_id !== undefined) {
          // Check if package_id matches recommendationId
          if (Number(purchase.package_id) === recommendationId) {
              // Return the amount if a match is found
              return purchase.amount;
          }
      }
  }
  // Return null if no match is found
  return null;
};


// Use the functions to determine the quantity
const determineQuantity = (recommendation, data, stockPurchases) => {
  // Check if there is a matching entry in stockPurchases
  const matchingAmount = findMatchingAmount(recommendationId, stockPurchases);
  // If a matching amount is found, use it
  if (matchingAmount === null) {
    return computeDefaultQuantity(recommendation, data);
  }

  // Otherwise, compute the default quantity
  return matchingAmount;

};

// Example usage in your JSX or elsewhere in your component
const quantity = determineQuantity(recommendation, data, stockPurchases);

const capital = quantity * recommendation.price_band_to; 


// Now you can use `quantity` in your component as needed
const industry = data.stock_list.find(stock_list => stock_list.symbol_up === recommendation.symbol);

const risk = recommendation.price_band_to - recommendation.stoploss;

const reward =  recommendation.target - recommendation.price_band_to;

const final_reward = reward / risk;

// Round final_reward to two decimal places
const rounded_final_reward = parseFloat(final_reward.toFixed(2));

// Log the rounded value


const total_return = (industry.u_last_price - recommendation.price_band_from ) /  recommendation.price_band_from * 100;

     const rounded_return = parseFloat(total_return.toFixed(2));

     const total_loss = (industry.u_last_price - recommendation.price_band_from) /  recommendation.price_band_from * 100;

     const rounded_loss = parseFloat(total_loss.toFixed(2));


  return (
    <div>
      
      <div>
      <Link to={`/recomm_details/${recommendation.id}?quantity=${quantity}`}>
        <div className="card-box mt-3">
          <div className={`card-details ${getBackgroundClass(recommendation.status)}`}>
            <div className="d-flex justify-content-between">
            <div className="">
                      <h5 className="fw-semibold">
                        {recommendation.script_name} | {recommendation.symbol}
                      </h5>
                      {industry && <div className="" style={{fontSize:'10px',color:'white'}}>
                                                      {industry.industry_name} | {industry.sector_name}
                                                  </div>
                                                  }  </div>
                      <div className="">
                      <img
                        className="rec_img"
                        src={"https://images.5paisa.com/MarketIcons/" + recommendation.symbol+".png"}
                        alt="ellipse"
                      />
                      </div></div>
            <div className="">
              <div className="text-start">
                <div className="">
                  <div className='d-flex justify-content-between'>
                    <h3 className="mt-1 text-white">₹ {recommendation.price_band_from} - {recommendation.price_band_to} {recommendation.status === "hold" && (
                      <span
                        style={{
                          backgroundColor: industry.u_last_price > recommendation.price_band_from ? 'green' : 'red',
                          padding: '2px 5px',
                          textAlign: 'center'
                        }}
                      >
                        {"(" + (industry.u_last_price > recommendation.price_band_from ? rounded_return : rounded_loss) + "%)"}
                      </span>
                    )}</h3>
                    <h3 className="mt-1 text-white">QTY {quantity}</h3>
                  </div>
                </div>
                <p className='text-muted-new'>Buy in this Zone | <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
	<path fill="#b7c0bc" d="M12 20a8 8 0 0 0 8-8a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8m0-18a10 10 0 0 1 10 10a10 10 0 0 1-10 10C6.47 22 2 17.5 2 12A10 10 0 0 1 12 2m.5 5v5.25l4.5 2.67l-.75 1.23L11 13V7z" />
</svg> {recommendation.duration} {recommendation.duration_type} </p>
              </div>
            </div>
            <div className="amount-details">
              <div className="amount w-50 text-start">
                <div className="d-flex align-items-center justify-content-start">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14">
	<g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round">
		<path d="M13.48 7.516a6.5 6.5 0 1 1-6.93-7" />
		<path d="M9.79 8.09A3 3 0 1 1 5.9 4.21M7 7l2.5-2.5m2 .5l-2-.5l-.5-2l2-2l.5 2l2 .5z" />
	</g>
</svg>
                  <h6 className='text-white' style={{marginLeft:'5px'}}>Target</h6>
                </div>
                <h3 className="text-white" ><span style={{ backgroundColor: recommendation.result === 'success' && 'lightGreen',padding:'2px 5px',textAlign:'center'}}>₹{recommendation.target}</span></h3>
              </div>
              <div className="amount w-50 text-end border-0">
                <div className="d-flex align-items-center justify-content-end">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16">
	<path fill="#fff" d="M4.47.22A.75.75 0 0 1 5 0h6c.199 0 .389.079.53.22l4.25 4.25c.141.14.22.331.22.53v6a.75.75 0 0 1-.22.53l-4.25 4.25A.75.75 0 0 1 11 16H5a.75.75 0 0 1-.53-.22L.22 11.53A.75.75 0 0 1 0 11V5c0-.199.079-.389.22-.53Zm.84 1.28L1.5 5.31v5.38l3.81 3.81h5.38l3.81-3.81V5.31L10.69 1.5ZM8 4a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5A.75.75 0 0 1 8 4m0 8a1 1 0 1 1 0-2a1 1 0 0 1 0 2" />
</svg>
                 <h6 className='text-white'  style={{marginLeft:'5px'}}>Stoploss</h6>
                </div>
                <h3 className="text-white" ><span style={{ backgroundColor: recommendation.result === 'fail' ? '#ff6a6a' : recommendation.trailing_stoploss && '#ffc107',padding:'2px 5px',textAlign:'center'}}>₹{recommendation.trailing_stoploss ? recommendation.trailing_stoploss : recommendation.stoploss}</span></h3>
              </div>
            </div>
            <div className="amount-details">
              <div className="amount w-50 text-start">
                <div className="d-flex align-items-center justify-content-start">
                 <svg xmlns="http://www.w3.org/2000/svg" width="15.5" height="12" viewBox="0 0 640 512">
	<path fill="#fff" d="M384 32h128c17.7 0 32 14.3 32 32s-14.3 32-32 32H398.4c-5.2 25.8-22.9 47.1-46.4 57.3V448h160c17.7 0 32 14.3 32 32s-14.3 32-32 32H128c-17.7 0-32-14.3-32-32s14.3-32 32-32h160V153.3c-23.5-10.3-41.2-31.6-46.4-57.3H128c-17.7 0-32-14.3-32-32s14.3-32 32-32h128c14.6-19.4 37.8-32 64-32s49.4 12.6 64 32m55.6 288h144.8L512 195.8zm72.4 96c-62.9 0-115.2-34-126-78.9c-2.6-11 1-22.3 6.7-32.1l95.2-163.2c5-8.6 14.2-13.8 24.1-13.8s19.1 5.3 24.1 13.8L631.3 305c5.7 9.8 9.3 21.1 6.7 32.1C627.2 382 574.9 416 512 416M126.8 195.8L54.4 320h144.9zM.9 337.1c-2.6-11 1-22.3 6.7-32.1l95.2-163.2c5-8.6 14.2-13.8 24.1-13.8s19.1 5.3 24.1 13.8L246.2 305c5.7 9.8 9.3 21.1 6.7 32.1C242 382 189.7 416 126.8 416S11.7 382 .9 337.1" />
</svg>
                 <h6 className='text-white' style={{marginLeft:'5px'}}> Risk Reward Ratio</h6>
                </div>
                <h3 className="text-white">{rounded_final_reward} times</h3>
              </div>
              <div className="amount w-50 text-end border-0">
                <div className="d-flex align-items-center justify-content-end">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20">
	<path fill="#fff" d="M5.673 0a.7.7 0 0 1 .7.7v1.309h7.517v-1.3a.7.7 0 0 1 1.4 0v1.3H18a2 2 0 0 1 2 1.999v13.993A2 2 0 0 1 18 20H2a2 2 0 0 1-2-1.999V4.008a2 2 0 0 1 2-1.999h2.973V.699a.7.7 0 0 1 .7-.699M1.4 7.742v10.259a.6.6 0 0 0 .6.6h16a.6.6 0 0 0 .6-.6V7.756zm5.267 6.877v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zm-8.333-3.977v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zM4.973 3.408H2a.6.6 0 0 0-.6.6v2.335l17.2.014V4.008a.6.6 0 0 0-.6-.6h-2.71v.929a.7.7 0 0 1-1.4 0v-.929H6.373v.92a.7.7 0 0 1-1.4 0z" />
</svg>
                  <h6 className='text-white' style={{marginLeft:'5px'}}>Date(Analysis)</h6>
                </div>
                <h3 className="text-white">
  {new Date(recommendation.date_of_recommandation).toLocaleDateString('en-GB')}
</h3>
              </div>
            </div>
          </div>
          <a href="#add-money" className={`add-money theme-color`} data-bs-toggle="modal">
            <span className={`${getStatusClass(recommendation.status)}`}>{recommendation.status}</span>
          </a>
        </div>
      </Link>
      </div>
    
    
    </div>
  );
  
   
  };


  const RecommendationCard1 = ({ recommendation }) => {
    const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

    const getBackgroundClass = (status) => {
      switch (status) {
        case 'buy':
          return 'bg-green';
        case 'short':
          return 'bg-red';
        case 'hold':
          return 'bg-yellow';
        case 'exit':
          return 'bg-black';
        case 'cancel':
          return 'bg-gray';
        default:
          return '';
      }
    };

    const getStatusClass = (status) => {
      switch (status) {
        case 'buy':
          return 'bg-green-status';
        case 'short':
          return 'bg-red-status';
        case 'hold':
          return 'bg-yellow-status';
        case 'exit':
          return 'bg-black-status';
        case 'cancel':
          return 'bg-gray-status';
        default:
          return '';
      }
    };

    // Function to compute the default quantity
const computeDefaultQuantity = (recommendation, data) => {
  const percentage = (data.Stock_rsk) * (data.user.percentage / 100);
  const percentage1 = (data.future_rsk) * (data.user.percentage_future / 100);
  const percentage2 = (data.option_rsk) * (data.user.percentage_option / 100);

  // Compute quantity based on recommendation stock type
  return recommendation.stock_type === 'options'
      ? Math.max(0, Math.floor(percentage2 / (recommendation.price_band_to - recommendation.stoploss)))
      : recommendation.stock_type === 'futures'
      ? Math.max(0, Math.floor(percentage1 / (recommendation.price_band_to - recommendation.stoploss)))
      : Math.max(0, Math.floor(percentage / (recommendation.price_band_to - recommendation.stoploss)));
};

// Function to find matching amount from stockPurchases

const recommendationId = recommendation.id;
const stockPurchases = data.stock_purchase;


const findMatchingAmount = (recommendationId, stockPurchases) => {
  // Iterate through stockPurchases array
  for (const purchase of stockPurchases) {
      // Check if the purchase is not null or undefined
      if (purchase && purchase.package_id !== undefined) {
          // Check if package_id matches recommendationId
          if (Number(purchase.package_id) === recommendationId) {
              // Return the amount if a match is found
              return purchase.amount;
          }
      }
  }
  // Return null if no match is found
  return null;
};


// Use the functions to determine the quantity
const determineQuantity = (recommendation, data, stockPurchases) => {
  // Check if there is a matching entry in stockPurchases
  const matchingAmount = findMatchingAmount(recommendationId, stockPurchases);
  // If a matching amount is found, use it
  if (matchingAmount === null) {
    return computeDefaultQuantity(recommendation, data);
  }

  // Otherwise, compute the default quantity
  return matchingAmount;

};

// Example usage in your JSX or elsewhere in your component
const quantity = determineQuantity(recommendation, data, stockPurchases);

// Now you can use `quantity` in your component as needed
const industry = data.stock_list.find(stock_list => stock_list.symbol_up === recommendation.symbol);

const risk = recommendation.price_band_to - recommendation.stoploss;

const reward =  recommendation.target - recommendation.price_band_to;

const final_reward = reward / risk;

// Round final_reward to two decimal places
const rounded_final_reward = parseFloat(final_reward.toFixed(2));

const total_return = (industry.u_last_price - recommendation.price_band_from ) /  recommendation.price_band_from * 100;

     const rounded_return = parseFloat(total_return.toFixed(2));

     const total_loss = (industry.u_last_price - recommendation.price_band_from) /  recommendation.price_band_from * 100;

     const rounded_loss = parseFloat(total_loss.toFixed(2));
  return (
    <div>
      
      <div>
      <Link to={`/recomm_details/${recommendation.id}?quantity=${quantity}`}>
        <div className="card-box mt-3">
          <div className={`card-details ${getBackgroundClass(recommendation.status)}`}>
            <div className="d-flex justify-content-between">
            <div className="">
                      <h5 className="fw-semibold">
                        {recommendation.script_name} | {recommendation.symbol}
                      </h5>
                      {industry && <div className="" style={{fontSize:'10px',color:'white'}}>
                                                      {industry.industry_name} | {industry.sector_name}
                                                  </div>
                                                  } </div>
                      <div className="">
                      <img
                        className="rec_img"
                        src={"https://images.5paisa.com/MarketIcons/" + recommendation.symbol+".png"}
                        alt="ellipse"
                      />
                      </div></div>
            <div className="">
              <div className="text-start">
                <div className="">
                  <div className='d-flex justify-content-between'>
                    <h3 className="mt-1 text-white">₹ {recommendation.price_band_from} - {recommendation.price_band_to} {recommendation.status === "hold" && (
  <span
    style={{
      backgroundColor: industry.u_last_price > recommendation.price_band_from ? 'green' : 'red',
      padding: '2px 5px',
      textAlign: 'center'
    }}
  >
    {"(" + (industry.u_last_price > recommendation.price_band_from ? rounded_return : rounded_loss) + "%)"}
  </span>
)}
</h3>
                    <h3 className="mt-1 text-white">QTY {quantity}</h3>
                  </div>
                </div>
                <p className='text-muted-new'>Buy in this Zone | <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
	<path fill="#b7c0bc" d="M12 20a8 8 0 0 0 8-8a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8m0-18a10 10 0 0 1 10 10a10 10 0 0 1-10 10C6.47 22 2 17.5 2 12A10 10 0 0 1 12 2m.5 5v5.25l4.5 2.67l-.75 1.23L11 13V7z" />
</svg> {recommendation.duration} {recommendation.duration_type} </p>
              </div>
            </div>
            <div className="amount-details">
              <div className="amount w-50 text-start">
                <div className="d-flex align-items-center justify-content-start">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14">
	<g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round">
		<path d="M13.48 7.516a6.5 6.5 0 1 1-6.93-7" />
		<path d="M9.79 8.09A3 3 0 1 1 5.9 4.21M7 7l2.5-2.5m2 .5l-2-.5l-.5-2l2-2l.5 2l2 .5z" />
	</g>
</svg>
                  <h6 className='text-white' style={{marginLeft:'5px'}}>Target</h6>
                </div>
                <h3 className="text-white" ><span style={{ backgroundColor: recommendation.result === 'success' && 'lightGreen',width:'60px',textAlign:'center'}}>₹{recommendation.target}</span></h3>
              </div>
              <div className="amount w-50 text-end border-0">
                <div className="d-flex align-items-center justify-content-end">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16">
	<path fill="#fff" d="M4.47.22A.75.75 0 0 1 5 0h6c.199 0 .389.079.53.22l4.25 4.25c.141.14.22.331.22.53v6a.75.75 0 0 1-.22.53l-4.25 4.25A.75.75 0 0 1 11 16H5a.75.75 0 0 1-.53-.22L.22 11.53A.75.75 0 0 1 0 11V5c0-.199.079-.389.22-.53Zm.84 1.28L1.5 5.31v5.38l3.81 3.81h5.38l3.81-3.81V5.31L10.69 1.5ZM8 4a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5A.75.75 0 0 1 8 4m0 8a1 1 0 1 1 0-2a1 1 0 0 1 0 2" />
</svg>
                 <h6 className='text-white'  style={{marginLeft:'5px'}}>Stoploss</h6>
                </div>
                <h3 className="text-white" ><span style={{ backgroundColor: recommendation.result === 'fail' ? '#ff6a6a' : recommendation.trailing_stoploss && '#ffc107',padding:'2px 5px',textAlign:'center'}}>₹{recommendation.trailing_stoploss ? recommendation.trailing_stoploss : recommendation.stoploss}</span></h3>
              </div>
            </div>
            <div className="amount-details">
            <div className="amount w-50 text-start">
                <div className="d-flex align-items-center justify-content-start">
                <svg xmlns="http://www.w3.org/2000/svg" width="15.5" height="12" viewBox="0 0 640 512">
	<path fill="#fff" d="M384 32h128c17.7 0 32 14.3 32 32s-14.3 32-32 32H398.4c-5.2 25.8-22.9 47.1-46.4 57.3V448h160c17.7 0 32 14.3 32 32s-14.3 32-32 32H128c-17.7 0-32-14.3-32-32s14.3-32 32-32h160V153.3c-23.5-10.3-41.2-31.6-46.4-57.3H128c-17.7 0-32-14.3-32-32s14.3-32 32-32h128c14.6-19.4 37.8-32 64-32s49.4 12.6 64 32m55.6 288h144.8L512 195.8zm72.4 96c-62.9 0-115.2-34-126-78.9c-2.6-11 1-22.3 6.7-32.1l95.2-163.2c5-8.6 14.2-13.8 24.1-13.8s19.1 5.3 24.1 13.8L631.3 305c5.7 9.8 9.3 21.1 6.7 32.1C627.2 382 574.9 416 512 416M126.8 195.8L54.4 320h144.9zM.9 337.1c-2.6-11 1-22.3 6.7-32.1l95.2-163.2c5-8.6 14.2-13.8 24.1-13.8s19.1 5.3 24.1 13.8L246.2 305c5.7 9.8 9.3 21.1 6.7 32.1C242 382 189.7 416 126.8 416S11.7 382 .9 337.1" />
</svg>
                 <h6 className='text-white' style={{marginLeft:'5px'}}> Risk Reward Ratio</h6>
                </div>
                <h3 className="text-white">{rounded_final_reward} times</h3>
              </div>
              <div className="amount w-50 text-end border-0">
                <div className="d-flex align-items-center justify-content-end">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20">
	<path fill="#fff" d="M5.673 0a.7.7 0 0 1 .7.7v1.309h7.517v-1.3a.7.7 0 0 1 1.4 0v1.3H18a2 2 0 0 1 2 1.999v13.993A2 2 0 0 1 18 20H2a2 2 0 0 1-2-1.999V4.008a2 2 0 0 1 2-1.999h2.973V.699a.7.7 0 0 1 .7-.699M1.4 7.742v10.259a.6.6 0 0 0 .6.6h16a.6.6 0 0 0 .6-.6V7.756zm5.267 6.877v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zm-8.333-3.977v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zM4.973 3.408H2a.6.6 0 0 0-.6.6v2.335l17.2.014V4.008a.6.6 0 0 0-.6-.6h-2.71v.929a.7.7 0 0 1-1.4 0v-.929H6.373v.92a.7.7 0 0 1-1.4 0z" />
</svg>
                  <h6 className='text-white' style={{marginLeft:'5px'}}>Date(Analysis)</h6>
                </div>
                <h3 className="text-white">
  {new Date(recommendation.date_of_recommandation).toLocaleDateString('en-GB')}
</h3>

              </div>
            </div>
          </div>
         <a href="#add-money" className={`add-money theme-color`} data-bs-toggle="modal">
            <span className={`${getStatusClass(recommendation.status)}`}>{recommendation.status}</span>
         </a>
        </div>
      </Link>
      </div>
    
    
    </div>
  );
  
   
  };

  const StockAlertCard = ({ recommendation }) => {
    const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

    const getBackgroundClass = (status) => {
      switch (status) {
        case 'buy':
          return 'bg-green';
        case 'short':
          return 'bg-red';
        case 'hold':
          return 'bg-yellow';
        case 'exit':
          return 'bg-black';
        default:
          return '';
      }
    };

    const getStatusClass = (status) => {
      switch (status) {
        case 'buy':
          return 'bg-green-status';
        case 'short':
          return 'bg-red-status';
        case 'hold':
          return 'bg-yellow-status';
        case 'exit':
          return 'bg-black-status';
        case 'cancle':
          return 'bg-gray-status';
        default:
          return '';
      }
    };

    // Function to compute the default quantity
const computeDefaultQuantity = (recommendation, data) => {
  const percentage = (data.Stock_rsk) * (data.user.percentage / 100);
  const percentage1 = (data.future_rsk) * (data.user.percentage_future / 100);
  const percentage2 = (data.option_rsk) * (data.user.percentage_option / 100);

  // Compute quantity based on recommendation stock type
  return recommendation.stock_type === 'options'
      ? Math.max(0, Math.floor(percentage2 / (recommendation.price_band_to - recommendation.stoploss)))
      : recommendation.stock_type === 'futures'
      ? Math.max(0, Math.floor(percentage1 / (recommendation.price_band_to - recommendation.stoploss)))
      : Math.max(0, Math.floor(percentage / (recommendation.price_band_to - recommendation.stoploss)));
};

// Function to find matching amount from stockPurchases

const recommendationId = recommendation.id;
const stockPurchases = data.stock_purchase;


const findMatchingAmount = (recommendationId, stockPurchases) => {
  // Iterate through stockPurchases array
  for (const purchase of stockPurchases) {
      // Check if the purchase is not null or undefined
      if (purchase && purchase.package_id !== undefined) {
          // Check if package_id matches recommendationId
          if (Number(purchase.package_id) === recommendationId) {
              // Return the amount if a match is found
              return purchase.amount;
          }
      }
  }
  // Return null if no match is found
  return null;
};


// Use the functions to determine the quantity
const determineQuantity = (recommendation, data, stockPurchases) => {
  // Check if there is a matching entry in stockPurchases
  const matchingAmount = findMatchingAmount(recommendationId, stockPurchases);
  // If a matching amount is found, use it
  if (matchingAmount === null) {
    return computeDefaultQuantity(recommendation, data);
  }

  // Otherwise, compute the default quantity
  return matchingAmount;

};

// Example usage in your JSX or elsewhere in your component
const quantity = determineQuantity(recommendation, data, stockPurchases);

// Now you can use `quantity` in your component as needed
 


  return (
    <div style={{overflowX:'hidden'}}>
      <Link to={`/recomm_details/${recommendation.id}?quantity=${quantity}`}>
    
      <div className="bill-box mb-3 pb-3"> {/* Added pb-3 for bottom padding */}
          <div className="d-flex gap-3">
            <div className="bill-icon" style={{width:'100px'}}>
              <img className="img-fluid icon" src={"https://images.5paisa.com/MarketIcons/" + recommendation.symbol+".png"} alt="p6" />
            </div>
            <div className="bill-details2">
              <h6 className="dark-text">{recommendation.script_name} |<span className="text-muted"> {recommendation.stock_type}</span></h6>
              <h6 className="light-text mt-2"><span className="">BZ: (₹{recommendation.price_band_from} - ₹{recommendation.price_band_to})</span> | {recommendation.duration} {recommendation.duration_type}</h6>
            </div>
            <div className="bill-details">
              <h6 className="dark-text" style={{textAlign:'end'}}> QTY {quantity}</h6>
              </div>
           
          </div>
          <div className="bill-price">
            <h5>T: ₹{recommendation.target} | SL: ₹{recommendation.trailing_stoploss ? recommendation.trailing_stoploss : recommendation.stoploss} </h5>
         
            <a href="#" data-bs-toggle="modal" className={`btn bill-pay bill-paid ${getStatusClass(recommendation.status)}`} style={{width:'80px'}}>{recommendation.status}</a>
          </div>
        </div>
        </Link>
    </div>
  );
  
   
  };
// Use the functions to determine the quantity


// Example usage in your JSX or elsewhere in your component

  return (
    <div style={{overflow:'hidden'}}>
      <Helmet>
        <title>Finvalley | Stock Analysis</title>
        <meta name="description" content="Finvalley is Best Application For Stock Analysis" />
        <meta name="keywords" content="stock,StockMarket,trading,trades" />
      </Helmet>
       {isPopupOpen && (
        <div className="describe_popup">
          <div className="describe_popup-content">
            <p style={{margin:'5px 0 4px 0'}}>
              Please be advised that the stock ideas and educational content provided by FINVALLEY are intended solely for informational and educational purposes. FINVALLEY is not a certified research analyst or financial advisor. Any investment decisions made based on the information provided are undertaken at your own risk.
            </p>
            <p style={{margin:'5px 0 4px 0'}}>
              FINVALLEY does not warrant the accuracy, completeness, or reliability of the information provided. Investing in the stock market carries inherent risks, including the potential loss of principal. Before making any investment decisions, it is strongly recommended that you conduct your own research or seek advice from a qualified financial professional.
            </p>
            <p style={{margin:'5px 0 4px 0'}}>
              FINVALLEY explicitly disclaims any liability for any direct, indirect, incidental, special, or consequential damages arising from the use or reliance on the information provided. We do not endorse or recommend specific stocks, investments, or trading strategies.
            </p>
            <p style={{margin:'5px 0 4px 0'}}>
              For personalized investment advice tailored to your individual financial circumstances and investment goals, please consult with a certified financial advisor. Keep in mind that all investments involve risks, and past performance is not indicative of future results.
            </p>
            {/* "I understood" button */}
            <center><button className="popup-button" onClick={handleClosePopup}>
              I understood
            </button></center>
          </div>
        </div>
      )}
      {showModal && <Modal message={modalMessage} closeModal={closeModal} />}

      <Sidebar />
      {/* <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/iconsax@1.1.5/css/iconsax.css" integrity="sha384-kJ8Bl2nzBRn1Fls+eWnBEqMWvAqNSMIAd1N2fXk4UuI2V5kyAFLtk4WuIqHg4HS7" crossOrigin="anonymous" /> */}
      <br />

      <div >
        {/* <img className="Himg-fluid img" src={logo1} alt="v1" /> */}

        <form className="theme-form p-2" style={{marginTop:'-25px'}}>
          <div className="form-group mt-2">
            <div className="form-input">
            <input
  type="text"
  className="form-control"
  id="inputusername"
  placeholder="Search here..."
  defaultValue={searchQuery}
  onChange={(e) => {
    setSearchQuery(e.target.value); // Update searchQuery state
    handleSearch(e, data); // Trigger search function
  }}
/>

{suggestions.length > 0 && searchQuery !== '' && (
  <div className="suggestion-dropdown" style={{marginTop:'10px',position:'absolute',zIndex:'999',width:'100%'}}>
    <ul>
      {suggestions.map((stock, index) => (
        <Link to={`/recomm_details/${stock.id}`} key={index}>
          <div className="bill-box">
            <div className="d-flex gap-3">
              <div className="bill-icon" style={{width:'100px'}}>
                <img className="img-fluid icon" src={`https://images.5paisa.com/MarketIcons/${stock.symbol}.png`} alt={stock.symbol} />
              </div>
              <div className="bill-details2" style={{alignContent:'center'}}>
                <h6 className="dark-text">{stock.script_name} | <span className="text-muted">{stock.symbol}</span></h6>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </ul>
  </div>
)}



           
              
              <svg className="contact-img" xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 512 512">
	<path fill="#7031fe" d="M456.69 421.39L362.6 327.3a173.8 173.8 0 0 0 34.84-104.58C397.44 126.38 319.06 48 222.72 48S48 126.38 48 222.72s78.38 174.72 174.72 174.72A173.8 173.8 0 0 0 327.3 362.6l94.09 94.09a25 25 0 0 0 35.3-35.3M97.92 222.72a124.8 124.8 0 1 1 124.8 124.8a124.95 124.95 0 0 1-124.8-124.8" />
</svg>
            </div>

            
          </div>
        </form>
    
       <section style={{paddingTop:'5px'}}>
    <div className="custom-container" style={{padding:'0 5px'}}>
    <Swiper
      slidesPerView={1}
      spaceBetween={10}
      pagination={{
        clickable: false,
        bulletActiveClass: 'swiper-pagination-bullet-active', // Bullet active class
        bulletClass: 'swiper-pagination-bullet', // Bullet class
        renderBullet: (index, className) => {
          // Return the custom bullet with inline styles
          return `<span class="${className}" style="background-color: white;"></span>`;
        },
      }}
      modules={[Pagination]}
      className="mySwiper"
    >
<SwiperSlide>
<section style={{paddingTop:'0px'}}>
    <div>
      <div className="crypto-wallet-box">
        <div className="card-details" style={{margin:'0 5px'}}>
          <div className="d-block w-75">
            <h5 className="fw-semibold">Capital For Stock</h5>
            <h2 className="mt-2" style={{paddingLeft:'0px'}}>₹{data.Stock_rsk} <Link to="/risk_management?activeTab=1"><span className="mt-2" style={{color:'#e3d6f7',fontSize:'12px'}}>Manage Capital</span></Link></h2>
          </div>
          <div className="price-difference">
            <div>
            <i className="menu-icon" data-feather="arrow-up" />
            </div>
            <div style={{textAlign:'center'}}> 
            <h6>{data.user.percentage}% (₹{stock_risk_amount})</h6>
            <h6>Risk per Trade </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</SwiperSlide>
{/* <SwiperSlide>
<section style={{paddingTop:'0px'}}>
    <div>
      <div className="crypto-wallet-box">
        <div className="card-details" style={{margin:'0 5px'}}>
          <div className="d-block w-75">
            <h5 className="fw-semibold">Capital For Future</h5>
            <h2 className="mt-2">₹{data.future_rsk} <Link to="/risk_management?activeTab=2"><span className="mt-2" style={{color:'#e3d6f7',fontSize:'12px'}}>Manage Capital</span></Link></h2>
          </div>
          <div className="price-difference">
            <div>
            <i className="menu-icon" data-feather="arrow-up" />
            </div>
            <div style={{textAlign:'center'}}> 
            <h6>{data.user.percentage_future}% (₹{future_risk_amount})</h6>
            <h6>Risk per Trade </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</SwiperSlide>

<SwiperSlide>
<section style={{paddingTop:'0px'}}>
    <div>
      <div className="crypto-wallet-box">
        <div className="card-details" style={{margin:'0 5px'}}>
          <div className="d-block w-75">
            <h5 className="fw-semibold">Capital For Option</h5>
            <h2 className="mt-2">₹{data.option_rsk} <Link to="/risk_management?activeTab=3"><span className="mt-2" style={{color:'#e3d6f7',fontSize:'12px'}}>Manage Capital</span></Link></h2>
            
          </div>
          <div className="price-difference">
            <div>
            <i className="menu-icon" data-feather="arrow-up" />
            </div>
            <div style={{textAlign:'center'}}> 
            <h6>{data.user.percentage_option}% (₹{option_risk_amount})</h6>
            <h6>Risk per Trade </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</SwiperSlide> */}

        </Swiper>
      
    </div>
  </section>
       
  <marquee style={{color:'white',backgroundColor:'#7d39e1',padding:'4px',
          fontSize:'15px',width:'100%',marginTop:'8px'}}>
          {data.getquotes.quotes}
        </marquee>
  
           
          </div>
        
         
      {/* categories section starts */}
      
      <section className="categories-section section-b-space">
        <div className="custom-container">
        <ul className="categories-list">
      <li>
        <a  onClick={() => handleCheck('intraday')}>
          <div className="categories-box">
          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 256 256">
	<path fill="#7031fe" d="M20 128a76.08 76.08 0 0 1 76-76h99l-3.52-3.51a12 12 0 1 1 17-17l24 24a12 12 0 0 1 0 17l-24 24a12 12 0 0 1-17-17L195 76H96a52.06 52.06 0 0 0-52 52a12 12 0 0 1-24 0m204-12a12 12 0 0 0-12 12a52.06 52.06 0 0 1-52 52H61l3.52-3.51a12 12 0 1 0-17-17l-24 24a12 12 0 0 0 0 17l24 24a12 12 0 1 0 17-17L61 204h99a76.08 76.08 0 0 0 76-76a12 12 0 0 0-12-12" />
</svg>
          </div>
          <h5 className="mt-2 text-center">BTST</h5>
        </a>
      </li>

      <li>
        <a onClick={() => handleCheck('swing')}>
          <div className="categories-box">
           <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24">
	<path fill="none" stroke="#7031fe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12h4l3 8l4-16l3 8h4" />
</svg>
          </div>
          <h5 className="mt-2 text-center">Swing Trade</h5>
        </a>
      </li>

      <li>
        <a  onClick={() => handleCheck('investment')}>
          <div className="categories-box">
          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24">
	<path fill="#7031fe" d="M8 11.75V7.5q0-.625.438-1.062T9.5 6t1.063.438T11 7.5v4.25q0 .625-.437 1.063T9.5 13.25t-1.062-.437T8 11.75m5-.225V3.5q0-.625.438-1.062T14.5 2t1.063.438T16 3.5v8.025q0 .75-.462 1.125t-1.038.375t-1.037-.375T13 11.525m-10 3.45V11.5q0-.625.438-1.062T4.5 10t1.063.438T6 11.5v3.475q0 .75-.462 1.125t-1.038.375t-1.037-.375T3 14.975m2.4 6.075q-.65 0-.913-.612T4.7 19.35l4.1-4.1q.275-.275.663-.3t.687.25L13 17.65l5.6-5.6H18q-.425 0-.712-.288T17 11.05t.288-.712t.712-.288h3q.425 0 .713.288t.287.712v3q0 .425-.288.713T21 15.05t-.712-.288T20 14.05v-.6l-6.25 6.25q-.275.275-.663.3t-.687-.25L9.55 17.3L6.1 20.75q-.125.125-.312.213t-.388.087" />
</svg>
          </div>
          <h5 className="mt-2 text-center">Investment</h5>
        </a>
      </li>

      <li>
        <a  onClick={() => handleCheck('multibagger')}>
          <div className="categories-box">
          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 256 256">
	<path fill="#7031fe" d="M216 72h-35.08c.39-.33.79-.65 1.17-1A29.53 29.53 0 0 0 192 49.57A32.62 32.62 0 0 0 158.44 16A29.53 29.53 0 0 0 137 25.91a54.94 54.94 0 0 0-9 14.48a54.94 54.94 0 0 0-9-14.48A29.53 29.53 0 0 0 97.56 16A32.62 32.62 0 0 0 64 49.57A29.53 29.53 0 0 0 73.91 71c.38.33.78.65 1.17 1H40a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16v64a16 16 0 0 0 16 16h60a4 4 0 0 0 4-4v-92H40V88h80v32h16V88h80v32h-80v92a4 4 0 0 0 4 4h60a16 16 0 0 0 16-16v-64a16 16 0 0 0 16-16V88a16 16 0 0 0-16-16M84.51 59a13.69 13.69 0 0 1-4.5-10a16.62 16.62 0 0 1 16.58-17h.49a13.69 13.69 0 0 1 10 4.5c8.39 9.48 11.35 25.2 12.39 34.92C109.71 70.39 94 67.43 84.51 59m87 0c-9.49 8.4-25.24 11.36-35 12.4C137.7 60.89 141 45.5 149 36.51a13.69 13.69 0 0 1 10-4.5h.49A16.62 16.62 0 0 1 176 49.08a13.69 13.69 0 0 1-4.51 9.92Z" />
</svg>
          </div>
          <h5 className="mt-2 text-center">Multi Baggar</h5>
        </a>
      </li>
    </ul>
        </div>
      </section>
      {/* categories section end */}
     

      {data && data.package_active && data.package_active.status === 'success' ? (
  <>
    <div className="custom-container" >
      <section style={{padding:'10px 0',width:'100%',backgroundColor:'#5e17eb',borderRadius:'15px',textAlign:'center'}}><Link to="near" style={{padding:'10px',color:'white'}}>Stock Under Surveillance ({data.recommandation_with_stock_list.total_count})</Link>
      </section></div>
 {data.today_recommendation && data.today_recommendation.length > 0 && (
    <section className="section-b-space">
        <div className="custom-container">
            <div className="title">
                <h2>Today's Analysis</h2>
                <Link to="recommandation">See all</Link>
            </div>
            {data.today_recommendation.map((recommendation, index) => (
          <RecommendationCard key={index} recommendation={recommendation} />
        ))}
        </div>
    </section>
)}


    {data.this_week_Recommendation &&
      data.this_week_Recommendation.length > 0 && (
        <section className="section-b-space" style={{marginTop:'-15px',paddingTop:'10px'}}>
          <div className="custom-container">
            <div className="title" style={{margin:'15px 0 0 0'}}>
              <h2>This Week's Analysis</h2>
              <Link to="recommandation">See all</Link>
            </div>
            <Swiper
      slidesPerView={1}
      spaceBetween={10}
      pagination={{
        clickable: false,
        bulletActiveClass: 'swiper-pagination-bullet-active', // Bullet active class
        bulletClass: 'swiper-pagination-bullet', // Bullet class
        renderBullet: (index, className) => {
          // Return the custom bullet with inline styles
          return `<span class="${className}" style="background-color: #622cfd;"></span>`;
        },
      }}
      modules={[Pagination]}
      className="mySwiper"
    >
            {/* Group data.this_week_Recommendation into chunks of 6 items each */}
            {data.this_week_Recommendation.reduce((chunks, recommendation, index) => {
                const chunkIndex = Math.floor(index / 3);
                
                // Create an array for each chunk if it doesn't exist yet
                if (!chunks[chunkIndex]) {
                    chunks[chunkIndex] = [];
                }
                
                // Add the current recommendation to the current chunk
                chunks[chunkIndex].push(recommendation);
                
                return chunks;
            }, []).map((recommendationChunk, chunkIndex) => (
                <SwiperSlide key={chunkIndex}>
                    <div className="row">
                        {/* Map through each recommendation in the current chunk */}
                        {recommendationChunk.map((recommendation, index) => (
                            <div className="col-12" key={index}>
                                {/* Render each recommendation using the RecommendationCard1 component */}
                                <RecommendationCard1 recommendation={recommendation} />
                            </div>
                        ))}
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
          </div>
        </section>
      )}
  </>
) : (
  <div>
  <div className="row" style={{padding:'10px',marginBottom:'-50px'}}>
    <div className="col box-design">
      <h2>Access Analysis</h2>
      <p>Subscribe now to access Analysis.</p>
    </div>
    <div className="col box-design">
      <h2>Get Calls</h2>
      <p>Subscribe now to receive calls of buy and sell.</p>
    </div>
  </div>
  <div className="text-center mt-4">
    <Link to="/pricing" className="btn btn-primary">Subscribe Now</Link>
  </div>
</div>

)}

{/* 
<section>
  <div className="custom-container" >
  <div className="title">
            <h2>Stock Alerts</h2>
          </div>
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom:'10px' }}>
        <h2 className={`stock_tabs2 ${selectedTab === 'buy' ? 'active2' : ''}`} onClick={() => setSelectedTab('buy')}>Buy</h2>
            <h2 className={`stock_tabs2 ${selectedTab === 'stoploss' ? 'active2' : ''}`} onClick={() => setSelectedTab('stoploss')}>Stop Loss</h2>
            <h2 className={`stock_tabs2 ${selectedTab === 'target' ? 'active2' : ''}`} onClick={() => setSelectedTab('target')}>Target</h2>

        </div>
 
    <div className="m_wrap">
      <div className="g-3 mb-3" >
        {data.recommandation_with_stock_list.near_to_buy && (
     selectedTab === 'buy' && ( data.recommandation_with_stock_list.near_to_buy.map((recommendation, index) => (
          <StockAlertCard key={index} recommendation={recommendation} />
        ))) )}
         {data.recommandation_with_stock_list.near_to_stoploss && (
        selectedTab === 'stoploss' && (data.recommandation_with_stock_list.near_to_stoploss.map((recommendation, index) => (
          <StockAlertCard key={index} recommendation={recommendation} />
        ))) )}
         {data.recommandation_with_stock_list.near_to_target && (
        selectedTab === 'target' && (data.recommandation_with_stock_list.near_to_target.map((recommendation, index) => (
          <StockAlertCard key={index} recommendation={recommendation} />
        ))) )}
       
      
      </div>
    </div>
  </div>
</section> */}

      {/* card end */}

      {/* bill details section starts */}

  {data && data.package_active && data.package_active.status === 'success' &&   <> 
  {data.open_calls_ten &&
      data.open_calls_ten.length > 0 && (
        <section style={{marginTop:'-25px'}}>
        <div className="custom-container">
          <div className="title">
            <h2>Open Calls </h2>
            
            <Link to="open_call">See all</Link>
          </div>
          <div className="m_wrap">
            <div className="g-3 swiper-container mb-3">
              {data.open_calls_ten.map((open_call, index) => (
                <Link to={`/recomm_details/${open_call.id}`}>
                <div className="swiper-item">
                  <div className="">
                    <div className="bill-box mb-3">
                      <div className="d-flex gap-3">
                        <div className="bill-icon">
                          <img
                            className="img-fluid icon"
                            src={"https://images.5paisa.com/MarketIcons/" + open_call.symbol+".png"}
                            alt="p6"
                          />
                        </div>
                        <div className="bill-details3">
                          <h6 className="dark-text">
                            {open_call.script_name} |
                            <span className="text-muted">
                              {" "}
                              {open_call.stock_type}
                            </span>
                          </h6>
                          <h6 className="light-text mt-2">
                            <span className="">
                              BZ: (₹{open_call.price_band_from} - ₹
                              {open_call.price_band_to})
                            </span>{" "}
                            | {open_call.duration} {open_call.duration_type}
                          </h6>
                        </div>
                      </div>
                      <div className="bill-price">
                        <h5>
                          T: ₹{open_call.target} | <span style={{ backgroundColor: open_call.trailing_stoploss && '#ffc107'}}></span>SL: ₹{open_call.trailing_stoploss ? open_call.trailing_stoploss : open_call.stoploss}{" "}
                        </h5>

                        <a href="#" data-bs-toggle="modal" className={`btn bill-pay bill-paid ${getStatusClass(open_call.status)}`} style={{width:'80px'}}>   {data.stock_purchase && data.stock_purchase.length > 0 ? (
        // Filter and calculate the quantity
        (() => {
            let quantity = 0;

            // Filter the stock purchase based on the package_id and recommendation.id
            const stockPurchaseMatch = data.stock_purchase.find(stock_purchase => {
                return stock_purchase && Number(stock_purchase.package_id) === open_call.id;
            });

            if (stockPurchaseMatch) {
                // If there is a match, use the stock purchase amount
                quantity = "PURCHASED";
            } else {
              quantity = open_call.status.toUpperCase();
              
            }
            
            // Return the calculated quantity
            return (
                <span >
                    {quantity}
                </span>
            );
        })()
    ) : null }</a>
                      </div>
                    </div>
                  </div>
                </div>
                </Link>))}
            </div>
          </div>
        </div>
        </section>
      )}
      </>
    }
      {/* bill details section starts */}
      
      {/* quick send section starts */}

      {data.stock_under_radar && data.stock_under_radar.length > 0 && (
        <section>
          <div className="custom-container">
            <div className="title" style={{marginTop:'10px'}}>
              <h2>Stocks in Radar</h2>
              <Link to="stock">See all</Link>
            </div>
          </div>
          <div className="quick-send">
            {data.stock_under_radar.map((under_radar, index) => (
              <div className="profile">
                <Link to="stock">
                  <img
                    className="img-fluid person-img"
                    src={"https://images.5paisa.com/MarketIcons/" + under_radar.symbol+".png"}
                    alt="p1"
                  />
                </Link>
                <p className="text-center fs-7 mt-1">
                  {under_radar.stock_name}
                </p>
              </div>
            ))}
          </div>
        </section>
      )}

      {/* quick send section end */}
      {/* Transaction section starts */}

      <section>
    <div className="custom-container">
        <div className="title">
            <h2>Market Trend</h2>
            <Link to="market_trends">See all</Link>
        </div>
        
        <Swiper
            slidesPerView={1} // Show one slide at a time
            spaceBetween={10} // Space between slides
            pagination={{ clickable: false }} // Pagination dots
            modules={[Pagination]}
            className="mySwiper"
        >
            {/* Loop through data.market_trend in chunks of 6 */}
            {data.market_trend.reduce((chunks, trend, index) => {
                const chunkIndex = Math.floor(index / 6);
                
                // If chunk doesn't exist yet, create an empty array for it
                if (!chunks[chunkIndex]) {
                    chunks[chunkIndex] = [];
                }
                
                // Push current trend to the appropriate chunk
                chunks[chunkIndex].push(trend);
                
                return chunks;
            }, []).map((trendChunk, chunkIndex) => (
                <SwiperSlide key={chunkIndex}>
                    <div className="row gy-3">
                        {/* Map through each trend in the current chunk */}
                        {trendChunk.map((trend, index) => (
                            <div className="col-12" key={index}>
                                <div className="transaction-box">
                                    <Link to="#" className="d-flex gap-3">
                                        <div className={`transaction-image1 ${getTrendBackground(trend.trend)}`}>
                                            {/* SVG icon based on trend */}
                                            {trend.trend === "up" ? (
                                               <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                               <g fill="#fff">
                                                 <path d="m14.829 11.948l1.414-1.414L12 6.29l-4.243 4.243l1.415 1.414L11 10.12v7.537h2V10.12z" />
                                                 <path fill-rule="evenodd" d="M19.778 4.222c-4.296-4.296-11.26-4.296-15.556 0c-4.296 4.296-4.296 11.26 0 15.556c4.296 4.296 11.26 4.296 15.556 0c4.296-4.296 4.296-11.26 0-15.556m-1.414 1.414A9 9 0 1 0 5.636 18.364A9 9 0 0 0 18.364 5.636" clip-rule="evenodd" />
                                               </g>
                                             </svg>
                                            ) : trend.trend === "side" ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                                    <g fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                                                        <circle cx="12" cy="12" r="9" />
                                                        <path d="M9 12h6m0 0l-3 3m3-3l-3-3" />
                                                    </g>
                                                </svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                                    <path fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0-18 0m5 0l4 4m0-8v8m4-4l-4 4" />
                                                </svg>
                                            )}
                                        </div>
                                        <div className="transaction-details">
                                            <div className="transaction-name">
                                                <h2>{trend.sector_of_trend}</h2>
                                                <h6 className={`uppercase`} style={{ color: trend.trend === "up" ? "#2b8b64" : trend.trend === "side" ? "#d18925" : "#ce3c60" }}>{getTrendImage(trend.trend)}</h6>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    </div>
</section>

      {/* all cards section end */}

      {/* saving plans section starts */}
      {/* <section>
        <div className="custom-container mb-3">
          <div className="title">
            <h2>Portfolios</h2>
          </div>
          <div className="m_wrap">
            <div className="g-3 swiper-container mb-3">
              {data.portfolio.map((portfolio, index) => (
                <div className="swiper-item">
                  <div className="">
                    <div className="saving-plan-box">
                      <div className="saving-plan-icon">
                        <img
                          className="img-fluid"
                          src={
                            apiImageUrl + "/portfolio_logo/" + portfolio.logo
                          }
                          alt="p10"
                        />
                      </div>
                      <h3>{portfolio.name}</h3>
                      <h6>Amount</h6>
                      <div
                        className="progress"
                        role="progressbar"
                        aria-label="progressbar"
                        aria-valuenow={0}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <div className="progress-bar bar1" />
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-2">
                        <h5 className="theme-color">{portfolio.return}</h5>
                        <img
                          className="img-fluid arrow"
                          src={arrow}
                          alt="arrow"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <br />
        <br />
      </section> */}
      {/* saving plans section end */}
      {/* monthly statistics section starts */}
      <section>
        <div className="custom-container">
          <div className="statistics-banner">
            <div className="d-flex justify-content-center gap-3">
              <div className="statistics-image">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<g fill="none" fill-rule="evenodd">
		<path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
		<path fill="#fff" d="M15 5.5a3.5 3.5 0 1 1 .994 2.443L11.67 10.21c.213.555.33 1.16.33 1.79a4.99 4.99 0 0 1-.33 1.79l4.324 2.267a3.5 3.5 0 1 1-.93 1.771l-4.475-2.346a5 5 0 1 1 0-6.963l4.475-2.347A3.524 3.524 0 0 1 15 5.5" />
	</g>
</svg>
              </div>
              <div className="statistics-content d-block">
                <h5>Refer a Friend</h5>
                <h6>Earn 20% on referring to your friend</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* monthly statistics section end */}
      {/* news-update section starts */}
      <section className="section-b-space">
        <div className="custom-container">
          <div className="title">
            <h2>Information Bits</h2>
            <Link to="/blog/10000000">See all</Link>
          </div>
          <div className="row gy-3">
            {data.latest_content_bytes.map((content_byte, index) => (
               <Link to={`/blog_details/${content_byte.id}/${content_byte.blog_title.replace(/\s+/g, '-')}`} style={{textDecoration:'none'}}>
                   
              <div className="col-12">
                <div className="news-update-box">
                  <div className="d-flex align-items-center gap-3">
                   
                      <img
                        className="img-fluid news-update-image"
                        src={`${apiImageUrl}/blog_image/${content_byte.blog_image}`}
                        alt="img1"
                      />
                    
                    <div className="news-update-content">
                        <h3>{content_byte.blog_title}</h3>
                      
                      <div className="news-writer" style={{marginTop:'5px'}}>
                        <div className="d-flex ">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 20 20">
	<path fill="#000" d="M5.673 0a.7.7 0 0 1 .7.7v1.309h7.517v-1.3a.7.7 0 0 1 1.4 0v1.3H18a2 2 0 0 1 2 1.999v13.993A2 2 0 0 1 18 20H2a2 2 0 0 1-2-1.999V4.008a2 2 0 0 1 2-1.999h2.973V.699a.7.7 0 0 1 .7-.699M1.4 7.742v10.259a.6.6 0 0 0 .6.6h16a.6.6 0 0 0 .6-.6V7.756zm5.267 6.877v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zm-8.333-3.977v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zM4.973 3.408H2a.6.6 0 0 0-.6.6v2.335l17.2.014V4.008a.6.6 0 0 0-.6-.6h-2.71v.929a.7.7 0 0 1-1.4 0v-.929H6.373v.92a.7.7 0 0 1-1.4 0z" />
</svg> <p className="fs-7" style={{margin:'0 0 0 2px'}}> {content_byte.date}</p></div>
<div className="d-flex">
<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 36 36">
	<path fill="#000" d="M33.87 8.32L28 2.42a2.07 2.07 0 0 0-2.92 0L4.27 23.2l-1.9 8.2a2.06 2.06 0 0 0 2 2.5a2.14 2.14 0 0 0 .43 0l8.29-1.9l20.78-20.76a2.07 2.07 0 0 0 0-2.92M12.09 30.2l-7.77 1.63l1.77-7.62L21.66 8.7l6 6ZM29 13.25l-6-6l3.48-3.46l5.9 6Z" class="clr-i-outline clr-i-outline-path-1" />
	<path fill="none" d="M0 0h36v36H0z" />
</svg> <p className="fs-7" style={{margin:'0 0 0 0'}}> {content_byte.author}</p></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
      {/* news-update section end */}
      {/* panel-space start */}
      <section className="panel-space" />
      {/* panel-space end */}
      {/* bottom navbar start */}

      {/* bottom navbar end */}
      {/* add money modal start */}
      <Footer />
     
      
    </div>
  );
}

export default App;

// https://stock.ukvalley.com/public/api/homepageapi/1
