import React from "react";
import logo from "./logo.svg";

import feather from "feather-icons";
import Footer from "./footer";

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";

// Assuming you're using React Router and have a route set up for blog details

import favicon from "./assets/theme_asset/logo/favicon.png";

import logo1 from "./assets/theme_asset/logo/logo.png";

import logo2 from "./assets/theme_asset/svg/logo1.svg";
import facebook from "./assets/theme_asset/svg/facebook.svg";
import google from "./assets/theme_asset/svg/google.svg";

import auth_bg from "./assets/theme_asset/background/auth-bg.jpg";

import arrow from "./assets/theme_asset/svg/arrow.svg";

import arrow_down_right from "./assets/theme_asset/svg/arrow-down-right.svg";
import arrow_up_right from "./assets/theme_asset/svg/arrow-up-right.svg";

import p1 from "./assets/theme_asset/person/p1.png";
import p2 from "./assets/theme_asset/person/p2.png";
import p3 from "./assets/theme_asset/person/p3.png";
import p4 from "./assets/theme_asset/person/p4.png";
import p5 from "./assets/theme_asset/person/p5.png";
import ellipse from "./assets/theme_asset/svg/ellipse.svg";

import a1 from "./assets/theme_asset/svg/1.svg";
import a2 from "./assets/theme_asset/svg/2.svg";
import a3 from "./assets/theme_asset/svg/3.svg";
import a4 from "./assets/theme_asset/svg/4.svg";
import a5 from "./assets/theme_asset/svg/5.svg";

import a6 from "./assets/theme_asset/svg/6.svg";
import a7 from "./assets/theme_asset/svg/7.svg";
import a8 from "./assets/theme_asset/svg/8.svg";
import a9 from "./assets/theme_asset/svg/9.svg";
import a10 from "./assets/theme_asset/svg/10.svg";
import a11 from "./assets/theme_asset/svg/11.svg";

import card_chip from "./assets/theme_asset/svg/card-chip.svg";

import img1 from "./assets/theme_asset/1.jpg";

import contact from "./assets/theme_asset/svg/contact.svg";

import auth6 from "./assets/theme_asset/authentication/6.svg";
import auth1 from "./assets/theme_asset/authentication/1.svg";

import img2 from "./assets/theme_asset/2.jpg";
import img3 from "./assets/theme_asset/3.jpg";
import img4 from "./assets/theme_asset/4.jpg";
import l49 from "./assets/theme_asset/logo/48.png";

import search from "./assets/search (1).png";

import "./App.css";

import scan from "./assets/theme_asset/svg/scan.svg";
import { useEffect } from "react";
import { Bar } from "react-chartjs-2";

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import Modal from "./modal";

const UserProfile = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="auth-header">
        <div>
          <Link to="/" style={{ textDecoration: "none" }}>
            <button
              style={{
                padding: "8px 16px",
                color: "white",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </Link>
        </div>

        <div className="auth-content" style={{ justifyContent: "center" }}>
          <div className="row">
            <div className="col-2">
              <img className="contact-img" src={search} alt="contact" />
            </div>
            <div className="col-10" style={{ paddingLeft: "40px" }}>
              <div className="">
                <h2 className="text-black">Valeed Pathan</h2>
              </div>

              <div className="">
                <h3 className="text-black">view profile</h3>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="profile">
          <img className="contact-img" src={search} alt="contact" />
          <h3>Anamika Roy</h3>
          <p>Designer</p>
        </div> */}
      </div>

      <form className="auth-form">
        <div className="custom-container">
          <div>
            <div className="bill-box mb-3 bg-light mt-3">
              <div className="">
                <div className="d-flex gap-3">
                  <div className="bill-icon">
                    <i className="service-icon" data-feather="play-circle" />
                  </div>
                  <div className="bill-details mt-2">
                    <h2>Became a Pro</h2>
                  </div>

                  <div className="float-right mt-2"></div>
                </div>

                <hr></hr>

                <div className="d-flex gap-3">
                  <div className="bill-icon">
                    <i className="service-icon" data-feather="stop-circle" />
                  </div>
                  <div className="bill-details mt-2">
                    <h2>Waya Fno</h2>
                  </div>

                  <div className="float-right mt-2"></div>
                </div>

                <hr></hr>

                <div className="d-flex gap-3">
                  <div className="bill-icon">
                    <i className="service-icon" data-feather="x-octagon" />
                  </div>
                  <div className="bill-details mt-2">
                    <h2>About Us</h2>
                  </div>

                  <div className="float-right mt-2"></div>
                </div>

                <hr></hr>

                <div className="d-flex gap-3">
                  <div className="bill-icon">
                    <i className="service-icon" data-feather="percent" />
                  </div>
                  <div className="bill-details mt-2">
                    <h2>FAQs</h2>
                  </div>
                </div>
                <hr></hr>
                <div className="d-flex gap-3">
                  <div className="bill-icon">
                    <i className="service-icon" data-feather="percent" />
                  </div>
                  <div className="bill-details mt-2">
                    <h2>Refer A Friend</h2>
                  </div>
                </div>
              </div>
            </div>
            <button type="submit" className="btn theme-btn w-100">
              <Link to="/subcription" style={{ color: "white" }}>
                Upgrade to yearly plan
              </Link>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserProfile;
