import logo from './assets/theme_asset/favicon.png';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import { Pagination, Navigation } from 'swiper/modules';
import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import SkeletonLoader from './SkeletonLoader';







function Open_call() {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0])
  const [selectedTab, setSelectedTab] = useState('all');








      




  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/Recommandationsdetailsapi/${id}`);
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  function getStatusClass(status) {
    switch (status) {
      case 'buy':
        return 'bg-green-status';
      case 'short':
        return 'bg-red-status';
      case 'hold':
        return 'bg-yellow-status';
      case 'exit':
        return 'bg-black-status';
      case 'cancle':
        return 'bg-gray-status';
      default:
        return ''; // You can set a default class or leave it empty if none matches
    }
  }

  

  useEffect(() => {
    // Replace icons after component is mounted

    

    feather.replace();

    fetchData();
  }, []);

  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  if(data == null)
    {
      return (
       <>  </>
       )
    }
    
    const RecommendationCard = ({ recommendation }) => {
      const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  
      const getBackgroundClass = (status) => {
        switch (status) {
          case 'buy':
            return 'bg-green';
          case 'short':
            return 'bg-red';
          case 'hold':
            return 'bg-yellow';
          case 'exit':
            return 'bg-black';
          default:
            return '';
        }
      };
  
      const getStatusClass = (status) => {
        switch (status) {
          case 'buy':
            return 'bg-green-status';
          case 'short':
            return 'bg-red-status';
          case 'hold':
            return 'bg-yellow-status';
          case 'exit':
            return 'bg-black-status';
          case 'cancle':
            return 'bg-gray-status';
          default:
            return '';
        }
      };
  
      // Function to compute the default quantity
  const computeDefaultQuantity = (recommendation, data) => {
    const percentage = (data.Stock_rsk) * (data.user.percentage / 100);
    const percentage1 = (data.future_rsk) * (data.user.percentage_future / 100);
    const percentage2 = (data.option_rsk) * (data.user.percentage_option / 100);
  
    // Compute quantity based on recommendation stock type
    return recommendation.stock_type === 'options'
        ? Math.max(0, Math.floor(percentage2 / (recommendation.price_band_to - recommendation.stoploss)))
        : recommendation.stock_type === 'futures'
        ? Math.max(0, Math.floor(percentage1 / (recommendation.price_band_to - recommendation.stoploss)))
        : Math.max(0, Math.floor(percentage / (recommendation.price_band_to - recommendation.stoploss)));
  };
  
  // Function to find matching amount from stockPurchases
  
  const recommendationId = recommendation.id;
  const stockPurchases = data.stock_purchase;
  
  
  const findMatchingAmount = (recommendationId, stockPurchases) => {
    // Iterate through stockPurchases array
    for (const purchase of stockPurchases) {
        // Check if the purchase is not null or undefined
        if (purchase && purchase.package_id !== undefined) {
            // Check if package_id matches recommendationId
            if (Number(purchase.package_id) === recommendationId) {
                // Return the amount if a match is found
                return purchase.amount;
            }
        }
    }
    // Return null if no match is found
    return null;
  };
  
  
  // Use the functions to determine the quantity
  const determineQuantity = (recommendation, data, stockPurchases) => {
    // Check if there is a matching entry in stockPurchases
    const matchingAmount = findMatchingAmount(recommendationId, stockPurchases);
    // If a matching amount is found, use it
    if (matchingAmount === null) {
      return computeDefaultQuantity(recommendation, data);
    }
  
    // Otherwise, compute the default quantity
    return matchingAmount;
  
  };
  
  // Example usage in your JSX or elsewhere in your component
  const quantity = determineQuantity(recommendation, data, stockPurchases);
  
  // Now you can use `quantity` in your component as needed
  const industry = data.stock_list.find(stock_list => stock_list.symbol_up === recommendation.symbol);

  

  
  const total_return = (industry.u_last_price - recommendation.price_band_from ) /  recommendation.price_band_from * 100;
  
       const rounded_return = parseFloat(total_return.toFixed(2));
  
       const total_loss = (industry.u_last_price - recommendation.price_band_from) /  recommendation.price_band_from * 100;
  
       const rounded_loss = parseFloat(total_loss.toFixed(2));
  
  
    return (
      <div style={{overflowX:'hidden'}}>
        <Link to={`/recomm_details/${recommendation.id}?quantity=${quantity}`}>
      
        <div className="bill-box mb-3 pb-3"> {/* Added pb-3 for bottom padding */}
            <div className="d-flex gap-3">
              <div className="bill-icon" style={{width:'100px'}}>
                <img className="img-fluid icon" src={"https://images.5paisa.com/MarketIcons/" + recommendation.symbol+".png"} alt="p6" />
              </div>
              <div className="bill-details2">
                <h6 className="dark-text">{recommendation.script_name} |<span className="text-muted"> {industry.sector_name}</span></h6>
                <h6 className="light-text mt-2"><span className="">BZ: (₹{recommendation.price_band_from} - ₹{recommendation.price_band_to})</span> | {recommendation.duration} {recommendation.duration_type}</h6>
              </div>
              <div className="bill-details">
                <h6 className="dark-text" style={{textAlign:'end'}}> QTY {quantity}</h6>
                </div>
             
            </div>
            <div className="bill-price">
              <h5>T: ₹{recommendation.target} | <span style={{ backgroundColor: recommendation.trailing_stoploss && '#ffc107'}}>SL: ₹{recommendation.trailing_stoploss ? recommendation.trailing_stoploss : recommendation.stoploss} </span><span className={industry.u_last_price > recommendation.price_band_from ? 'success-color' : 'error-color'} >{recommendation.status === "hold" && (
  "(" + (industry.u_last_price > recommendation.price_band_from ? rounded_return : rounded_loss) + "%)" 
)}</span></h5>
           
              <a href="#" data-bs-toggle="modal" className={`btn bill-pay bill-paid ${getStatusClass(recommendation.status)}`} style={{width:'80px'}}>   {data.stock_purchase && data.stock_purchase.length > 0 ? (
        // Filter and calculate the quantity
        (() => {
            let quantity = 0;

            // Filter the stock purchase based on the package_id and recommendation.id
            const stockPurchaseMatch = data.stock_purchase.find(stock_purchase => {
                return stock_purchase && Number(stock_purchase.package_id) === recommendation.id;
            });

            if (stockPurchaseMatch) {
                // If there is a match, use the stock purchase amount
                quantity = "PURCHASED";
            } else {
              quantity = recommendation.status.toUpperCase();
              
            }
            
            // Return the calculated quantity
            return (
                <span >
                    {quantity}
                </span>
            );
        })()
    ) : null}</a>
            </div>
          </div>
          </Link>
      </div>
    );
    
     
    };

    const RecommendationCard1 = ({ recommendation }) => {
      const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  
      const getBackgroundClass = (status) => {
        switch (status) {
          case 'buy':
            return 'bg-green';
          case 'short':
            return 'bg-red';
          case 'hold':
            return 'bg-yellow';
          case 'exit':
            return 'bg-black';
          default:
            return '';
        }
      };
  
      const getStatusClass = (status) => {
        switch (status) {
          case 'buy':
            return 'bg-green-status';
          case 'short':
            return 'bg-red-status';
          case 'hold':
            return 'bg-yellow-status';
          case 'exit':
            return 'bg-black-status';
          case 'cancle':
            return 'bg-gray-status';
          default:
            return '';
        }
      };
  
      // Function to compute the default quantity
  const computeDefaultQuantity = (recommendation, data) => {
    const percentage = (data.Stock_rsk) * (data.user.percentage / 100);
    const percentage1 = (data.future_rsk) * (data.user.percentage_future / 100);
    const percentage2 = (data.option_rsk) * (data.user.percentage_option / 100);
  
    // Compute quantity based on recommendation stock type
    return recommendation.stock_type === 'options'
        ? Math.max(0, Math.floor(percentage2 / (recommendation.price_band_to - recommendation.stoploss)))
        : recommendation.stock_type === 'futures'
        ? Math.max(0, Math.floor(percentage1 / (recommendation.price_band_to - recommendation.stoploss)))
        : Math.max(0, Math.floor(percentage / (recommendation.price_band_to - recommendation.stoploss)));
  };
  
  // Function to find matching amount from stockPurchases
  
  const recommendationId = recommendation.id;
  const stockPurchases = data.stock_purchase;
  
  
  const findMatchingAmount = (recommendationId, stockPurchases) => {
    // Iterate through stockPurchases array
    for (const purchase of stockPurchases) {
        // Check if the purchase is not null or undefined
        if (purchase && purchase.package_id !== undefined) {
            // Check if package_id matches recommendationId
            if (Number(purchase.package_id) === recommendationId) {
                // Return the amount if a match is found
                return purchase.amount;
            }
        }
    }
    // Return null if no match is found
    return null;
  };
  
  
  // Use the functions to determine the quantity
  const determineQuantity = (recommendation, data, stockPurchases) => {
    // Check if there is a matching entry in stockPurchases
    const matchingAmount = findMatchingAmount(recommendationId, stockPurchases);
    // If a matching amount is found, use it
    if (matchingAmount === null) {
      return computeDefaultQuantity(recommendation, data);
    }
  
    // Otherwise, compute the default quantity
    return matchingAmount;
  
  };
  
  // Example usage in your JSX or elsewhere in your component
  const quantity = determineQuantity(recommendation, data, stockPurchases);
  
  // Now you can use `quantity` in your component as needed
  const industry = data.stock_list.find(stock_list => stock_list.symbol_up === recommendation.symbol);

  

  
  const total_return = (industry.u_last_price - recommendation.price_band_from ) /  recommendation.price_band_from * 100;
  
       const rounded_return = parseFloat(total_return.toFixed(2));
  
       const total_loss = (industry.u_last_price - recommendation.price_band_from) /  recommendation.price_band_from * 100;
  
       const rounded_loss = parseFloat(total_loss.toFixed(2));
  
  
    return (
      <>
      {industry.u_last_price < recommendation.price_band_from && (
      <div style={{overflowX:'hidden'}}>
        <Link to={`/recomm_details/${recommendation.id}?quantity=${quantity}`}>
      
        <div className="bill-box mb-3 pb-3"> {/* Added pb-3 for bottom padding */}
            <div className="d-flex gap-3">
              <div className="bill-icon" style={{width:'100px'}}>
                <img className="img-fluid icon" src={"https://images.5paisa.com/MarketIcons/" + recommendation.symbol+".png"} alt="p6" />
              </div>
              <div className="bill-details2">
                <h6 className="dark-text">{recommendation.script_name} |<span className="text-muted"> {industry.sector_name}</span></h6>
                <h6 className="light-text mt-2"><span className="">BZ: (₹{recommendation.price_band_from} - ₹{recommendation.price_band_to})</span> | {recommendation.duration} {recommendation.duration_type}</h6>
              </div>
              <div className="bill-details">
                <h6 className="dark-text" style={{textAlign:'end'}}> QTY {quantity}</h6>
                </div>
             
            </div>
            <div className="bill-price">
              <h5>T: ₹{recommendation.target} |<span style={{ backgroundColor: recommendation.trailing_stoploss && '#ffc107'}}> SL: ₹{recommendation.trailing_stoploss ? recommendation.trailing_stoploss : recommendation.stoploss} </span><span className={industry.u_last_price > recommendation.price_band_from ? 'success-color' : 'error-color'} >{recommendation.status === "hold" && (
  "(" + (industry.u_last_price > recommendation.price_band_from ? rounded_return : rounded_loss) + "%)" 
)}</span></h5>
           
              <a href="#" data-bs-toggle="modal" className={`btn bill-pay bill-paid ${getStatusClass(recommendation.status)}`} style={{width:'80px'}}>   {data.stock_purchase && data.stock_purchase.length > 0 ? (
        // Filter and calculate the quantity
        (() => {
            let quantity = 0;

            // Filter the stock purchase based on the package_id and recommendation.id
            const stockPurchaseMatch = data.stock_purchase.find(stock_purchase => {
                return stock_purchase && Number(stock_purchase.package_id) === recommendation.id;
            });

            if (stockPurchaseMatch) {
                // If there is a match, use the stock purchase amount
                quantity = "PURCHASED";
            } else {
              quantity = recommendation.status.toUpperCase();
              
            }
            
            // Return the calculated quantity
            return (
                <span >
                    {quantity}
                </span>
            );
        })()
    ) : null}</a>
            </div>
          </div>
          </Link>
      </div>
    )}</>
    );
    
     
    };

    const RecommendationCard2 = ({ recommendation }) => {
      const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  
      const getBackgroundClass = (status) => {
        switch (status) {
          case 'buy':
            return 'bg-green';
          case 'short':
            return 'bg-red';
          case 'hold':
            return 'bg-yellow';
          case 'exit':
            return 'bg-black';
          default:
            return '';
        }
      };
  
      const getStatusClass = (status) => {
        switch (status) {
          case 'buy':
            return 'bg-green-status';
          case 'short':
            return 'bg-red-status';
          case 'hold':
            return 'bg-yellow-status';
          case 'exit':
            return 'bg-black-status';
          case 'cancle':
            return 'bg-gray-status';
          default:
            return '';
        }
      };
  
      // Function to compute the default quantity
  const computeDefaultQuantity = (recommendation, data) => {
    const percentage = (data.Stock_rsk) * (data.user.percentage / 100);
    const percentage1 = (data.future_rsk) * (data.user.percentage_future / 100);
    const percentage2 = (data.option_rsk) * (data.user.percentage_option / 100);
  
    // Compute quantity based on recommendation stock type
    return recommendation.stock_type === 'options'
        ? Math.max(0, Math.floor(percentage2 / (recommendation.price_band_to - recommendation.stoploss)))
        : recommendation.stock_type === 'futures'
        ? Math.max(0, Math.floor(percentage1 / (recommendation.price_band_to - recommendation.stoploss)))
        : Math.max(0, Math.floor(percentage / (recommendation.price_band_to - recommendation.stoploss)));
  };
  
  // Function to find matching amount from stockPurchases
  
  const recommendationId = recommendation.id;
  const stockPurchases = data.stock_purchase;
  
  
  const findMatchingAmount = (recommendationId, stockPurchases) => {
    // Iterate through stockPurchases array
    for (const purchase of stockPurchases) {
        // Check if the purchase is not null or undefined
        if (purchase && purchase.package_id !== undefined) {
            // Check if package_id matches recommendationId
            if (Number(purchase.package_id) === recommendationId) {
                // Return the amount if a match is found
                return purchase.amount;
            }
        }
    }
    // Return null if no match is found
    return null;
  };
  
  
  // Use the functions to determine the quantity
  const determineQuantity = (recommendation, data, stockPurchases) => {
    // Check if there is a matching entry in stockPurchases
    const matchingAmount = findMatchingAmount(recommendationId, stockPurchases);
    // If a matching amount is found, use it
    if (matchingAmount === null) {
      return computeDefaultQuantity(recommendation, data);
    }
  
    // Otherwise, compute the default quantity
    return matchingAmount;
  
  };
  
  // Example usage in your JSX or elsewhere in your component
  const quantity = determineQuantity(recommendation, data, stockPurchases);
  
  // Now you can use `quantity` in your component as needed
  const industry = data.stock_list.find(stock_list => stock_list.symbol_up === recommendation.symbol);

  

  
  const total_return = (industry.u_last_price - recommendation.price_band_from ) /  recommendation.price_band_from * 100;
  
       const rounded_return = parseFloat(total_return.toFixed(2));
  
       const total_loss = (industry.u_last_price - recommendation.price_band_from) /  recommendation.price_band_from * 100;
  
       const rounded_loss = parseFloat(total_loss.toFixed(2));
  
  
    return (
      <>
      {industry.u_last_price > recommendation.price_band_from && (
      <div style={{overflowX:'hidden'}}>
        <Link to={`/recomm_details/${recommendation.id}?quantity=${quantity}`}>
      
        <div className="bill-box mb-3 pb-3"> {/* Added pb-3 for bottom padding */}
            <div className="d-flex gap-3">
              <div className="bill-icon" style={{width:'100px'}}>
                <img className="img-fluid icon" src={"https://images.5paisa.com/MarketIcons/" + recommendation.symbol+".png"} alt="p6" />
              </div>
              <div className="bill-details2">
                <h6 className="dark-text">{recommendation.script_name} |<span className="text-muted"> {industry.sector_name}</span></h6>
                <h6 className="light-text mt-2"><span className="">BZ: (₹{recommendation.price_band_from} - ₹{recommendation.price_band_to})</span> | {recommendation.duration} {recommendation.duration_type}</h6>
              </div>
              <div className="bill-details">
                <h6 className="dark-text" style={{textAlign:'end'}}> QTY {quantity}</h6>
                </div>
             
            </div>
            <div className="bill-price">
              <h5>T: ₹{recommendation.target} | <span style={{ backgroundColor: recommendation.trailing_stoploss && '#ffc107'}}>SL: ₹{recommendation.trailing_stoploss ? recommendation.trailing_stoploss : recommendation.stoploss} </span> <span className={industry.u_last_price > recommendation.price_band_from ? 'success-color' : 'error-color'} >{recommendation.status === "hold" && (
  "(" + (industry.u_last_price > recommendation.price_band_from ? rounded_return : rounded_loss) + "%)" 
)}</span></h5>
           
              <a href="#" data-bs-toggle="modal" className={`btn bill-pay bill-paid ${getStatusClass(recommendation.status)}`} style={{width:'80px'}}>   {data.stock_purchase && data.stock_purchase.length > 0 ? (
        // Filter and calculate the quantity
        (() => {
            let quantity = 0;

            // Filter the stock purchase based on the package_id and recommendation.id
            const stockPurchaseMatch = data.stock_purchase.find(stock_purchase => {
                return stock_purchase && Number(stock_purchase.package_id) === recommendation.id;
            });

            if (stockPurchaseMatch) {
                // If there is a match, use the stock purchase amount
                quantity = "PURCHASED";
            } else {
              quantity = recommendation.status.toUpperCase();
              
            }
            
            // Return the calculated quantity
            return (
                <span >
                    {quantity}
                </span>
            );
        })()
    ) : null}</a>
            </div>
          </div>
          </Link>
      </div>
    )}</>
    );
    
     
    };

    const handleGoBack = () => {
      window.history.back();
    };
  
  
  return (

    
    <div >
       

  
       
       <div className='row pt-2'>
        <div className='col' style={{paddingRight:'0px',marginRight:'-25px'}}>
       <Link   to="#" onClick={handleGoBack} style={{padding:'5px 10px'}}>
       <svg xmlns="http://www.w3.org/2000/svg" width="35" height="25" viewBox="0 0 24 24">
	<path fill="black" d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1" />
</svg>
          </Link>
          </div>
          <div className='col' style={{paddingLeft:'0px'}}>
            <img src={logo} alt="logo" style={{width:'30px',height:'30px'}}/>
          </div>
          </div>
     
         
         <section>
  <div className="custom-container" >
  {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
         
          
          <div>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <button style={{ 
                padding: '8px 16px',
                backgroundColor: '#fff',
                color: 'black',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                boxShadow: '2px 4px 6px 2px rgba(0, 0, 0, 0.2)',
                transition: 'background-color 0.3s',
              }}>X</button>
            </Link>
          </div>
        </div><br /> */}
        
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom:'10px' }}>
            <h6 className={`stock_tabs2 ${selectedTab === 'all' ? 'active2' : ''}`} onClick={() => setSelectedTab('all')} style={{fontSize:'20px',padding:'10px 30px'}}>All</h6>
            <h6 className={`stock_tabs2 ${selectedTab === 'minus' ? 'active2' : ''}`} onClick={() => setSelectedTab('minus')} style={{fontSize:'20px',padding:'10px 30px'}}>Negative</h6>
            <h6 className={`stock_tabs2 ${selectedTab === 'plus' ? 'active2' : ''}`} onClick={() => setSelectedTab('plus')} style={{fontSize:'20px',padding:'10px 30px'}}>Positive</h6>

        </div>
    <div className="m_wrap">
      <div className="g-3 mb-3" >
      {selectedTab === 'all' &&  (
          <>
           <Swiper
           slidesPerView={1}
           spaceBetween={10}
           pagination={{
             clickable: false,
             bulletActiveClass: 'swiper-pagination-bullet-active', // Bullet active class
             bulletClass: 'swiper-pagination-bullet', // Bullet class
             renderBullet: (index, className) => {
               // Return the custom bullet with inline styles
               return `<span class="${className}" style="background-color: #622cfd;"></span>`;
             },
           }}
           modules={[Pagination]}
           className="mySwiper"
         >
                 {/* Group data.this_week_Recommendation into chunks of 6 items each */}
                 {data.open_calls.reduce((chunks, recommendation, index) => {
                     const chunkIndex = Math.floor(index / 10);
                     
                     // Create an array for each chunk if it doesn't exist yet
                     if (!chunks[chunkIndex]) {
                         chunks[chunkIndex] = [];
                     }
                     
                     // Add the current recommendation to the current chunk
                     chunks[chunkIndex].push(recommendation);
                     
                     return chunks;
                 }, []).map((recommendation, chunkIndex) => (
                     <SwiperSlide key={chunkIndex}>
                         <div className="row">
                             {/* Map through each recommendation in the current chunk */}
                             {recommendation.map((recommendation, index) => (
                                 <div className="col-12" key={index}>
                                     {/* Render each recommendation using the RecommendationCard1 component */}
                                     <RecommendationCard key={index} recommendation={recommendation} />
                                     </div>
                             ))}
                         </div>
                     </SwiperSlide>
                 ))}
             </Swiper>
             </>
        )}

      {selectedTab === 'minus' && data.open_calls.map((recommendation, index) => (
          <RecommendationCard1 key={index} recommendation={recommendation} />
        ))}
        
        {selectedTab === 'plus' && data.open_calls.map((recommendation, index) => (
          <RecommendationCard2 key={index} recommendation={recommendation} />
        ))}
       
      </div>
    </div>
  </div>
</section>


        {/* bill details section starts */}
        


        {/* quick send section starts */}

       
       
        
      </div>
  );
}

export default Open_call;
