import React, { useEffect, useState } from "react";
import feather from 'feather-icons';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";

import "./App.css";

import logo1 from "./assets/theme_asset/logo/logo.png";
import logo2 from "./assets/theme_asset/logo/logo3.png";
import auth_bg from "./assets/theme_asset/background/auth-bg.jpg";
import { googleLogout } from '@react-oauth/google';

function Sidebar() {

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [data, setData] = useState("");
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const id = localStorage.getItem("id");
      const response = await axios.get(`${apiBaseUrl}/sidebarapi/${id}`);
      setData(response.data);
      

    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    // Check if token exists
    const token = localStorage.getItem("token");
    if (!token) {
      // If no token exists, navigate to login
      navigate("/login");
    }

    fetchData();
  }, []);




  const handleLogout = () => {
    // Remove token from localStorage
    localStorage.removeItem('token');
    googleLogout();
    // Navigate to the login page by changing the URL
    window.location.href = '/login';
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleCheck = () => {
    if (data && data.package_active && data.package_active.status === 'success') {
      navigate(`/recommandation`);
    } else {
      alert("To access this module, please subscribe to a plan.");
      navigate('/pricing');
    }
  };

  return (
    <div>
      <div
        className={`offcanvas sidebar-offcanvas offcanvas-start${
          isSidebarOpen ? " show" : ""
        }`}
        tabIndex={-1}
        id="offcanvasLeft"
      >
        <div className="offcanvas-header sidebar-header">
          <a onClick={closeSidebar}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="none" stroke="black" stroke-linecap="round" stroke-width="2" d="M20 20L4 4m16 0L4 20" />
</svg>
          </a>

          <div className="sidebar-logo" style={{marginTop:'-30px'}}>
            <img className="img-fluid logo" src={logo2} alt="logo" />
          </div>

          <div className="balance">
            <img className="img-fluid balance-bg" src={auth_bg} alt="auth-bg" />
            <h2 className="text-white">{data && data.user && data.user.name}</h2>
            <h5>Welcome to Finvalley</h5>
          </div>
        </div>
        <div className="offcanvas-body">
          <div className="sidebar-content">
            <ul className="link-section">
              <li>
                <Link to="/" className="pages">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<g fill="none" stroke="#7031fe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
		<path d="m3 9l9-7l9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
		<path d="M9 22V12h6v10" />
	</g>
</svg>
                  <h3>Home</h3>
                </Link>
              </li><hr/>
              <li>
                <Link to="/upstock_form" className="pages">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 384 512"><path fill="#7031fe" d="M32 32c17.7 0 32 14.3 32 32V288c0 70.7 57.3 128 128 128s128-57.3 128-128V64c0-17.7 14.3-32 32-32s32 14.3 32 32V288c0 106-86 192-192 192S0 394 0 288V64C0 46.3 14.3 32 32 32z"/></svg>
                 <h3>Connect To Upstox</h3>
                </Link>
              </li><hr/>
              <li>
                <a onClick={handleCheck} className="pages">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<g fill="none" stroke="#7031fe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
		<path d="m3 17l6-6l4 4l8-8" />
		<path d="M14 7h7v7" />
	</g>
</svg>  <h3>Trades</h3>
                </a>
              </li><hr/>

              <li>
                <Link to="/risk_management" className="pages">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
	<path fill="none" d="M336 80H176a16 16 0 0 0-16 16v16h192V96a16 16 0 0 0-16-16" />
	<path fill="#7031fe" d="M496 176a64.07 64.07 0 0 0-64-64h-48V96a48.05 48.05 0 0 0-48-48H176a48.05 48.05 0 0 0-48 48v16H80a64.07 64.07 0 0 0-64 64v48h480Zm-144-64H160V96a16 16 0 0 1 16-16h160a16 16 0 0 1 16 16Zm-16 152a24 24 0 0 1-24 24H200a24 24 0 0 1-24-24v-4a4 4 0 0 0-4-4H16v144a64 64 0 0 0 64 64h352a64 64 0 0 0 64-64V256H340a4 4 0 0 0-4 4Z" />
</svg>
                  <h3>Risk Management</h3>
                </Link>
              </li><hr/>

              <li>
                <Link to="/portfolio" className="pages">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="#7031fe" d="M14.5 10.33h6.67A.83.83 0 0 0 22 9.5A7.5 7.5 0 0 0 14.5 2a.83.83 0 0 0-.83.83V9.5a.83.83 0 0 0 .83.83" />
	<path fill="#7031fe" d="M21.08 12h-8.15a.91.91 0 0 1-.91-.91V2.92A.92.92 0 0 0 11 2a10 10 0 1 0 11 11a.92.92 0 0 0-.92-1" />
</svg>
                  <h3>Portfolio</h3>
                </Link>
              </li><hr/>
              <li>
                <Link to="/near" className="pages">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
	<path fill="#7031fe" d="M8 2a4.5 4.5 0 0 0-4.5 4.5v2.401l-.964 2.414A.5.5 0 0 0 3 12h10a.5.5 0 0 0 .464-.685L12.5 8.9V6.5A4.5 4.5 0 0 0 8 2m0 12.5A2 2 0 0 1 6.063 13h3.874A2 2 0 0 1 8 14.5" />
</svg>
                  <h3>Stock Under Surveillance</h3>
                </Link>
              </li><hr/>
              <li>
                <Link to="/profile" className="pages">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="#7031fe" d="M12 4a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 10c4.42 0 8 1.79 8 4v2H4v-2c0-2.21 3.58-4 8-4" />
</svg>
                  <h3>Profile</h3>
                </Link>
              </li><hr/>
              <li>
                <Link to="/pricing" className="pages">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="none" stroke="#7031fe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 2v20m5-17H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
</svg>
                  <h3>Pricing</h3>
                </Link>
              </li><hr/>
              {/* <li>
                <Link to="/about" className="pages">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="#7031fe" d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22" />
</svg>
                  <h3>About us</h3>
                </Link>
              </li><hr/>
              <li>
                <Link to="/privacy" className="pages">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="#7031fe" d="M12 22q-3.475-.875-5.738-3.988T4 11.1V5l8-3l8 3v6.1q0 3.8-2.262 6.913T12 22" />
</svg>
                  <h3>Privacy and Policy</h3>
                </Link>
              </li><hr/>
              <li>
                <Link to="/terms" className="pages">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="#7031fe" d="M13 9h5.5L13 3.5zM6 2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.11.89-2 2-2m9 16v-2H6v2zm3-4v-2H6v2z" />
</svg>
                  <h3>Terms and Condition</h3>
                </Link>
              </li><hr/> */}
              {/* <li>
                <Link to="/return" className="pages">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="#7031fe" d="M22 10v6a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4v-6zM7.01 14H7a1 1 0 1 0 .01 2a1 1 0 0 0 0-2M13 14h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2m5-10a4 4 0 0 1 4 4H2a4 4 0 0 1 4-4z" />
</svg>
                  <h3>Refund Policy</h3>
                </Link>
              </li><hr/> */}
             
              {/* <li>
                <Link to="/check_out" className="pages">
                  <i className="sidebar-icon" data-feather="dollar-sign" />
                  <h3>Check Out</h3>
                </Link>
              </li> */}

              <li>
                <Link to="#" onClick={handleLogout} className="pages">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 1024 1024">
	<path fill="#7031fe" d="M868 732h-70.3c-4.8 0-9.3 2.1-12.3 5.8c-7 8.5-14.5 16.7-22.4 24.5a353.8 353.8 0 0 1-112.7 75.9A352.8 352.8 0 0 1 512.4 866c-47.9 0-94.3-9.4-137.9-27.8a353.8 353.8 0 0 1-112.7-75.9a353.3 353.3 0 0 1-76-112.5C167.3 606.2 158 559.9 158 512s9.4-94.2 27.8-137.8c17.8-42.1 43.4-80 76-112.5s70.5-58.1 112.7-75.9c43.6-18.4 90-27.8 137.9-27.8s94.3 9.3 137.9 27.8c42.2 17.8 80.1 43.4 112.7 75.9c7.9 7.9 15.3 16.1 22.4 24.5c3 3.7 7.6 5.8 12.3 5.8H868c6.3 0 10.2-7 6.7-12.3C798 160.5 663.8 81.6 511.3 82C271.7 82.6 79.6 277.1 82 516.4C84.4 751.9 276.2 942 512.4 942c152.1 0 285.7-78.8 362.3-197.7c3.4-5.3-.4-12.3-6.7-12.3m88.9-226.3L815 393.7c-5.3-4.2-13-.4-13 6.3v76H488c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h314v76c0 6.7 7.8 10.5 13 6.3l141.9-112a8 8 0 0 0 0-12.6" />
</svg>
                  <h3>Log out</h3>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <button className="sidebar-btn" onClick={toggleSidebar}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<g fill="none">
		<path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
		<path fill="#7031fe" d="M20 17.5a1.5 1.5 0 0 1 .144 2.993L20 20.5H4a1.5 1.5 0 0 1-.144-2.993L4 17.5zm0-7a1.5 1.5 0 0 1 0 3H4a1.5 1.5 0 0 1 0-3zm0-7a1.5 1.5 0 0 1 0 3H4a1.5 1.5 0 1 1 0-3z" />
	</g>
</svg>
            </button>
            <img className="img-fluid logo" src={logo1} alt="logo" />
            <Link to="/notification" className="notification">
             <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="#7031fe" d="M21 19v1H3v-1l2-2v-6c0-3.1 2.03-5.83 5-6.71V4a2 2 0 0 1 2-2a2 2 0 0 1 2 2v.29c2.97.88 5 3.61 5 6.71v6zm-7 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2" />
</svg>
            </Link>{" "}
          </div>
        </div>
      </header>
    </div>
  );
}

export default Sidebar;
