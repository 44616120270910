import logo from "./logo.svg";

import feather from "feather-icons";
import Footer from "./footer";
import Sidebar from "./Sidebar";

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";

// Assuming you're using React Router and have a route set up for blog details

import favicon from "./assets/theme_asset/logo/favicon.png";

import logo1 from "./assets/theme_asset/logo/logo.png";

import logo2 from "./assets/theme_asset/svg/logo1.svg";
import facebook from "./assets/theme_asset/svg/facebook.svg";
import google from "./assets/theme_asset/svg/google.svg";

import auth_bg from "./assets/theme_asset/background/auth-bg.jpg";

import arrow from "./assets/theme_asset/svg/arrow.svg";

import arrow_down_right from "./assets/theme_asset/svg/arrow-down-right.svg";
import arrow_up_right from "./assets/theme_asset/svg/arrow-up-right.svg";

import p1 from "./assets/theme_asset/person/p1.png";
import p2 from "./assets/theme_asset/person/p2.png";
import p3 from "./assets/theme_asset/person/p3.png";
import p4 from "./assets/theme_asset/person/p4.png";
import p5 from "./assets/theme_asset/person/p5.png";
import ellipse from "./assets/theme_asset/svg/ellipse.svg";

import a1 from "./assets/theme_asset/svg/1.svg";
import a2 from "./assets/theme_asset/svg/2.svg";
import a3 from "./assets/theme_asset/svg/3.svg";
import a4 from "./assets/theme_asset/svg/4.svg";
import a5 from "./assets/theme_asset/svg/5.svg";

import a6 from "./assets/theme_asset/svg/6.svg";
import a7 from "./assets/theme_asset/svg/7.svg";
import a8 from "./assets/theme_asset/svg/8.svg";
import a9 from "./assets/theme_asset/svg/9.svg";
import a10 from "./assets/theme_asset/svg/10.svg";
import a11 from "./assets/theme_asset/svg/11.svg";

import card_chip from "./assets/theme_asset/svg/card-chip.svg";

import img1 from "./assets/theme_asset/1.jpg";

import contact from "./assets/theme_asset/svg/contact.svg";

import auth6 from "./assets/theme_asset/authentication/6.svg";
import auth1 from "./assets/theme_asset/authentication/1.svg";

import img2 from "./assets/theme_asset/2.jpg";
import img3 from "./assets/theme_asset/3.jpg";
import img4 from "./assets/theme_asset/4.jpg";
import l49 from "./assets/theme_asset/logo/48.png";

import search from "./assets/search (1).png";

import "./App.css";

import scan from "./assets/theme_asset/svg/scan.svg";
import { useEffect } from "react";
import { Bar } from "react-chartjs-2";

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import Modal from "./modal";

import React from "react";
import ProceedPayment from "./proceed_payment";

import visa from "./assets/paymentimg/visa.svg";
import upi from "./assets/paymentimg/upi.svg";
import paytm from "./assets/paymentimg/paytm.svg";
import rupay from "./assets/paymentimg/payment.svg";
import mastercard from "./assets/paymentimg/mastercard.svg";
import googlepay from "./assets/paymentimg/google-pay.svg";
import googleplay from "./assets/paymentimg/google-play.png";

const CheckOut = () => {
  const navigate = useNavigate();

  const [selectedValue, setSelectedValue] = useState("option1");

  const handleRadioChange = (value) => {
    setSelectedValue(value);
  };

  return (
    <div style={{overflowX:'hidden'}}>
      <div className="auth-header">
        <div>
          <Link to="/" style={{ textDecoration: "none" }}>
            <button
              style={{
                padding: "8px 16px",
                color: "white",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
            >
              X
            </button>
          </Link>
        </div>

        <div className="auth-content" style={{ justifyContent: "center" }}>
          <div>
            <center>
              <h1
                className="text-white"
                style={{
                  fontWeight: "bold",
                  fontFamily: "-moz-initial",
                  padding: "5px",
                  position: "relative",
                }}
              >
                {" "}
                Checkout
              </h1>

              <h3
                className="text-white"
                style={{
                  fontFamily: "serif",
                }}
              >
                View the Payment Details
              </h3>
            </center>
          </div>
        </div>
      </div>

      <form className="auth-form">
        <div className="custom-container">
          <div>
            <center>
              <h1
                className="text-black"
                style={{
                  fontFamily: "inherit",
                  fontWeight: "bolder",
                  padding: "10px",
                }}
              >
                {" "}
                Payment Methods
              </h1>

              <h3
                className="text-black"
                style={{
                  width: "240px",
                  fontStyle: "initial",
                }}
              >
                Choose the payment options below you want to proceed with
              </h3>
            </center>
          </div>
        </div>

        <div>
          <section>
            <div className="custom-container" style={{ marginBottom: "20px" }}>
              <div className="m_wrap">
                <div className="g-3 mb-3">
                  <div className="bill-box mb-3 pb-3">
                    {" "}
                    {/* Added pb-3 for bottom padding */}
                    <div className="d-flex gap-3">
                      <div
                        className="center vertical align"
                        style={{
                          padding: "5px",
                          marginTop: "35px",
                        }}
                      >
                        <input
                          type="radio"
                          id="option1"
                          value="option1"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          checked={selectedValue === "option1"}
                          onChange={() => handleRadioChange("option1")}
                        />
                      </div>
                      <div className="bill-details">
                        <div>
                          <img
                            className="img-fluid logo "
                            src={mastercard}
                            style={{
                              width: "35px",
                              height: "40px",
                            }}
                            alt="logo"
                          />
                          <img
                            className="img-fluid logo"
                            src={visa}
                            style={{
                              width: "35px",
                              height: "40px",
                              marginRight: "8px",
                            }}
                            alt="logo"
                          />
                          <img
                            className="img-fluid logo"
                            src={rupay}
                            style={{
                              width: "35px",
                              height: "40px",
                              marginRight: "8px",
                            }}
                            alt="logo"
                          />

                          <img
                            className="img-fluid logo"
                            src={paytm}
                            style={{
                              width: "35px",
                              height: "40px",
                              marginRight: "8px",
                            }}
                            alt="logo"
                          />
                          <img
                            className="img-fluid logo"
                            src={upi}
                            style={{
                              width: "35px",
                              height: "40px",
                              marginRight: "8px",
                            }}
                            alt="logo"
                          />
                          <img
                            className="img-fluid logo"
                            src={googlepay}
                            style={{
                              width: "35px",
                              height: "40px",
                              marginRight: "8px",
                            }}
                            alt="logo"
                          />
                        </div>

                        <div className="bill-details">
                          <h3 className="dark-text">
                            UPI/Wallet/Credit/Debit Card
                          </h3>
                          <h6
                            className="light-text mt-2"
                            style={{
                              fontWeight: "initial",
                              fontFamily: "monospace",
                            }}
                          >
                            Pay with your Credit/Debit Card, or UPI
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div>
          <section>
            <div className="custom-container">
              <div className="m_wrap">
                <div className="g-3 mb-3">
                  <div className="bill-box mb-3 pb-3">
                    {" "}
                    {/* Added pb-3 for bottom padding */}
                    <div className="d-flex gap-3">
                      <div className="">
                        <input
                          type="radio"
                          id="option1"
                          value="option1"
                          style={{
                            padding: "5px",
                            marginTop: "35px",
                          }}
                          checked={selectedValue === "option1"}
                          onChange={() => handleRadioChange("option1")}
                        />
                      </div>

                      <div className="bill-details">
                        <div>
                          <img
                            className="img-fluid logo "
                            src={googleplay}
                            style={{
                              width: "30px",
                              height: "30px",
                            }}
                            alt="logo"
                          />
                        </div>
                        <div className="bill-details">
                          <h3 className="dark-text">In-App Purchase</h3>
                          <h6
                            className="light-text mt-2"
                            style={{
                              fontWeight: "initial",
                              fontFamily: "monospace",
                            }}
                          >
                            Pay using Google in-app purchase
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* <button type="submit" className="btn theme-btn w-100"> */}
        <Link
          to="/proceed_payment"
          style={{ color: "white", textDecoration: "none" }}
          className="btn theme-btn w-100"
        >
          Proceed to Payment
        </Link>
        {/* </button> */}
      </form>
    </div>
  );
};

export default CheckOut;
