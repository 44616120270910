import logo from './assets/theme_asset/favicon.png';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState , useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Link } from 'react-router-dom';



// Assuming you're using React Router and have a route set up for blog details






import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import './App.css';
import './recommandation.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import { Line } from "react-chartjs-2";
import SkeletonLoader from './SkeletonLoader';
import Tradingview from './tradingview';







function Recomm_details() {
  const { id } = useParams();
  const [RecommandationPost, setRecommandationPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
 const[data, setData] = useState(null);
 const[data1, setData1] = useState(null);
 const[check, setCheck] = useState(false);
  const location = useLocation();

  const iframeRef = useRef(null);

  // Function to remove classes from the iframe's content
  const removeClassesFromIframe = () => {
      const iframe = iframeRef.current;
      if (iframe) {
          try {
              // Access the iframe's document
              const iframeDocument = iframe.contentDocument || iframe.contentWindow;


              if (iframeDocument) {
                  // Remove the class 'stock-yield-subtitle' from elements
                  const elementsWithClass1 = iframeDocument.querySelector('.stock-yield-subtitle');
                  elementsWithClass1.forEach(element => {
                      element.classList.remove('stock-yield-subtitle');
                  });

                  // Remove the class 'embed-logo' from elements
                  const elementsWithClass2 = iframeDocument.querySelector('.embed-logo');
                  elementsWithClass2.forEach(element => {
                      element.classList.remove('embed-logo');
                  });
              }
          } catch (error) {
              console.error('Error accessing iframe content:', error);
          }
      }
  };



  // Delay the useEffect hook by 3 seconds
  useEffect(() => {
      // Define the delay time (3 seconds)
      const delay = 3000;

      // Set a timeout to execute the useEffect function after the delay
      const timeoutId = setTimeout(() => {
          const iframe = iframeRef.current;
          // Only proceed if the iframe is available
          if (iframe) {
              // Set an onload event handler to remove the classes once the iframe is loaded
              iframe.onload = removeClassesFromIframe;

              // If the iframe is already loaded, call the function immediately
              if (iframe.readyState === 'complete' || iframe.readyState === 'loaded') {
                  removeClassesFromIframe();
              }
          }
      }, delay);

      // Cleanup function to clear the timeout when the component unmounts
      return () => {
          clearTimeout(timeoutId);
      };
  }, []);


  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const fetchData = async () => {
    
    try {
      const id1 = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/recommandationapi/${id}`);
      const response1 = await axios.get(`${apiBaseUrl}/user_getapi/${id1}`);
      
      setRecommandationPost(response.data);
      setData(response.data);
      setData1(response1.data);

    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const calculateDuration = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the difference in time
    const timeDiff = Math.abs(end - start);

    // Convert time difference from milliseconds to days
    const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    return diffDays;
};

  const date_of_exicutation = RecommandationPost && RecommandationPost.recommandation && RecommandationPost.recommandation.date_of_exicutation;
  const date_of_exit = RecommandationPost && RecommandationPost.recommandation && RecommandationPost.recommandation.date_of_exit;

  const holdingDuration = date_of_exit ? calculateDuration(date_of_exicutation, date_of_exit) : null;

 

  const searchParams = new URLSearchParams(location.search);
  const quantity1 = searchParams.get('quantity');

  

  const handleformSubmit = async (e,id1,quantity,id) => {
    // Retrieve checked status from the event target (checkbox)
    e.preventDefault();

    const id2 = id1;
    const quantity1 = quantity;
    const id3 = id;

  
    const response = await axios.post(`${apiBaseUrl}/add_to_upstox`,{
      id2,
      quantity1,
      id3,
    } ,{
      headers: {
          'Content-Type': 'application/json', // Set content type to application/json for JSON data
      },
  });

  window.confirm("Stock Add to portfolio successfully");
};

// Effect to observe changes to `check`
useEffect(() => {
    // Any additional logic you want to handle when `check` changes can be placed here
}, [check]);

 



  const handleSubmit = async (e,id1,quantity,id, check1, buy_value1) => {
    e.preventDefault();
  
   
    
    // If the user confirms, proceed with submitting the data
   
        try {
         
            const reason = "stock_purchase";
            const id2 = id1;
            const quantity1 = quantity;
            const id3 = id;
            const buy_value = buy_value1;
            const status = 'buy';
           const check = check1;
           // Save the modal message in localStorage
           

            // Make an API request to submit the data
            const response = await axios.post(`${apiBaseUrl}/add_stock_portfolio`, {
                reason,
                id2,
                quantity1,
                id3,
                check,
                buy_value,
                status
            }, {
                headers: {
                    'Content-Type': 'application/json', // Set content type to application/json for JSON data
                },
            });
            window.confirm("Stock Add to portfolio successfully");
            fetchData();
        } catch (error) {
            console.log("Error while sending the Stock Management Data:", error);
        }
    
  };

  
  const handleGoBack = () => {
    window.history.back();
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  function getStatusClass(status) {
    switch (status) {
      case 'buy':
        return 'bg-green-status';
      case 'short':
        return 'bg-red-status';
      case 'hold':
        return 'bg-yellow-status';
      case 'exit':
        return 'bg-black-status';
      default:
        return ''; // You can set a default class or leave it empty if none matches
    }
  }

  const isPurchased = data?.stock_purchase?.some(purchase => {
    // Check if the purchase is not null or undefined and if package_id is defined
    return purchase && purchase.package_id !== undefined && purchase.package_id === id;
});

let risk;

if (RecommandationPost.recommandation.trailing_stoploss ? RecommandationPost.recommandation.trailing_stoploss : RecommandationPost.recommandation.stoploss >= RecommandationPost.recommandation.price_band_from) {
   risk = (RecommandationPost.recommandation.trailing_stoploss ? RecommandationPost.recommandation.trailing_stoploss : RecommandationPost.recommandation.stoploss) - RecommandationPost.recommandation.price_band_from ;
} else {
   risk = RecommandationPost.recommandation.price_band_from - (RecommandationPost.recommandation.trailing_stoploss ? RecommandationPost.recommandation.trailing_stoploss : RecommandationPost.recommandation.stoploss);
}

const reward =  RecommandationPost.recommandation.target - RecommandationPost.recommandation.price_band_from;

const final_reward = reward / risk;

const computeDefaultQuantity = (RecommandationPost, data1) => {
  const percentage = (data1 && data1.Stock_rsk) * (data1 && data1.user && data1.user.percentage / 100);
  const percentage1 = (data1 && data1.future_rsk) * (data1 && data1.user && data1.user.percentage_future / 100);
  const percentage2 = (data1 && data1.option_rsk) * (data1 && data1.user && data1.user.percentage_option / 100);


  // Compute quantity based on recommendation stock type
  return RecommandationPost.recommandation.stock_type === 'options'
      ? Math.max(0, Math.floor(percentage2 / (RecommandationPost.recommandation.price_band_to - RecommandationPost.recommandation.stoploss)))
      : RecommandationPost.recommandation.stock_type === 'futures'
      ? Math.max(0, Math.floor(percentage1 / (RecommandationPost.recommandation.price_band_to - RecommandationPost.recommandation.stoploss)))
      : Math.max(0, Math.floor(percentage / (RecommandationPost.recommandation.price_band_to - RecommandationPost.recommandation.stoploss)));
};



// Function to find matching amount from stockPurchases

const recommendationId = RecommandationPost.recommandation.id;
const stockPurchases = data && data1.stock_purchase;



const findMatchingAmount = (recommendationId, stockPurchases) => {
  // Iterate through stockPurchases array
   
  for (const purchase of stockPurchases) {
      // Check if the purchase is not null or undefined
      if (purchase && purchase.package_id !== undefined) {
          // Check if package_id matches recommendationId
          if (Number(purchase.package_id) === recommendationId) {
              // Return the amount if a match is found
              return purchase.amount;
          }
      }
  }
  // Return null if no match is found
  return null;
};


// Use the functions to determine the quantity
const determineQuantity = (RecommandationPost, data1, stockPurchases) => {
  // Check if there is a matching entry in stockPurchases
  const matchingAmount = findMatchingAmount(recommendationId, stockPurchases);
  // If a matching amount is found, use it
  if (matchingAmount === null) {
    return computeDefaultQuantity(RecommandationPost, data1);
  }

  // Otherwise, compute the default quantity
  return matchingAmount;

};

// Example usage in your JSX or elsewhere in your component
const quantity = determineQuantity(RecommandationPost, data1, stockPurchases);

const handleButtonClick = (e) => {
  // Retrieve id1 from local storage
  const id1 = localStorage.getItem('id');
  // Retrieve quantity from URL query parameters
  const quantity1 = quantity;
  const buy_value = quantity1 * RecommandationPost.stock_list.u_last_price;

  const check1 = check;
  const confirmSubmit = window.confirm("Are you sure you want to Add stock in Portfolio?");


  if(confirmSubmit){
  handleSubmit(e, id1, quantity1, id, check1, buy_value);
}
};

const handleButtonClickupstox = (e) => {
  // Retrieve id1 from local storage
  const id1 = localStorage.getItem('id');
  // Retrieve quantity from URL query parameters
  const quantity1 = quantity;

  const check1 = check;
  const confirmSubmit = window.confirm("Are you sure you want to Add stock in Upstox?");


  if(confirmSubmit){
    handleformSubmit(e, id1, quantity1, id);
}
};

const rounded_final_reward = parseFloat(final_reward.toFixed(2));
function capitalizeFirstLetter(str) {
  if (!str) {
      return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const recommendationType = RecommandationPost.recommandation.recommandation_type;

// Use the capitalizeFirstLetter function to capitalize the first letter
const capitalizedRecommendationType = capitalizeFirstLetter(recommendationType);


const capital_required = quantity * RecommandationPost.recommandation.price_band_to;
const capital_required1 = quantity * RecommandationPost.recommandation.price_band_from;
    // Function to remove elements with specified classes
const formatAPIResponseDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
  };

  const total_return = (RecommandationPost.recommandation.target - RecommandationPost.recommandation.price_band_to) /  RecommandationPost.recommandation.price_band_to * 100;

  const rounded_return = parseFloat(total_return.toFixed(2));


  const total_loss = (RecommandationPost.recommandation.stoploss - RecommandationPost.recommandation.price_band_to) /  RecommandationPost.recommandation.price_band_to * 100;

  const rounded_loss = Math.abs(parseFloat(total_loss.toFixed(2)));

  const trailing_total_loss = (RecommandationPost.recommandation.trailing_stoploss - RecommandationPost.recommandation.price_band_to) /  RecommandationPost.recommandation.price_band_to * 100;

  const trailing_rounded_loss = Math.abs(parseFloat(trailing_total_loss.toFixed(2)));

  const { stoploss, price_band_from, price_band_to, target, result } = RecommandationPost.recommandation;
  const currentValue = RecommandationPost.stock_list.u_last_price;
  
 
  // Assuming trailing_stoploss is a number and defined somewhere in your code
const stoplossValue =  stoploss;
const trailing_stoplossvalue =  RecommandationPost.recommandation.trailing_stoploss;

const calculatePercentage = (start, end) => {
  return ((end - start) / (target - stoplossValue)) * 100;
};

const price_from = RecommandationPost.recommandation.trailing_stoploss ?? price_band_from;

const percentageFromStopLossToPriceBandFrom = calculatePercentage(stoplossValue, price_from);

const percentageFromTrailingStopLossToPriceBandFrom = calculatePercentage(trailing_stoplossvalue, price_band_from);
const percentageFromPriceBandFromToPriceBandTo = calculatePercentage(price_band_from, price_band_to);
const percentageFromPriceBandToToTarget = calculatePercentage(price_band_to, target);

console.log(percentageFromTrailingStopLossToPriceBandFrom, percentageFromStopLossToPriceBandFrom)
const calculateCurrentPositionPercentage = (currentValue) => {
  return ((currentValue - stoplossValue) / (target - stoplossValue)) * 100;
};

const reward_amt = (RecommandationPost.recommandation.target * quantity) - (RecommandationPost.recommandation.price_band_from * quantity);
const risk_amt = (RecommandationPost.recommandation.price_band_from * quantity) - (RecommandationPost.recommandation.stoploss * quantity);

let trailing_risk_amt;
if(RecommandationPost.recommandation.trailing_stoploss <= RecommandationPost.recommandation.price_band_from){
  trailing_risk_amt = (RecommandationPost.recommandation.price_band_from * quantity) - (RecommandationPost.recommandation.trailing_stoploss * quantity);
}else{
  trailing_risk_amt = (RecommandationPost.recommandation.trailing_stoploss * quantity) - (RecommandationPost.recommandation.price_band_from * quantity);
}
let currentPositionPercentage;
if (RecommandationPost.recommandation.status === 'exit') {
  if (result === 'success') {
    currentPositionPercentage = 100;
  } else {
    currentPositionPercentage = 0.5;
  }
} else {
  currentPositionPercentage = Math.max(0, calculateCurrentPositionPercentage(currentValue));
}

  return (
    <div style={{overflowX:'hidden'}}>
      <div className='row pt-2'>
        <div className='col' style={{paddingRight:'0px',marginRight:'-25px'}}>
       <Link   to="#" onClick={handleGoBack} style={{padding:'5px 10px'}}>
       <svg xmlns="http://www.w3.org/2000/svg" width="35" height="25" viewBox="0 0 24 24">
	<path fill="black" d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1" />
</svg>
          </Link>
          </div>
          <div className='col' style={{paddingLeft:'0px'}}>
            <img src={logo} alt="logo" style={{width:'30px',height:'30px'}}/>
          </div>
          </div>
      <section style={{paddingTop:"10px"}}>
        <div className="custom-container">
        

          
        {RecommandationPost && (
    <>
        <div className="bill-box pb-3" style={{border:'none',marginBottom:'-9px'}}> {/* Added pb-3 for bottom padding */}
            <div className="d-flex gap-3">
                <div className="bill-icon" style={{width:'60px'}}>
                    <img className="img-fluid icon" src={"https://images.5paisa.com/MarketIcons/" + RecommandationPost.recommandation.symbol+".png"} alt="p6" style={{width:'50px'}} />
                </div>
                <div className="bill-details">
                    <h3 style={{marginBottom:'-5px'}}><b>{RecommandationPost.recommandation.script_name} </b></h3>
                    <span  style={{fontSize:'10px'}}>{RecommandationPost.stock_list.industry_name} | {RecommandationPost.stock_list.sector_name}</span>
                    <h6 >Type: {RecommandationPost.recommandation.stock_type}</h6>
                    
                    
                </div>

                
                
             
            </div>
               {data1.stock_purchase && data1.stock_purchase.length > 0 ? (
        // Filter and calculate the quantity
        (() => {
            let quantity = 0;

            // Filter the stock purchase based on the package_id and recommendation.id
            const stockPurchaseMatch = data1.stock_purchase.find(stock_purchase => {
              return stock_purchase && Number(stock_purchase.package_id) === RecommandationPost.recommandation.id;
            });
            

            const stockcancle = RecommandationPost.recommandation.status === "cancel"  ;
            const stockexit = RecommandationPost.recommandation.status === "exit"  ;
            if(stockcancle){
              quantity = <div className="bill-price" style={{width:"100%"}}>
              <div style={{backgroundColor:'gray', width:"100%", color:'white',padding:'3px 3px',margin:'4px',borderRadius:'10px', textAlign:"center"}}>
               Stock Cancelled 
              </div>
              </div>;
            } else if (stockexit) {
              quantity = <div className="bill-price" style={{width:"100%"}}>
              <div style={{backgroundColor:'black', width:"100%", color:'white',padding:'3px 3px',margin:'4px',borderRadius:'10px', textAlign:"center"}}>
               Stock Exited 
              </div>
              </div>;
               
            }  else if (stockPurchaseMatch) {
              quantity = <div className="bill-price" style={{width:"100%"}}>
              <div style={{backgroundColor:'#5e17eb', width:"100%", color:'white',padding:'3px 3px',margin:'4px',borderRadius:'10px', textAlign:"center"}}>Added to Portfolio</div>
              </div>;
            } else {
              quantity = (
                <div>
                <div className="bill-price" style={{ width: "100%" }}>
                    {/* Add to Portfolio button */}
                    <div
                        onClick={handleButtonClick}
                        style={{
                            backgroundColor: '#5e17eb',
                            width: "100%",
                            color: 'white',
                            padding: '3px 3px',
                            margin: '4px',
                            borderRadius: '10px',
                            textAlign: "center"
                        }}
                    >
                        + Add to Portfolio
                    </div>
                    </div>
                   
                    <div  onClick={handleButtonClickupstox}
                        style={{
                            backgroundColor: '#5e17eb',
                            color: 'white',
                            padding: '3px 3px',
                            margin: '4px',
                            borderRadius: '10px',
                            textAlign: "center"
                        }}>
                      
                      
                       Auto Execute On Upstox
                    </div>
                </div>
            );
              
            }
            
            // Return the calculated quantity
            return (
                <span >
                    {quantity}
                </span>
            );
        })()
    ) : null}
            
           
        </div>
       
    </>
)}
{RecommandationPost && (
    <>
 <div className="bill-box pb-3"  style={{border:'none',marginBottom:'5px',marginTop:'25px'}}> 
{/* {nearStopLoss && (
        <div style={{ color: 'red' }}>Near Stop Loss</div>
      )}

    
      {nearTarget && (
        <div style={{ color: 'green' }}>Near Target</div>
      )}

      
      {remainingPercentage !== 0 && (
        <div style={{ color }}>{remainingPercentage.toFixed(2)}%</div>
      )} */}
       <div className="range-bar-labels ">
        <span><div className='d-flex'><div className='index-for-bar' style={{backgroundColor:'#ff00007a'}}> </div>Below Entry Point</div></span>
        <span><div className='d-flex'><div className='index-for-bar' style={{backgroundColor:'gray'}}> </div>Buying Zone</div></span>
        </div>
      <div className="range-bar-labels mb-3 mt-2 " >
      <span><div className='d-flex'><div className='index-for-bar' style={{backgroundColor:'#00ff6f'}}> </div>Above Entry Point</div> </span>
      <span><div className='d-flex'><div className='index-for-bar' style={{backgroundColor:'#5e17eb'}}> </div>Current Position</div> </span>
      </div>
      <div className="range-bar-labels mb-3 mt-2 " >
      <span><div className='d-flex'><div className='index-for-bar' style={{backgroundColor:'#000'}}> </div>Below Trailing Stoploss</div> </span>
      </div>
      <div className="range-bar-container">
      {RecommandationPost.recommandation.trailing_stoploss && (<div
          className="range-bar"
          style={{ width: `${Math.abs(percentageFromStopLossToPriceBandFrom)}%`, backgroundColor: '#000' }}
        />)}
      {RecommandationPost.recommandation.trailing_stoploss <= RecommandationPost.recommandation.price_band_from &&
       <div
          className="range-bar"
          style={{ width: `${Math.abs(percentageFromTrailingStopLossToPriceBandFrom)}%`, backgroundColor: '#ff00007a' }}
        />
      }
      {RecommandationPost.recommandation.trailing_stoploss <= RecommandationPost.recommandation.price_band_to &&
        <div
          className="range-bar"
          style={{ width: `${Math.abs(percentageFromPriceBandFromToPriceBandTo)}%`, backgroundColor: 'gray' }}
        />
      }
        <div
          className="range-bar"
          style={{ width: `${Math.abs(percentageFromPriceBandToToTarget)}%`, backgroundColor: '#00ff6f' }}
        />
        <div
          className="current-position-dot"
          style={{ left: `${currentPositionPercentage}%` }}
        />
      </div>
      <div className="range-bar-labels">
        <span>{stoploss}{RecommandationPost.recommandation.trailing_stoploss && (` - ${RecommandationPost.recommandation.trailing_stoploss}`)}</span>
        <span>{price_band_from} - {price_band_to}</span>
        <span>{target}</span>
      </div>
      </div>
      
        {/* <div className="bill-box pb-3"  style={{border:'none',marginBottom:'-9px',marginTop:'25px'}}> 
            <div className="">


            <div className='row' style={{textAlign:'center'}}>
            <div className='col' style={{backgroundColor:'#2600a211',color:'voilet',padding:'10px',margin:'4px',borderRadius:'10px'}}>          
              <h5 > Open   </h5><h5><b>₹{RecommandationPost.stock_list.u_open} </b></h5>
              </div>
              <div className='col' style={{backgroundColor:'#2600a211',color:'voilet',padding:'10px',margin:'4px',borderRadius:'10px'}}>          
              <h5 > High   </h5><h5><b>₹{RecommandationPost.stock_list.u_high}</b></h5>
             </div>
             <div className='col' style={{backgroundColor:'#2600a211',color:'voilet',padding:'10px',margin:'4px',borderRadius:'10px'}}>          
              <h5 > Low   </h5><h5><b>₹{RecommandationPost.stock_list.u_low} </b></h5>
             </div>
                <div className='col' style={{backgroundColor:'#2600a211',color:'voilet',padding:'10px',margin:'4px',borderRadius:'10px'}}>
                <h5 > Close   </h5><h5><b>₹{RecommandationPost.stock_list.u_close}</b></h5>
             </div>  
              
             <div className='col' style={{ height: 'fit-content', textAlign:'center', backgroundColor:'#2600a211',color:'voilet',padding:'10px',margin:'4px',borderRadius:'10px'}}>
              <h5 > LTP </h5> <h6><b>₹{RecommandationPost.stock_list.u_last_price}</b></h6>  
              </div> 
              
              </div>
               
             
                
             
            </div>
            
          
        </div>
        <br /> */}
     
    </>
)}
<div className='bill-details"' style={{height:"200px"}}>
 <Tradingview  symbol={`BSE:${RecommandationPost.recommandation.symbol}`} />
 </div>
{RecommandationPost && (
    <>
        <div className="bill-box pb-3"  style={{border:'none',marginBottom:'-9px',marginTop:'10px'}}> {/* Added pb-3 for bottom padding */}
            <div className="">


            <div className='row' style={{textAlign:'center',marginBottom:'4px'}}>
                <div className='col' style={{backgroundColor:'#2600a211',color:'voilet',padding:'10px',margin:'4px',borderRadius:'10px'}}>
              <h3 > Type </h3> <h2><b>{capitalizedRecommendationType}</b></h2>  
              </div>  
              <div className='col' style={{backgroundColor:'#2600a211',color:'voilet',padding:'10px',margin:'4px',borderRadius:'10px'}}>          
              <h3 > Duration   </h3><h2><b>{RecommandationPost.recommandation.duration} {RecommandationPost.recommandation.duration_type}</b></h2>
              </div>
              </div>
               
             
                
             
            </div>
            
          
        </div>
        <br />
     
    </>
)}
{RecommandationPost && (
    <>
        <div className="bill-box pb-3"  style={{border:'none',marginBottom:'-9px'}}> {/* Added pb-3 for bottom padding */}
            <div className="">


            <div style={{backgroundColor:'#5e17eb',textAlign:'center',padding:'4px',borderRadius:'10px'}}>              
              <h3 className='text-white'> <b>Quantity  </b> </h3> <h2 className='text-white'><b>{quantity}</b></h2> 
              </div> 
               
                            
              <p style={{textAlign:'justify',margin:'5px 0 0 0'}}> <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
	<path fill="#5e17eba1" d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22" />
</svg> This quantity is depend on the capital and risk per trade and calculated on those basis you should purchase an given quantity of stock.</p>
              
                
             
            </div>
          
        </div>
        <br />
     
    </>
)}
 {RecommandationPost && (
    <>
        <div className="bill-box pb-3"   style={{border:'none',marginBottom:'-9px'}}> {/* Added pb-3 for bottom padding */}
            <div className="">
            <div className='row' style={{textAlign:'center',marginBottom:'4px'}}>
            <div className='col' style={{backgroundColor:'#5e17eb',color:'white',padding:'10px',margin:'4px',borderRadius:'10px'}}>
              <h3 ><b> Buying zone </b> </h3> <h2><b>₹{RecommandationPost.recommandation.price_band_from} - ₹{RecommandationPost.recommandation.price_band_to}</b></h2>  
              </div>
                </div>
                <div className='row' style={{textAlign:'center',marginBottom:'4px'}}>
                <div className='col' style={{backgroundColor:'#5e17eb',color:'white',padding:'4px',margin:'0 4px',borderRadius:'10px'}}>              
              <h6 className='text-white'> <b>Capital Required </b> </h6> <h5 className='text-white'><b>₹{capital_required1} - ₹{capital_required} </b></h5> 
              </div> 
              </div>
              <p style={{textAlign:'justify',margin:'5px 0 0 0'}}> <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
	<path fill="#5e17eba1" d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22" />
</svg> You should place your buy order in this zone only.</p>
                
             
            </div>
            
          
        </div>
        <br />
     
    </>
)}
{RecommandationPost && (
    <>
    
        <div className="bill-box pb-3"   style={{border:'none',marginBottom:'-9px'}}> {/* Added pb-3 for bottom padding */}
            <div className="">
            <div className='row' style={{textAlign:'center',marginBottom:'4px'}}>
                <div className='col' style={{backgroundColor:'red',color:'white',padding:'5px',margin:'4px',borderRadius:'10px'}}>
              <h4 ><b> Stop-Loss </b> </h4> <div className=""> <h3><b>₹{RecommandationPost.recommandation.stoploss} </b> </h3><div> <h4 style={{alignContent:'center',marginLeft:'5px'}}>(Stop-Loss Percentage {rounded_loss}%)</h4><h4>(Stop-Loss Value ₹{risk_amt} )</h4> </div> </div>
              </div>  
              </div>
             {RecommandationPost.recommandation.trailing_stoploss && ( <div className='row' style={{textAlign:'center',marginBottom:'4px'}}><div className='col' style={{backgroundColor:'#FFC107',color:'white',padding:'5px',margin:'4px',borderRadius:'10px'}}>
              <h4 ><b> Trailing Stop-Loss </b> </h4> <div className=""> <h3><b>₹{RecommandationPost.recommandation.trailing_stoploss} </b> </h3><div>  <h4 style={{alignContent:'center',marginLeft:'5px'}}>(Trailing SL Percentage {trailing_rounded_loss}% )</h4><h4>(Trailing SL Value ₹{trailing_risk_amt})</h4> </div></div>
              </div></div>)} 
              <div className='row' style={{textAlign:'center',marginBottom:'4px'}}> 
              <div className='col' style={{backgroundColor:'green',color:'white',padding:'5px',margin:'4px',borderRadius:'10px'}}>          
              <h4 > <b>Target  </b> </h4><div className=" justify-content-center"><h3><b>₹{RecommandationPost.recommandation.target} </b></h3><div><h4 style={{alignContent:'center',marginLeft:'5px'}}>(Target Percentage {rounded_return}% )</h4><h4> ( Target Value ₹{reward_amt})</h4></div></div>
              </div>
              </div>
              
              <div> 
              <div className='row' style={{textAlign:'center',marginBottom:'4px'}}>
                <div className='col' style={{backgroundColor:'#5e17eb',color:'white',padding:'4px',margin:'0 4px',borderRadius:'10px'}}>              
              <h6 className='text-white'> <b>Risk Reward Ratio  </b> </h6> <h5 className='text-white'><b>1:{rounded_final_reward} </b></h5> 
              </div>          
              </div>    
              <p style={{textAlign:'justify',margin:'5px 0 0 0'}}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
	<path fill="#5e17eba1" d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22" />
</svg> You should set the stop loss on this amount and after target achive you should get exited from the call as well as Risk Reward Ratio is also mention.</p>
              </div>
                
             
            </div>
          
        </div>
        <br />
     
    </>
)}

{RecommandationPost && (
    <>
        <div className="bill-box pb-3"   style={{border:'none',marginBottom:'-9px'}}> {/* Added pb-3 for bottom padding */}
            <div className="" style={{textAlign:"center"}}>


            <div className='col' style={{backgroundColor:'#2aa20030',color:'voilet',padding:'10px',margin:'4px',borderRadius:'10px'}}>
              <h3 > <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 36 36">
	<path fill="black" d="M32.25 6H29v2h3v22H4V8h3V6H3.75A1.78 1.78 0 0 0 2 7.81v22.38A1.78 1.78 0 0 0 3.75 32h28.5A1.78 1.78 0 0 0 34 30.19V7.81A1.78 1.78 0 0 0 32.25 6" class="clr-i-outline clr-i-outline-path-1" />
	<path fill="black" d="M8 14h2v2H8z" class="clr-i-outline clr-i-outline-path-2" />
	<path fill="black" d="M14 14h2v2h-2z" class="clr-i-outline clr-i-outline-path-3" />
	<path fill="black" d="M20 14h2v2h-2z" class="clr-i-outline clr-i-outline-path-4" />
	<path fill="black" d="M26 14h2v2h-2z" class="clr-i-outline clr-i-outline-path-5" />
	<path fill="black" d="M8 19h2v2H8z" class="clr-i-outline clr-i-outline-path-6" />
	<path fill="black" d="M14 19h2v2h-2z" class="clr-i-outline clr-i-outline-path-7" />
	<path fill="black" d="M20 19h2v2h-2z" class="clr-i-outline clr-i-outline-path-8" />
	<path fill="black" d="M26 19h2v2h-2z" class="clr-i-outline clr-i-outline-path-9" />
	<path fill="black" d="M8 24h2v2H8z" class="clr-i-outline clr-i-outline-path-10" />
	<path fill="black" d="M14 24h2v2h-2z" class="clr-i-outline clr-i-outline-path-11" />
	<path fill="black" d="M20 24h2v2h-2z" class="clr-i-outline clr-i-outline-path-12" />
	<path fill="black" d="M26 24h2v2h-2z" class="clr-i-outline clr-i-outline-path-13" />
	<path fill="black" d="M10 10a1 1 0 0 0 1-1V3a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1" class="clr-i-outline clr-i-outline-path-14" />
	<path fill="black" d="M26 10a1 1 0 0 0 1-1V3a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1" class="clr-i-outline clr-i-outline-path-15" />
	<path fill="black" d="M13 6h10v2H13z" class="clr-i-outline clr-i-outline-path-16" />
	<path fill="none" d="M0 0h36v36H0z" />
</svg> Date of Analysis </h3> <h2><b>{formatAPIResponseDate(RecommandationPost.recommandation.date_of_recommandation)}</b></h2>  
              </div>  
              {RecommandationPost.recommandation.date_of_exicutation &&
              <div className='col' style={{backgroundColor:'#fff40078',color:'voilet',padding:'10px',margin:'4px',borderRadius:'10px'}}>
              <h3 > <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 36 36">
	<path fill="black" d="M32.25 6H29v2h3v22H4V8h3V6H3.75A1.78 1.78 0 0 0 2 7.81v22.38A1.78 1.78 0 0 0 3.75 32h28.5A1.78 1.78 0 0 0 34 30.19V7.81A1.78 1.78 0 0 0 32.25 6" class="clr-i-outline clr-i-outline-path-1" />
	<path fill="black" d="M8 14h2v2H8z" class="clr-i-outline clr-i-outline-path-2" />
	<path fill="black" d="M14 14h2v2h-2z" class="clr-i-outline clr-i-outline-path-3" />
	<path fill="black" d="M20 14h2v2h-2z" class="clr-i-outline clr-i-outline-path-4" />
	<path fill="black" d="M26 14h2v2h-2z" class="clr-i-outline clr-i-outline-path-5" />
	<path fill="black" d="M8 19h2v2H8z" class="clr-i-outline clr-i-outline-path-6" />
	<path fill="black" d="M14 19h2v2h-2z" class="clr-i-outline clr-i-outline-path-7" />
	<path fill="black" d="M20 19h2v2h-2z" class="clr-i-outline clr-i-outline-path-8" />
	<path fill="black" d="M26 19h2v2h-2z" class="clr-i-outline clr-i-outline-path-9" />
	<path fill="black" d="M8 24h2v2H8z" class="clr-i-outline clr-i-outline-path-10" />
	<path fill="black" d="M14 24h2v2h-2z" class="clr-i-outline clr-i-outline-path-11" />
	<path fill="black" d="M20 24h2v2h-2z" class="clr-i-outline clr-i-outline-path-12" />
	<path fill="black" d="M26 24h2v2h-2z" class="clr-i-outline clr-i-outline-path-13" />
	<path fill="black" d="M10 10a1 1 0 0 0 1-1V3a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1" class="clr-i-outline clr-i-outline-path-14" />
	<path fill="black" d="M26 10a1 1 0 0 0 1-1V3a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1" class="clr-i-outline clr-i-outline-path-15" />
	<path fill="black" d="M13 6h10v2H13z" class="clr-i-outline clr-i-outline-path-16" />
	<path fill="none" d="M0 0h36v36H0z" />
</svg>  Date of Execution </h3> <h2><b>{formatAPIResponseDate(RecommandationPost.recommandation.date_of_exicutation)}</b></h2>  
              </div>
              }
              {RecommandationPost.recommandation.date_of_exit &&
              <div className='col' style={{backgroundColor:'#a2000030',color:'voilet',padding:'10px',margin:'4px',borderRadius:'10px'}}>
              <h3 > <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 36 36">
	<path fill="black" d="M32.25 6H29v2h3v22H4V8h3V6H3.75A1.78 1.78 0 0 0 2 7.81v22.38A1.78 1.78 0 0 0 3.75 32h28.5A1.78 1.78 0 0 0 34 30.19V7.81A1.78 1.78 0 0 0 32.25 6" class="clr-i-outline clr-i-outline-path-1" />
	<path fill="black" d="M8 14h2v2H8z" class="clr-i-outline clr-i-outline-path-2" />
	<path fill="black" d="M14 14h2v2h-2z" class="clr-i-outline clr-i-outline-path-3" />
	<path fill="black" d="M20 14h2v2h-2z" class="clr-i-outline clr-i-outline-path-4" />
	<path fill="black" d="M26 14h2v2h-2z" class="clr-i-outline clr-i-outline-path-5" />
	<path fill="black" d="M8 19h2v2H8z" class="clr-i-outline clr-i-outline-path-6" />
	<path fill="black" d="M14 19h2v2h-2z" class="clr-i-outline clr-i-outline-path-7" />
	<path fill="black" d="M20 19h2v2h-2z" class="clr-i-outline clr-i-outline-path-8" />
	<path fill="black" d="M26 19h2v2h-2z" class="clr-i-outline clr-i-outline-path-9" />
	<path fill="black" d="M8 24h2v2H8z" class="clr-i-outline clr-i-outline-path-10" />
	<path fill="black" d="M14 24h2v2h-2z" class="clr-i-outline clr-i-outline-path-11" />
	<path fill="black" d="M20 24h2v2h-2z" class="clr-i-outline clr-i-outline-path-12" />
	<path fill="black" d="M26 24h2v2h-2z" class="clr-i-outline clr-i-outline-path-13" />
	<path fill="black" d="M10 10a1 1 0 0 0 1-1V3a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1" class="clr-i-outline clr-i-outline-path-14" />
	<path fill="black" d="M26 10a1 1 0 0 0 1-1V3a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1" class="clr-i-outline clr-i-outline-path-15" />
	<path fill="black" d="M13 6h10v2H13z" class="clr-i-outline clr-i-outline-path-16" />
	<path fill="none" d="M0 0h36v36H0z" />
</svg>  Date of Exit </h3> <h2><b>{formatAPIResponseDate(RecommandationPost.recommandation.date_of_exit)}</b></h2>  
              </div>
              }
            {holdingDuration &&
              <div className='col' style={{backgroundColor:'#5e17eb4d',color:'voilet',padding:'10px',margin:'4px',borderRadius:'10px'}}>
              <h3 > <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
	<path fill="black" d="M12 20a8 8 0 0 0 8-8a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8m0-18a10 10 0 0 1 10 10a10 10 0 0 1-10 10C6.47 22 2 17.5 2 12A10 10 0 0 1 12 2m.5 5v5.25l4.5 2.67l-.75 1.23L11 13V7z" />
</svg>  Holding Days </h3> <h2><b>{holdingDuration} Days</b></h2>  
              </div>
              }
               
              
             
              
                
             
            </div>
          
        </div>
        <br />
     
    </>
)}
{RecommandationPost && (
    <>
        <div className="bill-box pb-3"   style={{border:'none',marginBottom:'-9px'}}> {/* Added pb-3 for bottom padding */}
            <div className="">
               
              <h3> <b>Company Introduction</b> </h3>               
              <p style={{textAlign:'justify',margin:'5px 0 0 0',fontFamily:'math'}}>{RecommandationPost.stock_list.description}</p>
              
                
             
            </div>
          
        </div>
        <br />
     
    </>
)}



        </div>
      </section>
    </div>
  );
}
export default Recomm_details;




