import logo from './assets/theme_asset/favicon.png';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Login from './login';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";



import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';
import up_logo from './assets/theme_asset/upstox_logo.png';


import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import Modal from "./modal";
import SkeletonLoader from './SkeletonLoader';






function Upstock_form() {

  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission status

  const [recMetrics,setRecMetrics] = useState([0,0,0])
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({});
  const [callback, setCallback] = useState(null);
  


  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
      setData(response.data);
      
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Initially set the callback to data.user.up_redirect_uri if it's available
    if (data && data.user && data.user.up_redirect_uri) {
        setCallback(data.user.up_redirect_uri);
    }
}, [data]);

const handleChange = (e) => {

  e.preventDefault(); // Prevent default form submission behavior

    const client_code = e.target.value;

    // Construct the new URL based on the client_code
    const newCallback = client_code
        ? `https://stock.ukvalley.com/public/webhookUpstoxAuthCodeUser/${client_code}`
        : data.user.up_redirect_uri;

    setCallback(newCallback);

    copyToClipboard(newCallback);

};
  

  

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    setIsSubmitting(true);
   
    try {
        const up_client_id = e.target.elements.up_client_id?.value; // Use `e.target.elements` to access inputs
        const id = e.target.elements.id?.value;
        const client_code = e.target.elements.client_code?.value;
        const up_client_secret = e.target.elements.up_client_secret?.value;
     


      const response = await axios.post(`${apiBaseUrl}/upstock_connect`, {
        up_client_id,
        id,
        client_code,
        up_client_secret,
       
      }, {
        headers: {
          'Content-Type': 'application/json', // Set content type to application/json for JSON data
        },
      });

      setModalMessage({
        title: 'User updated Successfully',
        content: 'You have upaded the information successfully',
      });
      setShowModal(true);
      navigate('/upstock_form'); // Redirect the user to the homepage after successful registration
      // You can add any further actions here, like redirecting the user or showing a success message
    } catch (error) {
      console.error('Error updating user:', error);
      setModalMessage({
        title: 'Error',
        content: 'Failed to update the information. Please enter vaild data or try again later.',
      });
      setShowModal(true);
      // Handle error, show error message, etc.
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setIsSubmitting(false);
  };
 
  const copyToClipboard = (callback) => {



    const textToCopy = callback.toString();


    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          alert('Copied to clipboard!'); 
        })
        .catch((error) => console.error('Could not copy text: ', error));
    } else {
      // Fallback for browsers that don't support clipboard API
      const textField = document.createElement('textarea');
      textField.innerText = textToCopy;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
      alert('Copied to clipboard!'); 
    }
  };

  useEffect(() => {
    // Replace icons after component is mounted
    
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/upstock_form');
    }
    feather.replace();

    fetchData();
  }, []);

  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

 
  if(data == null)
    {
      return (
       <>  </>
       )
    }


    const getTodayDate = () => {
        const today = new Date();
        // Format the date as Y-m-d
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

  
  return (

    
    <div style={{overflowX:'hidden'}}>
     {showModal && <Modal message={modalMessage} closeModal={closeModal} />}
       
       <div className="auth-header">
       <div>
            <Link to="/" style={{ textDecoration: 'none' , marginTop: '20px' , paddingLeft:'10px'}}>
           <svg xmlns="http://www.w3.org/2000/svg" width="35" height="25" viewBox="0 0 24 24" style={{marginTop:'5px'}}> 
              <path fill="white" d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1" />
            </svg>
            </Link>
          </div>
    <img
      className="img-fluid img"
      src={auth6}
      alt="v1"
    />
    <div className="auth-content">
      <div>
        <h2>Welcome</h2>
        <h4 className="p-0">Fill the Upstox details</h4>
      </div>
    </div>
  </div>
  {/* header end */}
  {/* login section start */}

  
  <form className="auth-form" onSubmit={handleSubmit}>
    <div className="custom-container">

    {data.user.up_auth_updated_at === getTodayDate() ? (
    // Render "Updated" if the dates match
    <div style={{backgroundColor: '#622cfd', color: 'white', border: 'none', borderRadius: '10px', padding: '10px', fontSize: '15px',textAlign:'center' }}><b>Connected to Upstox valid till {data.user.up_auth_updated_at}</b></div>
) : (
    // Render the buttons if the dates do not match
    <div className="d-flex mb-2" style={{ justifyContent: 'space-evenly' }}>
        <a href={`https://stock.ukvalley.com/public/callUpstoxAuthCode_login_user/${data.user.id}`} >
            <div style={{ backgroundColor: '#622cfd', color: 'white', border: 'none', borderRadius: '10px', padding: '10px', fontSize: '13px' }}>Generate Login code</div>
        </a>
        <a href={`https://stock.ukvalley.com/public/upstockAuthKeyUser/${data.user.id}`} >
            <div style={{ backgroundColor: '#622cfd', color: 'white', border: 'none', borderRadius: '10px', padding: '10px', fontSize: '13px' }}>Generate Auth Token</div>
        </a>
    </div>
)}
<h2 className="mb-2" style={{textAlign:'center',marginLeft:'10px',width:'100%'}}> <img src={up_logo} style={{width:'150px',marginLeft:'-20px'}} /> </h2><h2>Follow the below steps:</h2> 

<h3 className="mb-2">1) Below One ipnut box is there You should Input your Upstox Client Code below</h3>

<div className="form-group">
  <label htmlFor="mobile" className="form-label">
  Upstox's Client Code
  </label>
  <div className="form-input">
    <input
      type="text"
      className="form-control"
      id="client_code"
      name="client_code"
      defaultValue={data.user.client_code}
      onBlur ={handleChange}
      placeholder="Enter your Upstox Client Code"
    />
  </div>
</div>

<h3 className="mb-2">2) After that Copy the Below Link</h3> 

    <>
        <div className="form-group">
            <div className="form-input">
            <input
                        type="text"
                        className="form-control"
                        id="callback"
                        name="callback"
                        value={callback} // Controlled input using the callback state
                        placeholder="Generated Callback URL"
                        readOnly
                        style={{ fontSize: '8px' }} // Adjust font size as needed
                    />
            </div>
        </div>
    </>




<h3 className="mb-2">3) After Copying Open your Upstox account and in that you will see an App tab and in that paste the code and enter the name of app Finvalley.</h3> 
<h3 className="mb-2">4) After Successfully doing above steps get your Client ID and Secret Key and paste in Finvalley app in below 2 input box. </h3> 


    <div className="form-group">
  <label htmlFor="name" className="form-label">
  Upstox's Client ID
  </label>
  <div className="form-input">
    <input
      type="text"
      className="form-control"
      id="up_client_id"
      name="up_client_id"
      defaultValue={data.user.up_client_id}
      
      placeholder="Enter your Upstox Client Id"
    />
    <input
      type="hidden"
      className="form-control"
      id="id"
      name="id"
      defaultValue={data.user.id}
      placeholder="Enter your ID"
    />
  </div>
</div>


<div className="form-group">
  <label htmlFor="email" className="form-label">
  Upstox's Client Secret
  </label>
  <div className="form-input">
    <input
      type="text"
      className="form-control"
      id="up_client_secret"
      name="up_client_secret"
      defaultValue={data.user.up_client_secret}

      placeholder="Enter Your Upstox Client Secret"
    />
  </div>
</div>

<h3 className="mb-2">5) Now click on update profile button below after that you will get 2 buttons 1)Generate login code and 2)Generate Auth Token </h3> 
<h3 className="mb-2">Note you should update you login code or auth token on daily basis </h3> 
     
      <button type='submit' className="btn theme-btn w-100" style={{color:'white'}} disabled={isSubmitting}>
            {isSubmitting ? "Updateing..." : "Update Information"}
          </button>
      
    </div>
  </form>
       
        
      </div>
  );
}

 


export default Upstock_form;
