import logo from './assets/theme_asset/favicon.png';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';


import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Link } from 'react-router-dom';

// Assuming you're using React Router and have a route set up for blog details








import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import SkeletonLoader from './SkeletonLoader';







function FAQ() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchData = async () => {
    try {
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/faqpageapi/${id}`);
      setData(response.data.faq);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token == null) {
      navigate('/login');
    } else {
      fetchData();
      feather.replace();
    }
  }, []);

  const handleQuestionClick = (index) => {
    setData(data.map((item, i) => {
      if (i === index) {
        return { ...item, isOpen: !item.isOpen };
      } else {
        return { ...item, isOpen: false };
      }
    }));
  };

  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div style={{overflowX:'hidden'}}>
      <div className="auth-header" style={{height:'150px'}}>
      <div>
      <Link to="/profile" >
      <svg xmlns="http://www.w3.org/2000/svg" width="35" height="25" viewBox="0 0 24 24" style={{marginTop:'5px'}}> 
              <path fill="white" d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1" />
            </svg>
          </Link>
            </div>
        
        <div className="auth-content" style={{justifyContent:'center'}}>
          <div>
            <center>
            <h2>FAQ's</h2>
            <h4 className="p-0">Answers at your fingertips</h4>
            </center></div>
        </div>
      </div>
      
      <form className="auth-form" target="_blank">
        <div className="custom-container">
          <div className="help-center">
            <h2 className="fw-semibold">What issues are you facing?</h2>
            {data.map((faq, index) => (
              <div className="accordion accordion-flush help-accordion" key={index}>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className={`accordion-button${faq.isOpen ? '' : ' collapsed'}`} type="button" onClick={() => handleQuestionClick(index)}>
                      {faq.question}
                    </button>
                  </h2>
                  <div className={`accordion-collapse collapse${faq.isOpen ? ' show' : ''}`}>
                    <div className="accordion-body" dangerouslySetInnerHTML={{ __html: faq.answer }}>
                      
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </form>
    </div>
  );
}

export default FAQ;

