import logo from "./assets/theme_asset/favicon.png";

import feather from "feather-icons";
import Footer from "./footer";
import Sidebar from "./Sidebar";

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Login from "./login";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import favicon from "./assets/theme_asset/logo/favicon.png";

import logo1 from "./assets/theme_asset/logo/logo.png";

import logo2 from "./assets/theme_asset/svg/logo1.svg";

import auth_bg from "./assets/theme_asset/background/auth-bg.jpg";

import arrow from "./assets/theme_asset/svg/arrow.svg";

import arrow_down_right from "./assets/theme_asset/svg/arrow-down-right.svg";
import arrow_up_right from "./assets/theme_asset/svg/arrow-up-right.svg";

import p1 from "./assets/theme_asset/person/p1.png";
import p2 from "./assets/theme_asset/person/p2.png";
import p3 from "./assets/theme_asset/person/p3.png";
import p4 from "./assets/theme_asset/person/p4.png";
import p5 from "./assets/theme_asset/person/p5.png";
import ellipse from "./assets/theme_asset/svg/ellipse.svg";

import a1 from "./assets/theme_asset/svg/1.svg";
import a2 from "./assets/theme_asset/svg/2.svg";
import a3 from "./assets/theme_asset/svg/3.svg";
import a4 from "./assets/theme_asset/svg/4.svg";
import a5 from "./assets/theme_asset/svg/5.svg";

import a6 from "./assets/theme_asset/svg/6.svg";
import a7 from "./assets/theme_asset/svg/7.svg";
import a8 from "./assets/theme_asset/svg/8.svg";
import a9 from "./assets/theme_asset/svg/9.svg";
import a10 from "./assets/theme_asset/svg/10.svg";
import a11 from "./assets/theme_asset/svg/11.svg";

import card_chip from "./assets/theme_asset/svg/card-chip.svg";

import img1 from "./assets/theme_asset/1.jpg";

import contact from "./assets/theme_asset/svg/contact.svg";

import auth6 from "./assets/theme_asset/authentication/6.svg";

import img2 from "./assets/theme_asset/2.jpg";
import img3 from "./assets/theme_asset/3.jpg";
import img4 from "./assets/theme_asset/4.jpg";
import l49 from "./assets/theme_asset/logo/48.png";

import search from "./assets/search (1).png";

import "./App.css";

import scan from "./assets/theme_asset/svg/scan.svg";
import { useEffect } from "react";
import { Bar } from "react-chartjs-2";

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import SkeletonLoader from "./SkeletonLoader";

function Profile() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics, setRecMetrics] = useState([0, 0, 0]);

  const fetchData = async () => {
    try {
      const id = localStorage.getItem("id");
      const response = await axios.get(`${apiBaseUrl}/sidebarapi/${id}`);
      setData(response.data);
      
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Replace icons after component is mounted

    const token = localStorage.getItem("token");
    if (token == null) {
      navigate("/login");
    } else {
      navigate("/profile");
    }
    feather.replace();

    fetchData();
  }, []);

  if (loading) {
    return <SkeletonLoader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  if (data === null) {
    return null;
  }

  const handleLogout = () => {
    // Remove token from localStorage
    localStorage.removeItem('token');
    // Navigate to the login page by changing the URL
    window.location.href = '/login';
  };

  if (data == null) {
    return <SkeletonLoader showHeader={false} showFooter={true} />;
  }

  return (
    <div style={{overflowX:'hidden'}}>
       <div className='row pt-2'>
        <div className='col' style={{paddingRight:'0px',marginRight:'-25px'}}>
       <Link to="/" style={{padding:'5px 10px'}}>
       <svg xmlns="http://www.w3.org/2000/svg" width="35" height="25" viewBox="0 0 24 24">
	<path fill="black" d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1" />
</svg>
          </Link>
          </div>
          <div className='col' style={{paddingLeft:'0px'}}>
            <img src={logo} alt="logo" style={{width:'30px',height:'30px'}}/>
          </div>
          </div>

      {/* header end */}
      {/* profile section start */}

      <section style={{padding:'0px'}}>
        <div className="custom-container" style={{marginTop:'-30px'}}>
          <div className="profile-section">
            <div className="profile-banner">
              <h2 style={{ color: "white", paddingTop: "20px" }}>
                Hello, {data.user.name}
              </h2>
              <h5 style={{ color: "white" }}>{data.user.mobile}</h5>
            </div>
          </div>
          <ul className="profile-list">
            <li>
              <Link to="/account" className="profile-box">
                <div className="profile-img">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="#7031fe" d="M6 17c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1H6m9-9a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3a3 3 0 0 1 3 3M3 5v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2" />
</svg>
                </div>
                <div className="profile-details">
                  <h4>My Account</h4>
                  <img className="img-fluid arrow" src={arrow} alt="arrow" />
                </div>
              </Link>
            </li>
            <li>
              <Link to="/change_password" className="profile-box">
                <div className="profile-img">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="#7031fe" d="M19 13c.34 0 .67.04 1 .09V10a2 2 0 0 0-2-2h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6a2 2 0 0 0-2 2v10c0 1.11.89 2 2 2h7.81c-.51-.88-.81-1.9-.81-3c0-3.31 2.69-6 6-6M9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9zm3 11a2 2 0 1 1 2-2c0 1.11-.89 2-2 2m11 1v2h-3v3h-2v-3h-3v-2h3v-3h2v3z" />
</svg>
                </div>
                <div className="profile-details">
                  <h4>Change Password</h4>
                  <img className="img-fluid arrow" src={arrow} alt="arrow" />
                </div>
              </Link>
            </li>
            <li>
              <Link to="/faq" className="profile-box">
                <div className="profile-img">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="#7031fe" d="M18 15H6l-4 4V3a1 1 0 0 1 1-1h15a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1m5-6v14l-4-4H8a1 1 0 0 1-1-1v-1h14V8h1a1 1 0 0 1 1 1M8.19 4c-.87 0-1.57.2-2.11.59c-.52.41-.78.98-.77 1.77l.01.03h1.93c.01-.3.1-.53.28-.69a1 1 0 0 1 .66-.23c.31 0 .57.1.75.28c.18.19.26.45.26.75c0 .32-.07.59-.23.82c-.14.23-.35.43-.61.59c-.51.34-.86.64-1.05.91C7.11 9.08 7 9.5 7 10h2c0-.31.04-.56.13-.74s.26-.36.51-.52c.45-.24.82-.53 1.11-.93s.44-.81.44-1.31c0-.76-.27-1.37-.81-1.82C9.85 4.23 9.12 4 8.19 4M7 11v2h2v-2zm6 2h2v-2h-2zm0-9v6h2V4z" />
</svg>
                </div>
                <div className="profile-details">
                  <h4>FAQ's</h4>
                  <img className="img-fluid arrow" src={arrow} alt="arrow" />
                </div>
              </Link>
            </li>
            <li>
              <Link to="/about" className="profile-box">
                <div className="profile-img">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="#7031fe" d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22" />
</svg>
                </div>
                <div className="profile-details">
                  <h4>About us</h4>
                  <img className="img-fluid arrow" src={arrow} alt="arrow" />
                </div>
              </Link>
            </li>
            <li>
              <Link to="/privacy" className="profile-box">
                <div className="profile-img">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="#7031fe" d="M12 22q-3.475-.875-5.738-3.988T4 11.1V5l8-3l8 3v6.1q0 3.8-2.262 6.913T12 22" />
</svg>
                </div>
                <div className="profile-details">
                  <h4>Privacy and Policy</h4>
                  <img className="img-fluid arrow" src={arrow} alt="arrow" />
                </div>
              </Link>
            </li>
            <li>
              <Link to="/terms" className="profile-box">
                <div className="profile-img">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="#7031fe" d="M13 9h5.5L13 3.5zM6 2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.11.89-2 2-2m9 16v-2H6v2zm3-4v-2H6v2z" />
</svg>
                </div>
                <div className="profile-details">
                  <h4>Terms and Condition</h4>
                  <img className="img-fluid arrow" src={arrow} alt="arrow" />
                </div>
              </Link>
            </li>
            <li>
              <Link to="#" onClick={handleLogout} className="profile-box">
                <div className="profile-img">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 20 20">
	<path fill="#7031fe" d="M10.24 0c3.145 0 6.057 1.395 7.988 3.744a.644.644 0 0 1-.103.92a.68.68 0 0 1-.942-.1a8.961 8.961 0 0 0-6.944-3.256c-4.915 0-8.9 3.892-8.9 8.692c0 4.8 3.985 8.692 8.9 8.692a8.962 8.962 0 0 0 7.016-3.343a.68.68 0 0 1 .94-.113a.644.644 0 0 1 .115.918C16.382 18.564 13.431 20 10.24 20C4.583 20 0 15.523 0 10S4.584 0 10.24 0m6.858 7.16l2.706 2.707c.262.261.267.68.012.936l-2.644 2.643a.662.662 0 0 1-.936-.01a.662.662 0 0 1-.011-.937l1.547-1.547H7.462a.662.662 0 0 1-.67-.654c0-.362.3-.655.67-.655h10.269l-1.558-1.558a.662.662 0 0 1-.011-.936a.662.662 0 0 1 .936.011" />
</svg>
                </div>
                <div className="profile-details">
                  <h4>Log Out</h4>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </section>
      <section className="panel-space" />
      {/* panel-space end */}
      {/* bottom navbar start */}

      {/* bottom navbar end */}
      {/* add money modal start */}
      <Footer />
    </div>
  );
}

export default Profile;
