import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyB4heCZDZ_mVGbQFy_oXQ-j85p-k1ojswM",
    authDomain: "finvalley-2c74c.firebaseapp.com",
    projectId: "finvalley-2c74c",
    storageBucket: "finvalley-2c74c.appspot.com",
    messagingSenderId: "858837430253",
    appId: "1:858837430253:web:6f40c648a45423387901e4",
    measurementId: "G-35QCX539C1"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Firebase Messaging
export const messaging = getMessaging(app);

// Function to request permission for notifications and get the FCM token
export const requestPermission = async () => {
    try {
        // Request permission for notifications
        const permission = await Notification.requestPermission();
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        const id = localStorage.getItem("id");

        if (permission !== 'granted') {
            console.error("Notifications permission denied.");
            return;
        }

        if (!messaging) {
            console.error("Firebase Messaging is not supported in this browser.");
            return;
        }

        const currentToken = await getToken(messaging, { vapidKey: "BG1WcWwkv9uEJUOLSzIyWsijUpd7b0IXnR-3VE0lYhnPQ_DlDfWa2SBx2arIeFzah8TPFXnMxEp56pblLkDeERU" });

        if (currentToken) {
            // Send the token to your backend server
            const response = await fetch(`${apiBaseUrl}/save_token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: currentToken, id: id }),
            });
            await fetch(`${apiBaseUrl}/subscribe_to_topic`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token: currentToken, topic: 'all_users' }),
            });
           
        } else {
            console.error("Failed to obtain FCM token.");
        }
    } catch (error) {
        console.error("An error occurred while requesting permission or sending the token:", error);
    }
};

