import logo from "./assets/theme_asset/favicon.png";



import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import $ from 'jquery';

import { Link } from "react-router-dom";

// Assuming you're using React Router and have a route set up for blog details


import "./App.css";
import auth_bg from "./assets/theme_asset/background/auth-bg.jpg";


import { useEffect } from "react";

import Modal from "./modal";

import React from "react";

import './pricing.css';

const Pricing = () => {
  const navigate = useNavigate();
  const razorpayKey = process.env.RAZORPAY_API_KEY;
  const [selectedValue, setSelectedValue] = useState("option1");

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [data, setData] = useState(null);


  const handleRadioChange = (value) => {
    setSelectedValue(value);
  };

  const fetchData = async () => {
    try {
      const id = localStorage.getItem("id");
      const response = await axios.get(`${apiBaseUrl}/packagepageapi/${id}`);
      setData(response.data);

     

    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    // Replace icons after component is mounted

   
    const token = localStorage.getItem("token");
    if (token == null) {
      navigate("/login");
    } else {
      navigate("/pricing");
    }
    console.log(token);

    fetchData();
  }, []);
  const [paymentStatus, setPaymentStatus] = useState(null);

  const handleClick = async (event, packageId, amount) => {
    event.preventDefault();
    await initiatePayment(packageId, amount);
};



  const initiatePayment = async (packageId, amount) => {
    try {


        const response = await axios.post(`${apiBaseUrl}/create-order`, {
            amount: amount, 
            currency: 'INR', 
            user_id:data && data.user && data.user.id,
            package_id:packageId
        });

        
        const order_id = response.data.orderId;

        const handlePayment = async (response) => {
          try {
           
              const verifyResponse = await axios.post(`${apiBaseUrl}/payment-callback`, {
                  payment_id: response.razorpay_payment_id,
                  amount: amount,
                  order_id: response.razorpay_order_id,
              });
              fetchData();
          } catch (error) {
              console.error('Error verifying payment:', error);
          }
      };

      //   if (verifyResponse.data.success) {
      //     showSuccessScreen();
      // } else {
      //     showFailureScreen();
      // }
      
        const options = {
            key: razorpayKey, 
            amount: response.data.amount,
            currency: response.data.currency,
            order_id: order_id,
            name: 'Stock Up',
            description: 'Payment for services',
            handler: handlePayment,
            prefill: {
                name: 'John Doe',
                email: 'john@example.com',
                contact: '9999999999'
            }
        };
        const rzp = new window.Razorpay(options);
        rzp.open();
    } catch (error) {
        console.error('Error initiating payment:', error);
        setPaymentStatus('error');
    }
};

useEffect(() => {
  const script = document.createElement('script');
  script.src = "https://checkout.razorpay.com/v1/checkout.js";
  script.async = true;
  document.body.appendChild(script);
  return () => {
    document.body.removeChild(script);
  };
},[])

  return (
    <div>
      <div className="auth-header" style={{height:'120px'}}>
        <div>
          <Link to="/" style={{ textDecoration: "none" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="25" viewBox="0 0 24 24" style={{marginTop:'5px'}}> 
              <path fill="white" d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1" />
            </svg>
          </Link>
        </div>

        <div className="auth-content" style={{ justifyContent: "center" }}>
          <div>
            <center>
              <h1
                className="text-white"
                style={{
                  fontWeight: "bold",
                  fontFamily: "-moz-initial",
                  padding: "5px",
                  position: "relative",
                }}
              >
                {" "}
                Pricing
              </h1>

              <h3
                className="text-white"
                style={{
                  fontFamily: "serif",
                }}
              >
                View the Pricing Details
              </h3>
            </center>
          </div>
        </div>
      </div>
     
      <form className="auth-form" style={{paddingTop:'0px'}}>
        <div className="custom-container">
         
        {data && data.package && data.package.length > 0 && (
  <div className="custom-container">
   {data.package
  .filter((package1) => package1.id >= (data.transaction?.package_id || 0))
  .map((package1, index) => (
    <section key={package1.id} style={{ marginBottom: '20px' }}>
      <div className="custom-container">
        <div className="m_wrap">
          <div className="g-3 mb-3">
            <div className="bill-box mb-3 pb-3">
              {/* Added pb-3 for bottom padding */}
              <div className="d-flex gap-3">
                <div className="bill-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 24 24">
	<path fill="#622cfd" fill-rule="evenodd" d="M3.6 2.25A1.35 1.35 0 0 0 2.25 3.6v16.8c0 .746.604 1.35 1.35 1.35h16.8a1.35 1.35 0 0 0 1.35-1.35V3.6a1.35 1.35 0 0 0-1.35-1.35zm9.15 3.25a.75.75 0 0 0-1.5 0v1.103c-.515.077-1.02.255-1.447.579C9.13 7.692 8.75 8.483 8.75 9.5q0 .744.342 1.316c.225.376.534.653.865.864c.612.39 1.393.602 2.039.778l.057.016c.719.196 1.284.357 1.685.612c.185.118.305.239.382.367c.073.123.13.292.13.547c0 .61-.245.992-.604 1.243c-.39.273-.965.422-1.62.399c-1-.037-1.955-.464-2.426-1.092a.75.75 0 1 0-1.2.9c.67.893 1.757 1.425 2.85 1.615V18.5a.75.75 0 0 0 1.5 0v-1.391c.628-.075 1.242-.276 1.756-.637c.757-.53 1.244-1.37 1.244-2.472q0-.744-.342-1.316a2.6 2.6 0 0 0-.865-.864c-.612-.39-1.393-.602-2.039-.778l-.057-.016c-.719-.196-1.284-.357-1.685-.612a1.1 1.1 0 0 1-.382-.367a1.03 1.03 0 0 1-.13-.547c0-.614.21-.935.458-1.122c.28-.212.716-.335 1.272-.32c.949.026 1.961.444 2.49.972a.75.75 0 1 0 1.06-1.06c-.682-.683-1.731-1.163-2.78-1.34z" clip-rule="evenodd" />
</svg>
                </div>
                <div className="bill-details">
                  <h1 className="dark-text">{package1.package_name}</h1>
                  <h6 className="light-text mt-1">
                    {package1.package_duration} Months
                  </h6>
                </div>
                <div
                  style={{ justifyContent: 'flex-end', padding: '10px' }}
                >
                  <h1>₹{package1.package_amount}</h1>
                </div>
              </div>
              <div>
                {package1.package_amount === (data.transaction?.amount || 0) ? (
                  <Link
                    
                    style={{ color: 'white', marginTop: '10px' }}
                    className="btn theme-btn w-100"
                  >
                    Subscribed on {new Date(data.transaction.start_date).toLocaleDateString()}
                  </Link>
                ) : (
                  <Link
                    onClick={(event) =>
                      handleClick(event, package1.id, package1.package_amount)
                    }
                    style={{ color: 'white', marginTop: '10px' }}
                    className="btn theme-btn w-100"
                  >
                    Upgrade to plan
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  ))}

  </div>
)}

     <div>
            <table class="table" style={{marginTop:'50px'}}>
              <thead>
                <tr>
                  <th>Features</th>
                  <th>Free</th>
                  <th>Paid</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Stock Analysis</td>
                  <td>✔</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Sectoral Trend</td>
                  <td>✔</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>FIll till Date</td>
                  <td>✔</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Educational Content</td>
                  <td>✔</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Analysis</td>
                  <td>-</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Multibagger Portfolio</td>
                  <td>-</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Short Trades</td>
                  <td>-</td>
                  <td>✔</td>
                </tr>
                <tr>
                  <td>Stock Research Report</td>
                  <td>-</td>
                  <td>✔</td>
                </tr>
               
              </tbody>
            </table>
          </div>

         
        </div>
      </form>
    </div>
  );
};

export default Pricing;
