import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';
import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import SkeletonLoader from './SkeletonLoader';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { Link, useLocation  } from 'react-router-dom';

import './recommandation.css';

function Recommendation1() {
  const [data, setData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterType, setFilterType] = useState('all');
  const [filterStatus, setFilterStatus] = useState('all');
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState('all');

  
useEffect(() => {

  
  const params = new URLSearchParams(location.search);
  const status = params.get('status') || 'all'; // Use 'status' instead of 'type'
  const type = params.get('type') || 'all';

  setFilterStatus(status); // Update filter status
  setFilterType(type);

  handleSort(filterStatus, filterType);
  
}, []);



useEffect(() => {
  handleSort(filterStatus,filterType)
},[originalData]);


  const fetchData = async () => {
    try {
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/Recommandationsdetailsapi/${id}`);
      setData(response.data);
      setOriginalData(response.data);

    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
      handleSort(filterStatus,filterType)
    }
  };
  
  useEffect(() => {
    fetchData();
    
    const params = new URLSearchParams(location.search);
   

   
}, [location.search]);



const handleSort = (status, type) => {
    let filtered;

    if (originalData && originalData.recommandation) {
        if (status === 'all' && type === 'all') {
            filtered = originalData.recommandation;
        } else {
            filtered = originalData.recommandation.filter(recommendation => {
                return (status === 'all' || recommendation.status === status) && 
                       (type === 'all' || recommendation.recommandation_type === type);
            });
        }
        setData(prevData => ({ ...prevData, recommandation: filtered })); // Update data state
    }
}

const selectRef = useRef(null);

  const handleSvgClick = () => {
    if (selectRef.current) {
      selectRef.current.click();
    }
  };

  if (loading) {
    return (
        
      <SkeletonLoader showHeader={false} showFooter={false} /> 

    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (data === null) {
    return null;
  }


  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setFilterStatus(tab);
  };

  const RecommendationCard = ({ recommendation }) => {
    const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

    const getBackgroundClass = (status) => {
      switch (status) {
        case 'buy':
          return 'bg-green';
        case 'short':
          return 'bg-red';
        case 'hold':
          return 'bg-yellow';
        case 'exit':
          return 'bg-black';
        case 'cancel':
          return 'bg-gray';
        default:
          return '';
      }
    };

    const getStatusClass = (status) => {
      switch (status) {
        case 'buy':
          return 'bg-green-status';
        case 'short':
          return 'bg-red-status';
        case 'hold':
          return 'bg-yellow-status';
        case 'exit':
          return 'bg-black-status';
        case 'cancel':
          return 'bg-gray-status';
        default:
          return '';
      }
    };

    // Function to compute the default quantity
const computeDefaultQuantity = (recommendation, data) => {
  const percentage = (data.Stock_rsk) * (data.user.percentage / 100);
  const percentage1 = (data.future_rsk) * (data.user.percentage_future / 100);
  const percentage2 = (data.option_rsk) * (data.user.percentage_option / 100);

  // Compute quantity based on recommendation stock type
  return recommendation.stock_type === 'options'
      ? Math.max(0, Math.floor(percentage2 / (recommendation.price_band_to - recommendation.stoploss)))
      : recommendation.stock_type === 'futures'
      ? Math.max(0, Math.floor(percentage1 / (recommendation.price_band_to - recommendation.stoploss)))
      : Math.max(0, Math.floor(percentage / (recommendation.price_band_to - recommendation.stoploss)));
};

// Function to find matching amount from stockPurchases

const recommendationId = recommendation.id;
const stockPurchases = data.stock_purchase;


const findMatchingAmount = (recommendationId, stockPurchases) => {
  // Iterate through stockPurchases array
  for (const purchase of stockPurchases) {
      // Check if the purchase is not null or undefined
      if (purchase && purchase.package_id !== undefined) {
          // Check if package_id matches recommendationId
          if (Number(purchase.package_id) === recommendationId) {
              // Return the amount if a match is found
              return purchase.amount;
          }
      }
  }
  // Return null if no match is found
  return null;
};


// Use the functions to determine the quantity
const determineQuantity = (recommendation, data, stockPurchases) => {
  // Check if there is a matching entry in stockPurchases
  const matchingAmount = findMatchingAmount(recommendationId, stockPurchases);
  // If a matching amount is found, use it
  if (matchingAmount === null) {
    return computeDefaultQuantity(recommendation, data);
  }

  // Otherwise, compute the default quantity
  return matchingAmount;

};

// Example usage in your JSX or elsewhere in your component
const quantity = determineQuantity(recommendation, data, stockPurchases);

// Now you can use `quantity` in your component as needed
 
const industry = data.stock_list.find(stock_list => stock_list.symbol_up === recommendation.symbol);

const risk = recommendation.price_band_to - recommendation.stoploss;

const reward =  recommendation.target - recommendation.price_band_to;

const final_reward = reward / risk;

const rounded_final_reward = parseFloat(final_reward.toFixed(2));


const total_return = (industry.u_last_price - recommendation.price_band_from ) /  recommendation.price_band_from * 100;

     const rounded_return = parseFloat(total_return.toFixed(2));

     const total_loss = (industry.u_last_price - recommendation.price_band_from) /  recommendation.price_band_from * 100;

     const rounded_loss = parseFloat(total_loss.toFixed(2));

  return (
    <div>
       { selectedTab === 'all' && (
      <div>
      <Link to={`/recomm_details/${recommendation.id}?quantity=${quantity}`}>
        <div className="card-box mt-3">
          <div className={`card-details ${getBackgroundClass(recommendation.status)}`}>
            <div className="d-flex justify-content-between">
            <div className="">
                      <h5 className="fw-semibold">
                        {recommendation.script_name} | {recommendation.symbol}
                      </h5>
                      {data.stock_list && data.stock_list.length > 0 && (
                                        <div>
                                           {industry && <div className="" style={{fontSize:'10px',color:'white'}}>
                                                      {industry.industry_name} | {industry.sector_name}
                                                  </div>
                                                  } 
                                        </div>
                                    )}  </div>
                      <div className="">
                      <img
                        className="rec_img"
                        src={"https://images.5paisa.com/MarketIcons/" + recommendation.symbol+".png"}
                        alt="ellipse"
                      />
                      </div></div>
            <div className="">
              <div className="text-start">
                <div className="">
                  <div className='d-flex justify-content-between'>
                    <h3 className="mt-1 text-white">₹ {recommendation.price_band_from} - {recommendation.price_band_to} {recommendation.status === "hold" && (
  <span
    style={{
      backgroundColor: industry.u_last_price > recommendation.price_band_to ? 'green' : 'red',
      padding: '2px 5px',
      textAlign: 'center'
    }}
  >
    {"(" + (industry.u_last_price > recommendation.price_band_to ? rounded_return : rounded_loss) + "%)"}
  </span>
)}

</h3>
                    <h3 className="mt-1 text-white">QTY {quantity}</h3>
                  </div>
                </div>
                <p className='text-muted-new'>Buy in this Zone | <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
	<path fill="#b7c0bc" d="M12 20a8 8 0 0 0 8-8a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8m0-18a10 10 0 0 1 10 10a10 10 0 0 1-10 10C6.47 22 2 17.5 2 12A10 10 0 0 1 12 2m.5 5v5.25l4.5 2.67l-.75 1.23L11 13V7z" />
</svg> {recommendation.duration} {recommendation.duration_type}</p>
              </div>
            </div>
            <div className="amount-details">
              <div className="amount w-50 text-start">
                <div className="d-flex align-items-center justify-content-start">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14">
	<g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round">
		<path d="M13.48 7.516a6.5 6.5 0 1 1-6.93-7" />
		<path d="M9.79 8.09A3 3 0 1 1 5.9 4.21M7 7l2.5-2.5m2 .5l-2-.5l-.5-2l2-2l.5 2l2 .5z" />
	</g>
</svg>
                  <h6 className='text-white' style={{marginLeft:'5px'}}>Target</h6>
                </div>
                <h3 className="text-white" ><span style={{ backgroundColor: recommendation.result === 'success' && 'lightGreen',padding:'2px 5px',textAlign:'center'}}>₹{recommendation.target}</span></h3>
              </div>
              <div className="amount w-50 text-end border-0">
                <div className="d-flex align-items-center justify-content-end">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16">
	<path fill="#fff" d="M4.47.22A.75.75 0 0 1 5 0h6c.199 0 .389.079.53.22l4.25 4.25c.141.14.22.331.22.53v6a.75.75 0 0 1-.22.53l-4.25 4.25A.75.75 0 0 1 11 16H5a.75.75 0 0 1-.53-.22L.22 11.53A.75.75 0 0 1 0 11V5c0-.199.079-.389.22-.53Zm.84 1.28L1.5 5.31v5.38l3.81 3.81h5.38l3.81-3.81V5.31L10.69 1.5ZM8 4a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5A.75.75 0 0 1 8 4m0 8a1 1 0 1 1 0-2a1 1 0 0 1 0 2" />
</svg>
                 <h6 className='text-white'  style={{marginLeft:'5px'}}>Stoploss</h6>
                </div>
                <h3 className="text-white" ><span style={{ backgroundColor: recommendation.result === 'fail' ? '#ff6a6a' : recommendation.trailing_stoploss && '#ffc107',padding:'2px 5px',textAlign:'center'}}>₹{recommendation.trailing_stoploss ? recommendation.trailing_stoploss : recommendation.stoploss}</span></h3>
              </div>
            </div>
            <div className="amount-details">
            <div className="amount w-50 text-start">
                <div className="d-flex align-items-center justify-content-start">
                <svg xmlns="http://www.w3.org/2000/svg" width="15.5" height="12" viewBox="0 0 640 512">
	<path fill="#fff" d="M384 32h128c17.7 0 32 14.3 32 32s-14.3 32-32 32H398.4c-5.2 25.8-22.9 47.1-46.4 57.3V448h160c17.7 0 32 14.3 32 32s-14.3 32-32 32H128c-17.7 0-32-14.3-32-32s14.3-32 32-32h160V153.3c-23.5-10.3-41.2-31.6-46.4-57.3H128c-17.7 0-32-14.3-32-32s14.3-32 32-32h128c14.6-19.4 37.8-32 64-32s49.4 12.6 64 32m55.6 288h144.8L512 195.8zm72.4 96c-62.9 0-115.2-34-126-78.9c-2.6-11 1-22.3 6.7-32.1l95.2-163.2c5-8.6 14.2-13.8 24.1-13.8s19.1 5.3 24.1 13.8L631.3 305c5.7 9.8 9.3 21.1 6.7 32.1C627.2 382 574.9 416 512 416M126.8 195.8L54.4 320h144.9zM.9 337.1c-2.6-11 1-22.3 6.7-32.1l95.2-163.2c5-8.6 14.2-13.8 24.1-13.8s19.1 5.3 24.1 13.8L246.2 305c5.7 9.8 9.3 21.1 6.7 32.1C242 382 189.7 416 126.8 416S11.7 382 .9 337.1" />
</svg>
                 <h6 className='text-white' style={{marginLeft:'5px'}}> Risk Reward Ratio</h6>
                </div>
                <h3 className="text-white">{rounded_final_reward} times</h3>
              </div>
              <div className="amount w-50 text-end border-0">
                <div className="d-flex align-items-center justify-content-end">
                <svg xmlns="http://www.w3.org/2000/svg"  width="12" height="12"  viewBox="0 0 20 20">
	<path fill="#fff" d="M5.673 0a.7.7 0 0 1 .7.7v1.309h7.517v-1.3a.7.7 0 0 1 1.4 0v1.3H18a2 2 0 0 1 2 1.999v13.993A2 2 0 0 1 18 20H2a2 2 0 0 1-2-1.999V4.008a2 2 0 0 1 2-1.999h2.973V.699a.7.7 0 0 1 .7-.699M1.4 7.742v10.259a.6.6 0 0 0 .6.6h16a.6.6 0 0 0 .6-.6V7.756zm5.267 6.877v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zm-8.333-3.977v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zM4.973 3.408H2a.6.6 0 0 0-.6.6v2.335l17.2.014V4.008a.6.6 0 0 0-.6-.6h-2.71v.929a.7.7 0 0 1-1.4 0v-.929H6.373v.92a.7.7 0 0 1-1.4 0z" />
</svg>
                  <h6 className='text-white' style={{marginLeft:'5px'}}>Date(Analysis)</h6>
                </div>
                <h3 className="text-white">
  {new Date(recommendation.date_of_recommandation).toLocaleDateString('en-GB')}
</h3>
              </div>
            </div>
          </div>
          <a href="#add-money" className={`add-money theme-color`} data-bs-toggle="modal">
            <span className={`${getStatusClass(recommendation.status)}`}>{recommendation.status}</span>
          </a>
        </div>
      </Link>
      </div>
    )}
    { selectedTab === 'buy' && recommendation.status === 'buy' && (
      <div>
      <Link to={`/recomm_details/${recommendation.id}?quantity=${quantity}`}>
        <div className="card-box mt-3">
          <div className={`card-details ${getBackgroundClass(recommendation.status)}`}>
            <div className="d-flex justify-content-between">
            <div className="">
                      <h5 className="fw-semibold">
                        {recommendation.script_name} | {recommendation.symbol}
                      </h5>
                      {data.stock_list && data.stock_list.length > 0 && (
                                        <div>
                                           {industry && <div className="" style={{fontSize:'10px',color:'white'}}>
                                                      {industry.industry_name} | {industry.sector_name}
                                                  </div>
                                                  } 
                                        </div>
                                    )}  </div>
                      <div className="">
                      <img
                        className="rec_img"
                        src={"https://images.5paisa.com/MarketIcons/" + recommendation.symbol+".png"}
                        alt="ellipse"
                      />
                      </div></div>
            <div className="">
              <div className="text-start">
                <div className="">
                  <div className='d-flex justify-content-between'>
                    <h3 className="mt-1 text-white">₹ {recommendation.price_band_from} - {recommendation.price_band_to} <span className={industry.u_last_price > recommendation.price_band_to ? 'success-color' : 'error-color'} >{recommendation.status === "hold" && (
  "(" + (industry.u_last_price > recommendation.price_band_to ? rounded_return : rounded_loss) + "%)" 
)}</span></h3>
                    <h3 className="mt-1 text-white">QTY {quantity}</h3>
                  </div>
                </div>
                <p className='text-muted-new'>Buy in this Zone | <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
	<path fill="#b7c0bc" d="M12 20a8 8 0 0 0 8-8a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8m0-18a10 10 0 0 1 10 10a10 10 0 0 1-10 10C6.47 22 2 17.5 2 12A10 10 0 0 1 12 2m.5 5v5.25l4.5 2.67l-.75 1.23L11 13V7z" />
</svg> {recommendation.duration} {recommendation.duration_type}</p>
              </div>
            </div>
            <div className="amount-details">
              <div className="amount w-50 text-start">
                <div className="d-flex align-items-center justify-content-start">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14">
	<g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round">
		<path d="M13.48 7.516a6.5 6.5 0 1 1-6.93-7" />
		<path d="M9.79 8.09A3 3 0 1 1 5.9 4.21M7 7l2.5-2.5m2 .5l-2-.5l-.5-2l2-2l.5 2l2 .5z" />
	</g>
</svg>
                  <h6 className='text-white' style={{marginLeft:'5px'}}>Target</h6>
                </div>
                <h3 className="text-white" >₹ {recommendation.target}</h3>
              </div>
              <div className="amount w-50 text-end border-0">
                <div className="d-flex align-items-center justify-content-end">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16">
	<path fill="#fff" d="M4.47.22A.75.75 0 0 1 5 0h6c.199 0 .389.079.53.22l4.25 4.25c.141.14.22.331.22.53v6a.75.75 0 0 1-.22.53l-4.25 4.25A.75.75 0 0 1 11 16H5a.75.75 0 0 1-.53-.22L.22 11.53A.75.75 0 0 1 0 11V5c0-.199.079-.389.22-.53Zm.84 1.28L1.5 5.31v5.38l3.81 3.81h5.38l3.81-3.81V5.31L10.69 1.5ZM8 4a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5A.75.75 0 0 1 8 4m0 8a1 1 0 1 1 0-2a1 1 0 0 1 0 2" />
</svg>
                 <h6 className='text-white'  style={{marginLeft:'5px'}}>Stoploss</h6>
                </div>
                <h3 className="text-white" >₹ {recommendation.trailing_stoploss ? recommendation.trailing_stoploss : recommendation.stoploss}</h3>
              </div>
            </div>
            <div className="amount-details">
            <div className="amount w-50 text-start">
                <div className="d-flex align-items-center justify-content-start">
                <svg xmlns="http://www.w3.org/2000/svg" width="15.5" height="12" viewBox="0 0 640 512">
	<path fill="#fff" d="M384 32h128c17.7 0 32 14.3 32 32s-14.3 32-32 32H398.4c-5.2 25.8-22.9 47.1-46.4 57.3V448h160c17.7 0 32 14.3 32 32s-14.3 32-32 32H128c-17.7 0-32-14.3-32-32s14.3-32 32-32h160V153.3c-23.5-10.3-41.2-31.6-46.4-57.3H128c-17.7 0-32-14.3-32-32s14.3-32 32-32h128c14.6-19.4 37.8-32 64-32s49.4 12.6 64 32m55.6 288h144.8L512 195.8zm72.4 96c-62.9 0-115.2-34-126-78.9c-2.6-11 1-22.3 6.7-32.1l95.2-163.2c5-8.6 14.2-13.8 24.1-13.8s19.1 5.3 24.1 13.8L631.3 305c5.7 9.8 9.3 21.1 6.7 32.1C627.2 382 574.9 416 512 416M126.8 195.8L54.4 320h144.9zM.9 337.1c-2.6-11 1-22.3 6.7-32.1l95.2-163.2c5-8.6 14.2-13.8 24.1-13.8s19.1 5.3 24.1 13.8L246.2 305c5.7 9.8 9.3 21.1 6.7 32.1C242 382 189.7 416 126.8 416S11.7 382 .9 337.1" />
</svg>
                 <h6 className='text-white' style={{marginLeft:'5px'}}> Risk Reward Ratio</h6>
                </div>
                <h3 className="text-white">{rounded_final_reward} times</h3>
              </div>
              <div className="amount w-50 text-end border-0">
                <div className="d-flex align-items-center justify-content-end">
                <svg xmlns="http://www.w3.org/2000/svg"  width="12" height="12"  viewBox="0 0 20 20">
	<path fill="#fff" d="M5.673 0a.7.7 0 0 1 .7.7v1.309h7.517v-1.3a.7.7 0 0 1 1.4 0v1.3H18a2 2 0 0 1 2 1.999v13.993A2 2 0 0 1 18 20H2a2 2 0 0 1-2-1.999V4.008a2 2 0 0 1 2-1.999h2.973V.699a.7.7 0 0 1 .7-.699M1.4 7.742v10.259a.6.6 0 0 0 .6.6h16a.6.6 0 0 0 .6-.6V7.756zm5.267 6.877v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zm-8.333-3.977v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zM4.973 3.408H2a.6.6 0 0 0-.6.6v2.335l17.2.014V4.008a.6.6 0 0 0-.6-.6h-2.71v.929a.7.7 0 0 1-1.4 0v-.929H6.373v.92a.7.7 0 0 1-1.4 0z" />
</svg>
                  <h6 className='text-white' style={{marginLeft:'5px'}}>Date(Analysis)</h6>
                </div>
                <h3 className="text-white">
  {new Date(recommendation.date_of_recommandation).toLocaleDateString('en-GB')}
</h3>
              </div>
            </div>
          </div>
          <a href="#add-money" className={`add-money theme-color`} data-bs-toggle="modal">
            <span className={`${getStatusClass(recommendation.status)}`}>{recommendation.status}</span>
          </a>
        </div>
      </Link>
      </div>
    )}
    { selectedTab === 'hold' && recommendation.status === 'hold' && (
      <div>
      <Link to={`/recomm_details/${recommendation.id}?quantity=${quantity}`}>
        <div className="card-box mt-3">
          <div className={`card-details ${getBackgroundClass(recommendation.status)}`}>
            <div className="d-flex justify-content-between">
            <div className="">
                      <h5 className="fw-semibold">
                        {recommendation.script_name} | {recommendation.symbol}
                      </h5>
                      {data.stock_list && data.stock_list.length > 0 && (
                                        <div>
                                           {industry && <div className="" style={{fontSize:'10px',color:'white'}}>
                                                      {industry.industry_name} | {industry.sector_name}
                                                  </div>
                                                  } 
                                        </div>
                                    )}  </div>
                      <div className="">
                      <img
                        className="rec_img"
                        src={"https://images.5paisa.com/MarketIcons/" + recommendation.symbol+".png"}
                        alt="ellipse"
                      />
                      </div></div>
            <div className="">
              <div className="text-start">
                <div className="">
                  <div className='d-flex justify-content-between'>
                    <h3 className="mt-1 text-white">₹ {recommendation.price_band_from} - {recommendation.price_band_to} {recommendation.status === "hold" && (
  <span
    style={{
      backgroundColor: industry.u_last_price > recommendation.price_band_from ? 'green' : 'red',
      padding: '2px 5px',
      textAlign: 'center'
    }}
  >
    {"(" + (industry.u_last_price > recommendation.price_band_from ? rounded_return : rounded_loss) + "%)"}
  </span>
)}</h3>
                    <h3 className="mt-1 text-white">QTY {quantity}</h3>
                  </div>
                </div>
                <p className='text-muted-new'>Buy in this Zone | <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
	<path fill="#b7c0bc" d="M12 20a8 8 0 0 0 8-8a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8m0-18a10 10 0 0 1 10 10a10 10 0 0 1-10 10C6.47 22 2 17.5 2 12A10 10 0 0 1 12 2m.5 5v5.25l4.5 2.67l-.75 1.23L11 13V7z" />
</svg> {recommendation.duration} {recommendation.duration_type}</p>
              </div>
            </div>
            <div className="amount-details">
              <div className="amount w-50 text-start">
                <div className="d-flex align-items-center justify-content-start">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14">
	<g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round">
		<path d="M13.48 7.516a6.5 6.5 0 1 1-6.93-7" />
		<path d="M9.79 8.09A3 3 0 1 1 5.9 4.21M7 7l2.5-2.5m2 .5l-2-.5l-.5-2l2-2l.5 2l2 .5z" />
	</g>
</svg>
                  <h6 className='text-white' style={{marginLeft:'5px'}}>Target</h6>
                </div>
                <h3 className="text-white">₹ {recommendation.target}</h3>
              </div>
              <div className="amount w-50 text-end border-0">
                <div className="d-flex align-items-center justify-content-end">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16">
	<path fill="#fff" d="M4.47.22A.75.75 0 0 1 5 0h6c.199 0 .389.079.53.22l4.25 4.25c.141.14.22.331.22.53v6a.75.75 0 0 1-.22.53l-4.25 4.25A.75.75 0 0 1 11 16H5a.75.75 0 0 1-.53-.22L.22 11.53A.75.75 0 0 1 0 11V5c0-.199.079-.389.22-.53Zm.84 1.28L1.5 5.31v5.38l3.81 3.81h5.38l3.81-3.81V5.31L10.69 1.5ZM8 4a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5A.75.75 0 0 1 8 4m0 8a1 1 0 1 1 0-2a1 1 0 0 1 0 2" />
</svg>
                 <h6 className='text-white'  style={{marginLeft:'5px'}}>Stoploss</h6>
                </div>
                <h3 className="text-white">₹ {recommendation.trailing_stoploss ? recommendation.trailing_stoploss : recommendation.stoploss}</h3>
              </div>
            </div>
            <div className="amount-details">
            <div className="amount w-50 text-start">
                <div className="d-flex align-items-center justify-content-start">
                <svg xmlns="http://www.w3.org/2000/svg" width="15.5" height="12" viewBox="0 0 640 512">
	<path fill="#fff" d="M384 32h128c17.7 0 32 14.3 32 32s-14.3 32-32 32H398.4c-5.2 25.8-22.9 47.1-46.4 57.3V448h160c17.7 0 32 14.3 32 32s-14.3 32-32 32H128c-17.7 0-32-14.3-32-32s14.3-32 32-32h160V153.3c-23.5-10.3-41.2-31.6-46.4-57.3H128c-17.7 0-32-14.3-32-32s14.3-32 32-32h128c14.6-19.4 37.8-32 64-32s49.4 12.6 64 32m55.6 288h144.8L512 195.8zm72.4 96c-62.9 0-115.2-34-126-78.9c-2.6-11 1-22.3 6.7-32.1l95.2-163.2c5-8.6 14.2-13.8 24.1-13.8s19.1 5.3 24.1 13.8L631.3 305c5.7 9.8 9.3 21.1 6.7 32.1C627.2 382 574.9 416 512 416M126.8 195.8L54.4 320h144.9zM.9 337.1c-2.6-11 1-22.3 6.7-32.1l95.2-163.2c5-8.6 14.2-13.8 24.1-13.8s19.1 5.3 24.1 13.8L246.2 305c5.7 9.8 9.3 21.1 6.7 32.1C242 382 189.7 416 126.8 416S11.7 382 .9 337.1" />
</svg>
                 <h6 className='text-white' style={{marginLeft:'5px'}}> Risk Reward Ratio</h6>
                </div>
                <h3 className="text-white">{rounded_final_reward} times</h3>
              </div>
              <div className="amount w-50 text-end border-0">
                <div className="d-flex align-items-center justify-content-end">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"  viewBox="0 0 20 20">
	<path fill="#fff" d="M5.673 0a.7.7 0 0 1 .7.7v1.309h7.517v-1.3a.7.7 0 0 1 1.4 0v1.3H18a2 2 0 0 1 2 1.999v13.993A2 2 0 0 1 18 20H2a2 2 0 0 1-2-1.999V4.008a2 2 0 0 1 2-1.999h2.973V.699a.7.7 0 0 1 .7-.699M1.4 7.742v10.259a.6.6 0 0 0 .6.6h16a.6.6 0 0 0 .6-.6V7.756zm5.267 6.877v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zm-8.333-3.977v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zM4.973 3.408H2a.6.6 0 0 0-.6.6v2.335l17.2.014V4.008a.6.6 0 0 0-.6-.6h-2.71v.929a.7.7 0 0 1-1.4 0v-.929H6.373v.92a.7.7 0 0 1-1.4 0z" />
</svg>
                  <h6 className='text-white' style={{marginLeft:'5px'}}>Date(Analysis)</h6>
                </div>
                <h3 className="text-white">
  {new Date(recommendation.date_of_recommandation).toLocaleDateString('en-GB')}
</h3>
              </div>
            </div>
          </div>
          <a href="#add-money" className={`add-money theme-color`} data-bs-toggle="modal">
            <span className={`${getStatusClass(recommendation.status)}`}>{recommendation.status}</span>
          </a>
        </div>
      </Link>
      </div>
    )}
     { selectedTab === 'exit' && recommendation.status === 'exit' && (
     <div>
     <Link to={`/recomm_details/${recommendation.id}?quantity=${quantity}`}>
       <div className="card-box mt-3">
         <div className={`card-details ${getBackgroundClass(recommendation.status)}`}>
           <div className="d-flex justify-content-between">
           <div className="">
                     <h5 className="fw-semibold">
                       {recommendation.script_name} | {recommendation.symbol}
                     </h5>
                     {data.stock_list && data.stock_list.length > 0 && (
                                       <div>
                                          {industry && <div className="" style={{fontSize:'10px',color:'white'}}>
                                                     {industry.industry_name} | {industry.sector_name}
                                                 </div>
                                                 } 
                                       </div>
                                   )}  </div>
                     <div className="">
                     <img
                       className="rec_img"
                       src={"https://images.5paisa.com/MarketIcons/" + recommendation.symbol+".png"}
                       alt="ellipse"
                     />
                     </div></div>
           <div className="">
             <div className="text-start">
               <div className="">
                 <div className='d-flex justify-content-between'>
                   <h3 className="mt-1 text-white">₹ {recommendation.price_band_from} - {recommendation.price_band_to} <span className={industry.u_last_price > recommendation.price_band_to ? 'success-color' : 'error-color'} >{recommendation.status === "hold" && (
  "(" + (industry.u_last_price > recommendation.price_band_to ? rounded_return : rounded_loss) + "%)" 
)}</span></h3>
                   <h3 className="mt-1 text-white">QTY {quantity}</h3>
                 </div>
               </div>
               <p className='text-muted-new'>Buy in this Zone | <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
	<path fill="#b7c0bc" d="M12 20a8 8 0 0 0 8-8a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8m0-18a10 10 0 0 1 10 10a10 10 0 0 1-10 10C6.47 22 2 17.5 2 12A10 10 0 0 1 12 2m.5 5v5.25l4.5 2.67l-.75 1.23L11 13V7z" />
</svg> {recommendation.duration} {recommendation.duration_type}</p>
             </div>
           </div>
           <div className="amount-details">
             <div className="amount w-50 text-start">
               <div className="d-flex align-items-center justify-content-start">
               <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14">
	<g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round">
		<path d="M13.48 7.516a6.5 6.5 0 1 1-6.93-7" />
		<path d="M9.79 8.09A3 3 0 1 1 5.9 4.21M7 7l2.5-2.5m2 .5l-2-.5l-.5-2l2-2l.5 2l2 .5z" />
	</g>
</svg>
                  <h6 className='text-white' style={{marginLeft:'5px'}}>Target</h6>
               </div>
               <h3 className="text-white" ><span style={{ backgroundColor: recommendation.result === 'success' && 'lightGreen',padding:'2px 5px',textAlign:'center'}}>₹{recommendation.target}</span></h3>
             </div>
             <div className="amount w-50 text-end border-0">
               <div className="d-flex align-items-center justify-content-end">
               <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16">
	<path fill="#fff" d="M4.47.22A.75.75 0 0 1 5 0h6c.199 0 .389.079.53.22l4.25 4.25c.141.14.22.331.22.53v6a.75.75 0 0 1-.22.53l-4.25 4.25A.75.75 0 0 1 11 16H5a.75.75 0 0 1-.53-.22L.22 11.53A.75.75 0 0 1 0 11V5c0-.199.079-.389.22-.53Zm.84 1.28L1.5 5.31v5.38l3.81 3.81h5.38l3.81-3.81V5.31L10.69 1.5ZM8 4a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5A.75.75 0 0 1 8 4m0 8a1 1 0 1 1 0-2a1 1 0 0 1 0 2" />
</svg>
                 <h6 className='text-white'  style={{marginLeft:'5px'}}>Stoploss</h6>
               </div>
               <h3 className="text-white" ><span style={{ backgroundColor: recommendation.result === 'fail' && '#ff6a6a',padding:'2px 5px',textAlign:'center'}}>₹{recommendation.trailing_stoploss ? recommendation.trailing_stoploss : recommendation.stoploss}</span></h3>
             </div>
           </div>
           <div className="amount-details">
           <div className="amount w-50 text-start">
               <div className="d-flex align-items-center justify-content-start">
               <svg xmlns="http://www.w3.org/2000/svg" width="15.5" height="12" viewBox="0 0 640 512">
	<path fill="#fff" d="M384 32h128c17.7 0 32 14.3 32 32s-14.3 32-32 32H398.4c-5.2 25.8-22.9 47.1-46.4 57.3V448h160c17.7 0 32 14.3 32 32s-14.3 32-32 32H128c-17.7 0-32-14.3-32-32s14.3-32 32-32h160V153.3c-23.5-10.3-41.2-31.6-46.4-57.3H128c-17.7 0-32-14.3-32-32s14.3-32 32-32h128c14.6-19.4 37.8-32 64-32s49.4 12.6 64 32m55.6 288h144.8L512 195.8zm72.4 96c-62.9 0-115.2-34-126-78.9c-2.6-11 1-22.3 6.7-32.1l95.2-163.2c5-8.6 14.2-13.8 24.1-13.8s19.1 5.3 24.1 13.8L631.3 305c5.7 9.8 9.3 21.1 6.7 32.1C627.2 382 574.9 416 512 416M126.8 195.8L54.4 320h144.9zM.9 337.1c-2.6-11 1-22.3 6.7-32.1l95.2-163.2c5-8.6 14.2-13.8 24.1-13.8s19.1 5.3 24.1 13.8L246.2 305c5.7 9.8 9.3 21.1 6.7 32.1C242 382 189.7 416 126.8 416S11.7 382 .9 337.1" />
</svg>
                 <h6 className='text-white' style={{marginLeft:'5px'}}> Risk Reward Ratio</h6>
               </div>
               <h3 className="text-white">{rounded_final_reward} times</h3>
             </div>
             <div className="amount w-50 text-end border-0">
               <div className="d-flex align-items-center justify-content-end">
               <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 20 20">
	<path fill="#fff" d="M5.673 0a.7.7 0 0 1 .7.7v1.309h7.517v-1.3a.7.7 0 0 1 1.4 0v1.3H18a2 2 0 0 1 2 1.999v13.993A2 2 0 0 1 18 20H2a2 2 0 0 1-2-1.999V4.008a2 2 0 0 1 2-1.999h2.973V.699a.7.7 0 0 1 .7-.699M1.4 7.742v10.259a.6.6 0 0 0 .6.6h16a.6.6 0 0 0 .6-.6V7.756zm5.267 6.877v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zm-8.333-3.977v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zM4.973 3.408H2a.6.6 0 0 0-.6.6v2.335l17.2.014V4.008a.6.6 0 0 0-.6-.6h-2.71v.929a.7.7 0 0 1-1.4 0v-.929H6.373v.92a.7.7 0 0 1-1.4 0z" />
</svg>
                  <h6 className='text-white' style={{marginLeft:'5px'}}>Date(Analysis)</h6>
               </div>
               <h3 className="text-white">
 {new Date(recommendation.date_of_recommandation).toLocaleDateString('en-GB')}
</h3>
             </div>
           </div>
         </div>
         <a href="#add-money" className={`add-money theme-color`} data-bs-toggle="modal">
           <span className={`${getStatusClass(recommendation.status)}`}>{recommendation.status}</span>
         </a>
       </div>
     </Link>
     </div>
    )}
    </div>
  );
  
   
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <section className="section-b-space" style={{paddingTop:'10px'}}>



      <div className="custom-container">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{marginTop:'-15px'}}>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <button style={{
                padding: '8px 16px',
                backgroundColor: '#fff',
                color: 'black',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                boxShadow: '2px 4px 6px 2px rgba(0, 0, 0, 0.2)',
                transition: 'background-color 0.3s',
              }}>X</button>
            </Link>
          </div>
          <div className="title" >
            <h2 style={{paddingLeft:'50px'}}>Analysis</h2>
          </div>
          <div>
          <div className="custom-select-wrapper" >
  {/* Container for select and SVG */}
  <div  onClick={() => {
      if (selectRef.current) {
        selectRef.current.click(); // Trigger click event on select element
      }
    }}>
    {/* Select element */}
    <select
    
      value=""
      
      onChange={(e) => {
        const [status, type] = e.target.value.split('-');
        setFilterStatus(status);
        setFilterType(type);
        handleSort(status, type);
      }}
      className="custom-select"
    >
      <optgroup label="Filter by Status">
        <option value="all-all"></option>
        <option value={`all-${filterType}`}>All Status</option>
        <option value={`buy-${filterType}`}>Buy</option>
        <option value={`short-${filterType}`}>Short</option>
        <option value={`hold-${filterType}`}>Hold</option>
        <option value={`exit-${filterType}`}>Exit</option>
      </optgroup>
      <optgroup label="Filter by Type">
        
        <option value={`${filterStatus}-all`}>All Types</option>
        <option value={`${filterStatus}-intraday`}>BTST</option>
        <option value={`${filterStatus}-swing`}>Swing</option>
        <option value={`${filterStatus}-multibagger`}>Multibaggar</option>
        <option value={`${filterStatus}-mutualfund`}>Mutualfund</option>
        <option value={`${filterStatus}-investment`}>Investment</option>
        <option value={`${filterStatus}-short`}>Short</option>
        
      </optgroup>
       
      </select>
    {/* SVG icon */}
    
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 24 24"
      style={{
        position: 'absolute',
        right: '14px', // Adjust this value as needed for proper alignment
        top: '50%', // Adjust this value to vertically center the icon
        transform: 'translateY(-50%)', // Center the icon vertically
        cursor: 'pointer',
        zIndex: '-1' // Change cursor to pointer on hover
      }}
      onClick={handleSvgClick}
    >
      <path fill="#2b8b64" d="M22 18.605a.75.75 0 0 1-.75.75h-5.1a2.93 2.93 0 0 1-5.66 0H2.75a.75.75 0 1 1 0-1.5h7.74a2.93 2.93 0 0 1 5.66 0h5.1a.75.75 0 0 1 .75.75m0-13.21a.75.75 0 0 1-.75.75H18.8a2.93 2.93 0 0 1-5.66 0H2.75a.75.75 0 1 1 0-1.5h10.39a2.93 2.93 0 0 1 5.66 0h2.45a.74.74 0 0 1 .75.75m0 6.6a.74.74 0 0 1-.75.75H9.55a2.93 2.93 0 0 1-5.66 0H2.75a.75.75 0 1 1 0-1.5h1.14a2.93 2.93 0 0 1 5.66 0h11.7a.75.75 0 0 1 .75.75" />
    </svg>
    
  </div>
</div>




          </div>
        </div><br />
        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center',marginTop:'-15px',marginBottom:'10px' }}>
            <span className='filter_tabs' style={{marginRight:'10px',fontSize:'8px'}}>Status:  {capitalizeFirstLetter(filterStatus)}</span>
            <span className='filter_tabs' style={{fontSize:'8px'}}>Type: {filterType === 'intraday' ? 'BSTS' :   capitalizeFirstLetter(filterType)}</span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2 className={`stock_tabs ${selectedTab === 'all' ? 'active1' : ''}`} onClick={() => handleTabClick('all')}>All</h2>
        <h2 className={`stock_tabs ${selectedTab === 'buy' ? 'active1' : ''}`} onClick={() => handleTabClick('buy')}>Buy</h2>
            <h2 className={`stock_tabs5 ${selectedTab === 'hold' ? 'active5' : ''}`} onClick={() => handleTabClick('hold')}>Hold</h2>
            <h2 className={`stock_tabs6 ${selectedTab === 'exit' ? 'active6' : ''}`} onClick={() => handleTabClick('exit')}>Exit</h2>

        </div>



        {data.recommandation.map((recommendation, index) => (
          <RecommendationCard key={index} recommendation={recommendation} />
        ))}
      </div>
    </section>
  );
}

export default Recommendation1;
