import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from './modal';
import auth6 from './assets/theme_asset/authentication/6.svg';
import { useNavigate } from 'react-router-dom';

const Future_Stock = () => {
  const [formData, setFormData] = React.useState({
    percentage: '',
    date: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      localStorage.setItem(
        'modalMessage1',
        JSON.stringify({
          title: 'Risk Analyst Data Sent',
          content: 'Risk Management Data Submitted',
        })
      );

      const formDataToSend = new FormData();

      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }

    } catch (error) {
      console.log('Error while sending the Risk Analyst Data :', error);
      setModalMessage({
        title: 'Error',
        content:
          'Failed to send Risk Analyst Data request. Please try again later or check the email you entered.',
      });
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      {showModal && <Modal message={modalMessage} closeModal={closeModal} />}
      
      <form className="auth-form" onSubmit={handleSubmit}>
        <div className="custom-container">
          <div className="form-group">
            <label htmlFor="inputusername" className="form-label">
              Select Percentage
            </label>
            <div className="form-input">
              <select
                type="percentage"
                className="form-control"
                id="percentage"
                name="percentage"
                placeholder="Select Percentage"
                onChange={handleChange}
                style={{
                  padding: '4px',
                  fontSize: '15px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                  outline: 'none',
                  cursor: 'pointer',
                  marginLeft: '5px',
                }}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="DOB" className="form-label">
              Select the Date
            </label>
            <div className="form-input">
              <input
                type="date"
                className="form-control"
                id="date"
                name="date"
                placeholder="Enter Date"
                onChange={handleChange}
              />
            </div>
          </div>

          <button
            type="submit"
            className="btn theme-btn w-100"
            style={{ color: 'white' }}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};


export default Future_Stock;
