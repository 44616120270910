import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import scan from './assets/theme_asset/svg/scan.svg';
import user from './assets/theme_asset/svg/user.svg';
import user_fill from './assets/theme_asset/svg/user-fill.svg';
import mpay from './assets/theme_asset/svg/mpay.svg';
import mpay_fill from './assets/theme_asset/svg/mpay-fill.svg';
import bar from './assets/theme_asset/svg/bar-chart.svg';
import bar_fill from './assets/theme_asset/svg/bar-chart-fill.svg';

const Footer = () => {
  const location = useLocation();


  const catid = 10000000;

  return (
    <div>
      <section className="panel-space" />
      <div className="navbar-menu">
        <div className="scanner-bg">
          <Link to="/portfolio" className="scanner-btn">
          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24">
	<path fill="#fff" d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2M9 17H7v-7h2zm4 0h-2V7h2zm4 0h-2v-4h2z" />
</svg>
          </Link>
        </div>
        <ul>
          <li className={location.pathname === '/' ? 'active' : ''}>
            <Link to="/">
              <div className="icon">
               <svg xmlns="http://www.w3.org/2000/svg" className="unactive" width="2em" height="1em" viewBox="0 0 24 24">
	<path fill="none" stroke="#958f9f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8.557 2.75H4.682A1.932 1.932 0 0 0 2.75 4.682v3.875a1.942 1.942 0 0 0 1.932 1.942h3.875a1.942 1.942 0 0 0 1.942-1.942V4.682A1.942 1.942 0 0 0 8.557 2.75m10.761 0h-3.875a1.942 1.942 0 0 0-1.942 1.932v3.875a1.943 1.943 0 0 0 1.942 1.942h3.875a1.942 1.942 0 0 0 1.932-1.942V4.682a1.932 1.932 0 0 0-1.932-1.932m0 10.75h-3.875a1.942 1.942 0 0 0-1.942 1.933v3.875a1.942 1.942 0 0 0 1.942 1.942h3.875a1.942 1.942 0 0 0 1.932-1.942v-3.875a1.932 1.932 0 0 0-1.932-1.932M8.557 13.5H4.682a1.943 1.943 0 0 0-1.932 1.943v3.875a1.932 1.932 0 0 0 1.932 1.932h3.875a1.942 1.942 0 0 0 1.942-1.932v-3.875a1.942 1.942 0 0 0-1.942-1.942" />
</svg>
    <svg xmlns="http://www.w3.org/2000/svg" className="active" width="2em" height="1em" viewBox="0 0 24 24">
	<path fill="#7031fe" d="M10.995 4.68v3.88A2.44 2.44 0 0 1 8.545 11h-3.86a2.38 2.38 0 0 1-1.72-.72a2.41 2.41 0 0 1-.71-1.72V4.69a2.44 2.44 0 0 1 2.43-2.44h3.87a2.42 2.42 0 0 1 1.72.72a2.39 2.39 0 0 1 .72 1.71m10.75.01v3.87a2.46 2.46 0 0 1-2.43 2.44h-3.88a2.5 2.5 0 0 1-1.73-.71a2.44 2.44 0 0 1-.71-1.73V4.69a2.39 2.39 0 0 1 .72-1.72a2.42 2.42 0 0 1 1.72-.72h3.87a2.46 2.46 0 0 1 2.44 2.44m0 10.75v3.87a2.46 2.46 0 0 1-2.43 2.44h-3.88a2.5 2.5 0 0 1-1.75-.69a2.42 2.42 0 0 1-.71-1.73v-3.87a2.391 2.391 0 0 1 .72-1.72a2.421 2.421 0 0 1 1.72-.72h3.87a2.46 2.46 0 0 1 2.44 2.44zm-10.75.01v3.87a2.46 2.46 0 0 1-2.45 2.43h-3.86a2.42 2.42 0 0 1-2.43-2.43v-3.87A2.46 2.46 0 0 1 4.685 13h3.87a2.49 2.49 0 0 1 1.73.72a2.45 2.45 0 0 1 .71 1.73" />
</svg>
              </div>
              <h5 className={location.pathname === '/' ? 'active' : ''}>Home</h5>
            </Link>
          </li>
          <li className={location.pathname === '/insights' ? 'active' : ''}>
            <Link to="/insights">
              <div className="icon">
              <svg xmlns="http://www.w3.org/2000/svg" className="unactive" width="2em" height="1em" viewBox="0 0 24 24">
	<path fill="#958f9f" d="M10 16q2.5 0 4.25-1.75T16 10t-1.75-4.25T10 4T5.75 5.75T4 10t1.75 4.25T10 16m-1-3V6h2v7zm-3.5 0V8h2v5zm7 0V9h2v4zm8.1 9l-5.675-5.675q-1.025.8-2.275 1.238T10 18q-3.35 0-5.675-2.325T2 10t2.325-5.675T10 2t5.675 2.325T18 10q0 1.4-.437 2.65t-1.238 2.275L22 20.6z" />
</svg>
               <svg xmlns="http://www.w3.org/2000/svg" className="active"  width="2em" height="1em" viewBox="0 0 24 24">
	<path fill="#7031fe" d="M10 16q2.5 0 4.25-1.75T16 10t-1.75-4.25T10 4T5.75 5.75T4 10t1.75 4.25T10 16m-1-3V6h2v7zm-3.5 0V8h2v5zm7 0V9h2v4zm8.1 9l-5.675-5.675q-1.025.8-2.275 1.238T10 18q-3.35 0-5.675-2.325T2 10t2.325-5.675T10 2t5.675 2.325T18 10q0 1.4-.437 2.65t-1.238 2.275L22 20.6z" />
</svg></div>
              <h5 className={location.pathname === '/insights' ? 'active' : ''}>Insight</h5>
            </Link>
          </li>
          <li>
            {/* Leave this empty for spacing */}
          </li>
          <li className={location.pathname === `/blog/${catid}` ? 'active' : ''}>
            <Link to={`/blog/${catid}`}>
              <div className="icon">
              <svg xmlns="http://www.w3.org/2000/svg" className="unactive" width="2em" height="1em" viewBox="0 0 2048 2048">
	<path fill="#958f9f" d="M1536 768v128H512V768zm-256-512v128H512V256zm-256 1536v-640h512v640zm128-512v384h256v-384zm384-768v128H512V512zM256 0h1536v2048H256zm1408 1920V128H384v1792zm-768-256v128H512v-128zm0-512v128H512v-128zm0 256v128H512v-128z" />
</svg>
                <svg xmlns="http://www.w3.org/2000/svg" className="active"  width="2em" height="1em" viewBox="0 0 2048 2048">
	<path fill="#7031fe" d="M1536 768v128H512V768zm-256-512v128H512V256zm-256 1536v-640h512v640zm128-512v384h256v-384zm384-768v128H512V512zM256 0h1536v2048H256zm1408 1920V128H384v1792zm-768-256v128H512v-128zm0-512v128H512v-128zm0 256v128H512v-128z" />
</svg>
              </div>
              <h5 className={location.pathname === '/blog/${catid}' ? 'active' : ''}>Info Bits</h5>
            </Link>
          </li>
          <li className={location.pathname === '/courses' ? 'active' : ''}>
            <Link to="/courses">
              <div className="icon">
         <svg xmlns="http://www.w3.org/2000/svg" className="unactive" width="2em" height="1em" viewBox="0 0 14 14">
	<g fill="none" stroke="#958f9f" stroke-linecap="round" stroke-linejoin="round">
		<path d="M11.366 7.318a5.506 5.506 0 1 0-7.549 3.736M.512 6.006h11.011" />
		<path d="M4.382 8.547a9.503 9.503 0 0 1-.484-2.541A9.504 9.504 0 0 1 6.016.5a9.504 9.504 0 0 1 2.117 5.506A9.505 9.505 0 0 1 8.02 7.06m1.438 1.118l4.03 1.746l-4.03 1.746L5.43 9.924l4.03-1.746Z" />
		<path d="m7.257 10.72l.004 1.9s.665.88 2.197.88s2.2-.88 2.2-.88v-1.9m-6.229 2.275V9.924" />
	</g>
</svg>
           <svg xmlns="http://www.w3.org/2000/svg" className="active" width="2em" height="1em" viewBox="0 0 14 14">
	<path fill="#7031fe" fill-rule="evenodd" d="M0 5.368A6.007 6.007 0 0 1 5.58 0a.624.624 0 0 0-.093.093a10.129 10.129 0 0 0-2.209 5.275zM3.652 8.91c0 .044.002.088.005.131v2.496A6.011 6.011 0 0 1 0 6.618h3.278c.073.66.21 1.31.41 1.939a1.75 1.75 0 0 0-.036.352Zm.885-2.29h1.75l-1.58.684a1.749 1.749 0 0 0-.051.023a8.88 8.88 0 0 1-.12-.708Zm7.41-1.25H8.665A10.129 10.129 0 0 0 6.457.092A.625.625 0 0 0 6.363 0a6.007 6.007 0 0 1 5.584 5.368Zm-7.41 0h2.87a8.879 8.879 0 0 0-1.435-3.831a8.879 8.879 0 0 0-1.435 3.83ZM9.65 6.71a.546.546 0 0 0-.434 0L5.242 8.432a.546.546 0 0 0-.116.068a.682.682 0 0 0-.349.596v3.028a.682.682 0 0 0 1.365 0v-2.3l3.074 1.331c.139.06.296.06.434 0l3.974-1.721a.546.546 0 0 0 0-1.002zm-2.933 4.51l2.024.877a1.74 1.74 0 0 0 1.383 0l2.024-.877v1.358c0 .12-.038.236-.11.33l-.435-.33l.434.331l-.001.002l-.002.002l-.004.006l-.012.015a2.127 2.127 0 0 1-.165.176c-.11.104-.27.237-.483.368c-.431.264-1.071.514-1.937.514c-.866 0-1.506-.25-1.936-.515a2.883 2.883 0 0 1-.61-.5a1.598 1.598 0 0 1-.037-.044l-.012-.015l-.004-.006l-.002-.002v-.002H6.83l.435-.33l-.435.33a.546.546 0 0 1-.11-.329l-.004-1.36Z" clip-rule="evenodd" />
</svg>
              </div>
              <h5 className={location.pathname === '/courses' ? 'active' : ''}>Courses</h5>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
