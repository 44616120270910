import React, { useEffect, useRef ,memo} from 'react';

function TradingViewWidget({ symbol = "BSE:SAIL" }) {
    const container = useRef();

    useEffect(() => {
        // Check if the script has already been loaded
        if (!document.getElementById("tradingViewScript")) {
            const script = document.createElement("script");
            script.id = "tradingViewScript";
            script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
            script.type = "text/javascript";
            script.async = true;
            script.innerHTML = `
            {
                "autosize": true,
                "symbol": "${symbol}",
                "interval": "D",
                "timezone": "Etc/UTC",
                "theme": "light",
                "style": "3",
                "locale": "en",
                "enable_publishing": false,
                "allow_symbol_change": false,
                "hide_top_toolbar": true,
                "hide_legend": true,
                "calendar": false,
                "support_host": "https://www.tradingview.com"
            }`;
            container.current.appendChild(script);
        }
    }, [symbol]);

    return (
        <div className="tradingview-widget-container" ref={container} style={{ height: "400", width: "100%" }}>
        </div>
    );
}

export default memo(TradingViewWidget);
