import logo from './assets/theme_asset/favicon.png';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { Link } from 'react-router-dom';

import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import SkeletonLoader from './SkeletonLoader';







function Notification() {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0])

  const [notification , setNotification] = useState({title: "",body: ""});

  
  





      




  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/notificationapi/${id}`);
      setData(response.data); 
      
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };
  


  

  useEffect(() => {
   
    

    feather.replace();

    fetchData();


   
  }, []);

  

  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  if(data == null)
    {
      return (
       <>  </>
       )
    }



  
  return (

    
    <div style={{overflowX:'hidden'}}>
       

  

{/* categories section starts */}

       
<div className='row pt-2'>
        <div className='col' style={{paddingRight:'0px',marginRight:'-25px'}}>
       <Link to="/" style={{padding:'5px 10px'}}>
       <svg xmlns="http://www.w3.org/2000/svg" width="35" height="25" viewBox="0 0 24 24">
	<path fill="black" d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1" />
</svg>
          </Link>
          </div>
          <div className='col' style={{paddingLeft:'0px'}}>
            <img src={logo} alt="logo" style={{width:'30px',height:'30px'}}/>
          </div>
          </div>
        {/* quick send section end */}
        {/* Transaction section starts */}
        <section className="section-b-space">
    <div className="custom-container">
    <div className="mb-2">
      <center><h2>All Notification</h2></center>
      
    </div>

      <ul className="notification-list">
      {data.notification && data.notification.map((notification, index) => (
        <Link to={notification.link}>
        <li style={{ backgroundColor: notification.status === 'unread' ? '#acdded' : 'white', marginBottom:'5px' }} className="notification-box">

          <div className="notification-img">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 15 15">
	<path fill="black" d="M5 5.5a2.5 2.5 0 1 1 5 0a2.5 2.5 0 0 1-5 0" />
	<path fill="black" fill-rule="evenodd" d="M7.5 0a7.5 7.5 0 1 0 0 15a7.5 7.5 0 0 0 0-15M1 7.5a6.5 6.5 0 1 1 10.988 4.702A3.5 3.5 0 0 0 8.5 9h-2a3.5 3.5 0 0 0-3.488 3.202A6.482 6.482 0 0 1 1 7.5" clip-rule="evenodd" />
</svg>
          </div>
          
             <div className="notification-details">
             <div className="d-flex justify-content-between align-items-center">
               <div>
                
                   <h5 className="fw-semibold dark-text">{notification.title}</h5>
                
                 <h5 className="dark-text fw-normal">
               {notification.desc}
                 
               </h5>
                
               </div>
               <div>
               <h6 className="time fw-normal light-text">{new Date(notification.created_at).toLocaleDateString()}</h6>
<h6 className="time fw-normal light-text">{new Date(notification.created_at).toLocaleTimeString()}</h6>
</div>

             </div>
            
           </div></li>
           </Link>
              ))}
              
      </ul>
    </div>
  </section>
        {/* all cards section end */}
       
        {/* saving plans section starts */}
        
       
        
        
      </div>
  );
}

export default Notification;
