import React from 'react';
import Stories from 'react-insta-stories';

const MyStoriesFeed = () => {
  // Data for stories
  const storiesData = [
    { url: 'https://www.lifewire.com/thmb/l02eHirN3GViukVErJSqJCRAEuM=/768x0/filters:no_upscale():max_bytes(150000):strip_icc()/GettyImages-116248249-5be59c3cc9e77c00514fed87.jpg', duration: 5000 },
    { url: 'https://pureseo.com/wp-content/uploads/2017/10/link-building-digital-marketing-1.jpg', duration: 5000 },
    { url: 'https://youtu.be/-EQmUArLeEk?si=lr5iKk5A5zsRnd-i', duration: 5000 },
    // Add more stories as needed
  ];


  const stories = [
    'https://pureseo.com/wp-content/uploads/2017/10/link-building-digital-marketing-1.jpg',
    {
      url: 'https://pureseo.com/wp-content/uploads/2017/10/link-building-digital-marketing-1.jpg',
      duration: 5000,
      header: {
        heading: 'Mohit Karekar',
        subheading: 'Posted 30m ago',
        profileImage: 'https://pureseo.com/wp-content/uploads/2017/10/link-building-digital-marketing-1.jpg',
      },
    },
   
  ];

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
  <Stories
    stories={stories}
    width="100%"
    height="100%"
    loop
    defaultInterval={5000}
    progressClassName="custom-progress-bar"
    onAllStoriesEnd={() => console.log('All stories ended')}
  />
</div>

  );
};

export default MyStoriesFeed;
