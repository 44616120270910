import logo from './assets/theme_asset/favicon.png';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Login from './login';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { Link } from 'react-router-dom';

import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import { Pagination, Navigation } from 'swiper/modules';
import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import Modal from "./modal";
import SkeletonLoader from './SkeletonLoader';






function App() {

  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState('all');

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0,0])
  
  

  



const dataChartRec = {
  labels: [
    'Active',
    'Hold',
    'Exit',
    'Cancel'
  ],
  datasets: [{
    label: 'Recommandation Insights',
    data: recMetrics,
    backgroundColor: [
      '#2b8b64',
      '#d18925',
      'black',
      '#808080'
    ],
    hoverOffset: 4
  }]
};


      




  const fetchData = async () => {
    try {
      
      const response = await axios.get(`${apiBaseUrl}/insightpageapi`);
      setData(response.data);
      var tArr = [response.data.recommandation_count.active,response.data.recommandation_count.hold,response.data.recommandation_count.exit,response.data.recommandation_count.cancel]; 
      setRecMetrics(tArr);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  function getStatusClass(status) {
    switch (status) {
      case 'buy':
        return 'bg-green-status';
      case 'short':
        return 'bg-red-status';
      case 'hold':
        return 'bg-yellow-status';
      case 'exit':
        return 'bg-black-status';
      default:
        return ''; // You can set a default class or leave it empty if none matches
    }
  }


  function getBackgroundClass(status) {
    switch (status) {
      case 'buy':
        return 'bg-green';
      case 'short':
        return 'bg-red';
      case 'hold':
        return 'bg-yellow';
      case 'exit':
        return 'bg-black';
      default:
        return ''; // You can set a default class or leave it empty if none matches
    }
  }


  function getTrendBackground(status) {
    switch (status) {
      case 'up':
        return 'bg-green-status';
      case 'down':
        return 'bg-red-status';
      case 'side':
        return 'bg-yellow-status';
      default:
        return ''; // You can set a default class or leave it empty if none matches
    }
  }

  function getTrendIcon(status) {
    switch (status) {
      case 'up':
        return 'arrow-up-circle';
      case 'down':
        return 'arrow-down-circle';
      case 'side':
        return 'arrow-right-circle';
      default:
        return ''; // You can set a default class or leave it empty if none matches
    }
  }
  
  const [modalMessage3, setModalMessage] = useState(null);
  useEffect(() => {
    // Replace icons after component is mounted
    const storedMessage = localStorage.getItem('modalMessage3');
      if (storedMessage) {
        setModalMessage(JSON.parse(storedMessage));
        // Remove modal message from local storage after retrieving
        localStorage.removeItem('modalMessage3');
      }

       
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/insights');
    }
    feather.replace();

    fetchData();
  }, []);

  if (loading) {
    
      <SkeletonLoader showHeader={false} showFooter={true} />     
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if(data == null)
    {
      return (
        <SkeletonLoader />
       )
    }

    function getColorFromString(str) {
      // Convert the string to a hash code
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      // Convert the hash code to an RGB color
      const c = (hash & 0x00FFFFFF)
        .toString(16)
        .toUpperCase()
        .padStart(6, '0');
      // Return the color in hexadecimal format
      return `#${c}`;
    }
  
    const labels = data?.getPieChartData_all?.map(item => item.sector) || [];
    const data1 = data && data.getPieChartData_all.map(item => item.count);
    
    const backgroundColor = labels?.map(label => getColorFromString(label));
  
  
    const dataChartRec1 = {
      labels: labels, // Add labels here
      datasets: [{
        label: 'Sector wise Stock',
        data: data1,
        backgroundColor: backgroundColor,
        hoverOffset: 4
      }]
    };
    
    const options = {
      plugins: {
        legend: {
          display: false // Hide the legend
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const label = context.label || '';
              const value = context.raw || 0;
              return `${label}: ${value}`;
            }
          }
        }
      }
    };

  

    const RecommendationCard = ({ exit_calls }) => {
      const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  
      const getBackgroundClass = (status) => {
        switch (status) {
          case 'buy':
            return 'bg-green';
          case 'short':
            return 'bg-red';
          case 'hold':
            return 'bg-yellow';
          case 'exit':
            return 'bg-black';
          case 'cancel':
            return 'bg-gray';
          default:
            return '';
        }
      };
  
      const getStatusClass = (status) => {
        switch (status) {
          case 'buy':
            return 'bg-green-status';
          case 'short':
            return 'bg-red-status';
          case 'hold':
            return 'bg-yellow-status';
          case 'exit':
            return 'bg-black-status';
          case 'cancel':
            return 'bg-gray-status';
          default:
            return '';
        }
      };
      let total_return;
      if(exit_calls.target > exit_calls.trailing_stoploss){
         total_return = (exit_calls.target - exit_calls.price_band_to) /  exit_calls.price_band_to * 100;

      }else{
        total_return = (exit_calls.trailing_stoploss - exit_calls.price_band_to) /  exit_calls.price_band_to * 100;

      }
    
    
         const rounded_return = parseFloat(total_return.toFixed(2));
    
     let total_loss;
      if(exit_calls.trailing_stoploss){
         total_loss = (exit_calls.trailing_stoploss - exit_calls.price_band_to) /  exit_calls.price_band_to * 100;
    
      }else{
         total_loss = (exit_calls.stoploss - exit_calls.price_band_to) /  exit_calls.price_band_to * 100;
      }
     const rounded_loss = parseFloat(total_loss.toFixed(2));

    return (
      <div>
  
        <div>
          <Link to={`/recomm_details/${exit_calls.id}`}>
            <div className="bill-box mb-3 pb-3"> {/* Added pb-3 for bottom padding */}
            <div className="d-flex gap-3">
            <div className="bill-icon">
            <img className="img-fluid icon" src={"https://images.5paisa.com/MarketIcons/" + exit_calls.symbol+".png"} alt="p6" />
            </div>
            <div className="bill-details">
            <h6 className="dark-text">{exit_calls.script_name} |<span className="text-muted"> {exit_calls.stock_type}</span> </h6>
            <h6 className="light-text mt-2"><span className="">BZ: (₹{exit_calls.price_band_from} - ₹{exit_calls.price_band_to})</span> | {exit_calls.duration} {exit_calls.duration_type}</h6>
            </div>


            </div>
            <div className="bill-price">
            <h5><span style={{ backgroundColor: exit_calls.result === 'success' && 'lightGreen', padding:'3px'}}>T: ₹{exit_calls.target} </span>| <span style={{ backgroundColor: exit_calls.result === 'fail' && '#ff6a6a', padding:'3px'}}>SL: ₹{exit_calls.stoploss}</span> | <span className={ exit_calls.result === 'success' ? 'success-color' : 'error-color' }>{exit_calls.result === 'success' && '+' } {exit_calls.result === 'success' ? rounded_return : rounded_loss} %</span> </h5>
            <a href="#pay" data-bs-toggle="modal" className={`btn bill-pay bill-paid ${getStatusClass(exit_calls.status)}`}>{exit_calls.status.toUpperCase()}</a>
            </div>
            </div>
          </Link>
       </div>
    
      </div>
    );
    
     
    };
    const RecommendationCard1 = ({ exit_calls }) => {
      const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  
      const getBackgroundClass = (status) => {
        switch (status) {
          case 'buy':
            return 'bg-green';
          case 'short':
            return 'bg-red';
          case 'hold':
            return 'bg-yellow';
          case 'exit':
            return 'bg-black';
          case 'cancel':
            return 'bg-gray';
          default:
            return '';
        }
      };
  
      const getStatusClass = (status) => {
        switch (status) {
          case 'buy':
            return 'bg-green-status';
          case 'short':
            return 'bg-red-status';
          case 'hold':
            return 'bg-yellow-status';
          case 'exit':
            return 'bg-black-status';
          case 'cancel':
            return 'bg-gray-status';
          default:
            return '';
        }
      };

      let total_return;
      if(exit_calls.target > exit_calls.trailing_stoploss){
         total_return = (exit_calls.target - exit_calls.price_band_to) /  exit_calls.price_band_to * 100;

      }else{
        total_return = (exit_calls.trailing_stoploss - exit_calls.price_band_to) /  exit_calls.price_band_to * 100;

      }
    

     const rounded_return = parseFloat(total_return.toFixed(2));

 let total_loss;
  if(exit_calls.trailing_stoploss){
     total_loss = (exit_calls.trailing_stoploss - exit_calls.price_band_to) /  exit_calls.price_band_to * 100;

  }else{
     total_loss = (exit_calls.stoploss - exit_calls.price_band_to) /  exit_calls.price_band_to * 100;
  }
     const rounded_loss = parseFloat(total_loss.toFixed(2));

    return (
      <div>
          {exit_calls.result === 'fail' && 
            <div>
              <Link to={`/recomm_details/${exit_calls.id}`}>
                <div className="bill-box mb-3 pb-3"> {/* Added pb-3 for bottom padding */}
                <div className="d-flex gap-3">
                <div className="bill-icon">
                <img className="img-fluid icon" src={"https://images.5paisa.com/MarketIcons/" + exit_calls.symbol+".png"} alt="p6" />
                </div>
                <div className="bill-details">
                <h6 className="dark-text">{exit_calls.script_name} |<span className="text-muted"> {exit_calls.stock_type}</span> </h6>
                <h6 className="light-text mt-2"><span className="">BZ: (₹{exit_calls.price_band_from} - ₹{exit_calls.price_band_to})</span> | {exit_calls.duration} {exit_calls.duration_type}</h6>
                </div>


                </div>
                <div className="bill-price">
                <h5><span style={{ backgroundColor: exit_calls.result === 'success' && 'lightGreen', padding:'3px'}}>T: ₹{exit_calls.target} </span>| <span style={{ backgroundColor: exit_calls.result === 'fail' && '#ff6a6a', padding:'3px'}}>SL: ₹{exit_calls.stoploss}</span> | <span className={ exit_calls.result === 'success' ? 'success-color' : 'error-color' }>{exit_calls.result === 'success' && '+' } {exit_calls.result === 'success' ? rounded_return : rounded_loss} %</span> </h5>
                <a href="#pay" data-bs-toggle="modal" className={`btn bill-pay bill-paid ${getStatusClass(exit_calls.status)}`}>{exit_calls.status.toUpperCase()}</a>
                </div>
                </div>
              </Link>
            </div>
          }
      </div>
    );
    
     
    };
    const RecommendationCard2 = ({ exit_calls }) => {
      const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  
      const getBackgroundClass = (status) => {
        switch (status) {
          case 'buy':
            return 'bg-green';
          case 'short':
            return 'bg-red';
          case 'hold':
            return 'bg-yellow';
          case 'exit':
            return 'bg-black';
          case 'cancel':
            return 'bg-gray';
          default:
            return '';
        }
      };
  
      const getStatusClass = (status) => {
        switch (status) {
          case 'buy':
            return 'bg-green-status';
          case 'short':
            return 'bg-red-status';
          case 'hold':
            return 'bg-yellow-status';
          case 'exit':
            return 'bg-black-status';
          case 'cancel':
            return 'bg-gray-status';
          default:
            return '';
        }
      };
  
      let total_return;
      if(exit_calls.target > exit_calls.trailing_stoploss){
         total_return = (exit_calls.target - exit_calls.price_band_to) /  exit_calls.price_band_to * 100;

      }else{
        total_return = (exit_calls.trailing_stoploss - exit_calls.price_band_to) /  exit_calls.price_band_to * 100;

      }
    
         const rounded_return = parseFloat(total_return.toFixed(2));
    
     let total_loss;
      if(exit_calls.trailing_stoploss){
         total_loss = (exit_calls.trailing_stoploss - exit_calls.price_band_to) /  exit_calls.price_band_to * 100;
    
      }else{
         total_loss = (exit_calls.stoploss - exit_calls.price_band_to) /  exit_calls.price_band_to * 100;
      }
     const rounded_loss = parseFloat(total_loss.toFixed(2));

    return (
      <div>
        {exit_calls.result === 'success' && 
          <div>
            <Link to={`/recomm_details/${exit_calls.id}`}>
              <div className="bill-box mb-3 pb-3"> {/* Added pb-3 for bottom padding */}
              <div className="d-flex gap-3">
              <div className="bill-icon">
              <img className="img-fluid icon" src={"https://images.5paisa.com/MarketIcons/" + exit_calls.symbol+".png"} alt="p6" />
              </div>
              <div className="bill-details">
              <h6 className="dark-text">{exit_calls.script_name} |<span className="text-muted"> {exit_calls.stock_type}</span> </h6>
              <h6 className="light-text mt-2"><span className="">BZ: (₹{exit_calls.price_band_from} - ₹{exit_calls.price_band_to})</span> | {exit_calls.duration} {exit_calls.duration_type}</h6>
              </div>


              </div>
              <div className="bill-price">
              <h5><span style={{ backgroundColor: exit_calls.result === 'success' && 'lightGreen', padding:'3px'}}>T: ₹{exit_calls.target} </span>| <span style={{ backgroundColor: exit_calls.result === 'fail' && '#ff6a6a', padding:'3px'}}>SL: ₹{exit_calls.stoploss}</span> | <span className={ exit_calls.result === 'success' ? 'success-color' : 'error-color' }>{exit_calls.result === 'success' && '+' } {exit_calls.result === 'success' ? rounded_return : rounded_loss} %</span> </h5>
              <a href="#pay" data-bs-toggle="modal" className={`btn bill-pay bill-paid ${getStatusClass(exit_calls.status)}`}>{exit_calls.status.toUpperCase()}</a>
              </div>
              </div>
            </Link>
          </div>
        }
      </div>
    );
    
     
    };
  
 
    return (
      <div style={{overflowX:'hidden'}}>
        <div className='row pt-2'>
        <div className='col' style={{paddingRight:'0px',marginRight:'-25px'}}>
       <Link to="/" style={{padding:'5px 10px'}}>
       <svg xmlns="http://www.w3.org/2000/svg" width="35" height="25" viewBox="0 0 24 24">
	<path fill="black" d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1" />
</svg>
          </Link>
          </div>
          <div className='col' style={{paddingLeft:'0px'}}>
            <img src={logo} alt="logo" style={{width:'30px',height:'30px'}}/>
          </div>
          </div>
        
<section style={{padding:'0px'}}>
    <div className="custom-container">
    
    <div>
    <div className='p6 col-md-6'>   
      <Doughnut data={dataChartRec} />
    </div>
    <div className='p3 col-md-6'>
    <h2>Performance Metrix</h2>
    <div className="bill-box mb-3 bg-light mt-3">
                  <div className="">
                    <div className='d-flex gap-3'>
                    <div className="bill-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="none" stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 16v2a1 1 0 0 1-1 1H6l6-7l-6-7h11a1 1 0 0 1 1 1v2" />
</svg>
                    </div>
                    <div className="bill-details mt-2">
                      <h2>Total Calls</h2>
                    </div>

                    <div className="float-right mt-2">
                      <h2>{data.recommandation_count.all}</h2>
                    </div>
                    </div>

                    <hr></hr>

                    <div className='d-flex gap-3'>
                    <div className="bill-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
	<path fill="black" d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M1.5 8a6.5 6.5 0 1 0 13 0a6.5 6.5 0 0 0-13 0m4.879-2.773l4.264 2.559a.25.25 0 0 1 0 .428l-4.264 2.559A.25.25 0 0 1 6 10.559V5.442a.25.25 0 0 1 .379-.215" />
</svg>
                    </div>
                    <div className="bill-details mt-2">
                      <h2>Active Calls</h2>
                    </div>

                    <div className="float-right mt-2">
                      <h2>{data.recommandation_count.active}</h2>
                    </div>
                    </div>


                    <hr></hr>

                    <div className='d-flex gap-3'>
                    <div className="bill-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 20 20">
	<path fill="black" d="M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07m12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32M7 6h2v8H7zm4 0h2v8h-2z" />
</svg>
                    </div>
                    <div className="bill-details mt-2">
                      <h2>Open Calls</h2>
                    </div>

                    <div className="float-right mt-2">
                      <h2>{data.recommandation_count.hold}</h2>
                    </div>
                    </div>


                    <hr></hr>

                    <div className='d-flex gap-3'>
                    <div className="bill-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<g fill="none">
		<path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
		<path fill="black" d="M12 2.5a1.5 1.5 0 0 1 0 3H7a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h4.5a1.5 1.5 0 0 1 0 3H7A3.5 3.5 0 0 1 3.5 18V6A3.5 3.5 0 0 1 7 2.5Zm6.06 5.61l2.829 2.83a1.5 1.5 0 0 1 0 2.12l-2.828 2.83a1.5 1.5 0 1 1-2.122-2.122l.268-.268H12a1.5 1.5 0 0 1 0-3h4.207l-.268-.268a1.5 1.5 0 1 1 2.122-2.121Z" />
	</g>
</svg>
                    </div>
                    <div className="bill-details mt-2">
                      <h2>Exit Calls</h2>
                    </div>

                    <div className="float-right mt-2">
                      <h2>{data.recommandation_count.exit}</h2>
                    </div>
                    </div>


                    <hr></hr>
                    <div className='d-flex gap-3'>
                    <div className="bill-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 20 20">
	<path fill="black" d="M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07m1.41-1.41A8 8 0 1 0 15.66 4.34A8 8 0 0 0 4.34 15.66m9.9-8.49L11.41 10l2.83 2.83l-1.41 1.41L10 11.41l-2.83 2.83l-1.41-1.41L8.59 10L5.76 7.17l1.41-1.41L10 8.59l2.83-2.83z" />
</svg>
                    </div>
                    <div className="bill-details mt-2">
                      <h2>Cancel Calls</h2>
                    </div>

                    <div className="float-right mt-2">
                      <h2>{data.recommandation_count.cancel}</h2>
                    </div>
                    </div>


                    <hr></hr>

                    <div className='d-flex gap-3'>
                    <div className="bill-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<g fill="none" stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
		<path d="M5 19L19 5" />
		<circle cx="7" cy="7" r="3" />
		<circle cx="17" cy="17" r="3" />
	</g>
</svg>
                    </div>
                    <div className="bill-details mt-2">
                      <h2>Success Ratio</h2>
                      <span style={{color:'red',fontSize:'12px'}}>Fail count: {data.recommandation_count.exit_fail} </span>| <span style={{color:'green',fontSize:'12px'}}>Success count: {data.recommandation_count.exit_success} </span>
                    </div>

                    <div className="float-right mt-2">
                    <h2>{data.success_ratio.toFixed(2)}%</h2>
                    </div>
                    </div>

                  </div>
                  
                </div>
      
    </div>
    </div>
    </div>
  </section>
  <div className="custom-container">
  <section>
    <h2>Sector Wise Analysis</h2>
    {data && data.getPieChartData_all && (
    <div className='p6 col-md-6'>   
      <Doughnut data={dataChartRec1} options= {options}/>
    </div>
    )}
</section>
  {data.exit_calls && data.exit_calls.length > 0 && (
  <section>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom:'10px' }}>
            <h6 className={`stock_tabs2 ${selectedTab === 'all' ? 'active2' : ''}`} onClick={() => setSelectedTab('all')} style={{fontSize:'20px',padding:'10px 30px'}}>All</h6>
            <h6 className={`stock_tabs2 ${selectedTab === 'minus' ? 'active2' : ''}`} onClick={() => setSelectedTab('minus')} style={{fontSize:'20px',padding:'10px 30px'}}>Negative</h6>
            <h6 className={`stock_tabs2 ${selectedTab === 'plus' ? 'active2' : ''}`} onClick={() => setSelectedTab('plus')} style={{fontSize:'20px',padding:'10px 30px'}}>Positive</h6>

        </div>
        {selectedTab === 'all' && (
          <>
           <Swiper
           slidesPerView={1}
           spaceBetween={10}
           pagination={{
             clickable: false,
             bulletActiveClass: 'swiper-pagination-bullet-active', // Bullet active class
             bulletClass: 'swiper-pagination-bullet', // Bullet class
             renderBullet: (index, className) => {
               // Return the custom bullet with inline styles
               return `<span class="${className}" style="background-color: #622cfd;"></span>`;
             },
           }}
           modules={[Pagination]}
           className="mySwiper"
         >
                 {/* Group data.this_week_Recommendation into chunks of 6 items each */}
                 {data.exit_calls.reduce((chunks, exit_calls, index) => {
                     const chunkIndex = Math.floor(index / 10);
                     
                     // Create an array for each chunk if it doesn't exist yet
                     if (!chunks[chunkIndex]) {
                         chunks[chunkIndex] = [];
                     }
                     
                     // Add the current recommendation to the current chunk
                     chunks[chunkIndex].push(exit_calls);
                     
                     return chunks;
                 }, []).map((exit_calls, chunkIndex) => (
                     <SwiperSlide key={chunkIndex}>
                         <div className="row">
                             {/* Map through each recommendation in the current chunk */}
                             {exit_calls.map((exit_calls, index) => (
                                 <div className="col-12" key={index}>
                                     {/* Render each recommendation using the RecommendationCard1 component */}
                                     <RecommendationCard exit_calls={exit_calls} />
                                     </div>
                             ))}
                         </div>
                     </SwiperSlide>
                 ))}
             </Swiper>
             </>
        )}

      {selectedTab === 'minus' && data.exit_calls.map((exit_calls, index) => (
        <RecommendationCard1 key={index} exit_calls={exit_calls} />
      ))}
        
        {selectedTab === 'plus' && data.exit_calls.map((exit_calls, index) => (
          <RecommendationCard2 key={index} exit_calls={exit_calls} />
        ))}

</section>
)}
</div>
  
        {/* news-update section end */}
        {/* panel-space start */}
        <section className="panel-space" />
        {/* panel-space end */}
        {/* bottom navbar start */}
       
        {/* bottom navbar end */}
        {/* add money modal start */}
        <Footer/>
     
        
      </div>
  );
}



export default App;
