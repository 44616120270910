import logo from './logo.svg';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";



import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import { Link } from 'react-router-dom';
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import SkeletonLoader from './SkeletonLoader';

import bull from "./assets/theme_asset/bull.png"
import bull_bear from "./assets/theme_asset/bull_bear.png"
import bear from "./assets/theme_asset/bear.png"





function Market_trend() {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0])
  







      




  const fetchData = async () => {
    try {
      
      const response = await axios.get(`${apiBaseUrl}/market_trendpageapi`);
      setData(response.data); 
      
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  


  function getTrendBackground(status) {
    switch (status) {
      case 'up':
        return 'bg-green-status';
      case 'down':
        return 'bg-red-status';
      case 'side':
        return 'bg-yellow-status';
      default:
        return 'bg-red-status'; // You can set a default class or leave it empty if none matches
    }
  }

  function getTrendIcon(status) {
    switch (status) {
      case 'up':
        return 'arrow-up-circle';
      case 'down':
        return 'arrow-down-circle';
      case 'side':
        return 'arrow-right-circle';
      default:
        return ''; // You can set a default class or leave it empty if none matches
    }
  }
  

  useEffect(() => {
    // Replace icons after component is mounted

    

    feather.replace();

    fetchData();
  }, []);

  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  if(data == null)
    {
      return (
       <>  </>
       )
    }

    function getTrendImage(status) {
      switch (status) {
          case "up":
              return <img src={bull} alt="Bull trend" style={{ width: "50px" }} />;
          case "down":
              return <img src={bear} alt="Bull trend" style={{ width: "50px" }} />;
          case "side":
              return <img src={bull_bear} alt="Bull trend" style={{ width: "50px" }} />;
          default:
              return <img src={bear} alt="Bull trend" style={{ width: "50px" }} />; // Return an empty string for an unexpected or unknown status
      }
  }
  
  
  return (

    
    <div>
       

       <div className="auth-header" style={{height:'150px'}}>
      <div>
              <Link to="/" style={{ textDecoration: 'none' }}>
                <button style={{
                  padding: '8px 16px',
                  color: 'white',
                  backgroundColor: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                }}>X</button>
              </Link>
            </div>
        
        <div className="auth-content" style={{justifyContent:'center'}}>
          <div>
            <center>
            <h2>Market Trends</h2>
            <h4 className="p-0"></h4>
            </center></div>
        </div>
      </div>
        <section>
          <div className="custom-container">
          
            <div className="row gy-3">
            {data.market_trend.map((trend, index) => (
              <div className="col-12">
                <div className="transaction-box">
                  <a href="#" className="d-flex gap-3">
                    <div className={`transaction-image1 ${getTrendBackground(trend.trend)}`}>
                    {trend.trend === "up" ? <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<g fill="none">
		<path d="M24 0v24H0V0zM12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.105.074l.014.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.016-.018m.264-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.092l.01-.009l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
		<path fill="white" d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m-.707 3.046a1 1 0 0 1 1.32-.083l.094.083l3.536 3.535a1 1 0 0 1-1.32 1.498l-.094-.083l-1.83-1.83v6.077a1 1 0 0 1-1.992.116L11 16.243v-6.076l-1.828 1.829a1 1 0 0 1-1.498-1.32l.084-.095z" />
	</g>
</svg> : trend.trend === "side" ? <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<g fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
		<circle cx="12" cy="12" r="9" />
		<path d="M9 12h6m0 0l-3 3m3-3l-3-3" />
	</g>
</svg> : <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	<path fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0-18 0m5 0l4 4m0-8v8m4-4l-4 4" />
</svg>}
                    {/* <i className="service-icon" data-feather={getTrendIcon(trend.trend)} /> */}
                    </div>
                    <div className="transaction-details">
                      <div className="transaction-name">
                        <h2>{trend.sector_of_trend}</h2>
                        <h6 className={`uppercase`} style={{ color: trend.trend === "up" ? "#2b8b64" : trend.trend === "side" ? "#d18925" : "#ce3c60" }}>{getTrendImage(trend.trend)}</h6>
                      </div>
                      
                      {/* <div className="d-flex justify-content-between">
                        <h5 className="light-text">Subscription</h5>
                        <h5 className="light-text">8:45 am</h5>
                      </div> */}
                    </div>
                  </a>
                </div>
              </div>
              ))}

              
            </div>
          </div>
        </section>
        {/* all cards section end */}
       
        {/* saving plans section starts */}
        
       
        
        
      </div>
  );
}

export default Market_trend;
